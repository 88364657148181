var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    { attrs: { "data-cy": "login-page" } },
    [
      _c(
        "v-container",
        { staticClass: "fill-height", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "the-login-class",
                          attrs: { cols: "12", sm: "8", md: "4" }
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-12" },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pb-0" },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      attrs: { "lazy-validation": "" },
                                      model: {
                                        value: _vm.formValid,
                                        callback: function($$v) {
                                          _vm.formValid = $$v
                                        },
                                        expression: "formValid"
                                      }
                                    },
                                    [
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(_vm.$t("login.loginMessage"))
                                        )
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.$t("login.emailLabel"))
                                        )
                                      ]),
                                      _c("v-text-field", {
                                        attrs: {
                                          name: "email",
                                          "prepend-inner-icon": "mail",
                                          type: "text",
                                          autocomplete: "email",
                                          rules: [
                                            function(v) {
                                              return v.length > 0 || ""
                                            },
                                            !_vm.incorrectCredentials || ""
                                          ],
                                          outlined: "",
                                          dense: "",
                                          "data-cy": "email"
                                        },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.login.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email"
                                        }
                                      }),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.$t("login.passwordLabel"))
                                        )
                                      ]),
                                      _c("v-text-field", {
                                        attrs: {
                                          id: "password",
                                          name: "password",
                                          "prepend-inner-icon": "lock",
                                          type: "password",
                                          dense: "",
                                          autocomplete: "current-password",
                                          rules: [
                                            function(v) {
                                              return v.length > 0 || ""
                                            },
                                            !_vm.incorrectCredentials || ""
                                          ],
                                          outlined: "",
                                          "data-cy": "password"
                                        },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.login.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password"
                                        }
                                      }),
                                      _vm.incorrectCredentials || !_vm.formValid
                                        ? _c(
                                            "div",
                                            { staticClass: "error--text" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("login.genericError")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.temporarilyUnavailable
                                        ? _c(
                                            "div",
                                            { staticClass: "error--text" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "login.temporarilyUnavailableError"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "px-6 pb-4 d-flex justify-end" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: { name: "PasswordResetRequest" }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("login.forgotPassword")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        depressed: "",
                                        "data-cy": "login-btn"
                                      },
                                      on: { click: _vm.login }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("login.loginButton")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _vm.$helpers.detectUnsupportedBrowser()
                      ? _c(
                          "div",
                          { staticClass: "unsupported-browser-error" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "general.detectedInternetBrowserMessage"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }