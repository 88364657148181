import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import scss variables: see: https://til.hashrocket.com/posts/sxbrscjuqu-share-scss-variables-with-javascript
import variables from '@/scss/variables.scss'

Vue.use(Vuetify)

export default new Vuetify({
	breakpoint: {
		thresholds: {
			// add 1px to all breakpoints so to make Vuetify switch to lower size AT that (round) breakpoint not 1px below it
			xs: 600 + 1, // up to (not including) this width is "xs"
			sm: 960 + 1, // up to (not including) this width is "sm"
			md: parseInt(process.env.VUE_APP_TABLET_BREAKPOINT) + 1,
			lg: 1904 + 1,
		},
	},
	icons: {
		iconfont: 'md',
	},
	theme: {
		themes: {
			light: {
				primary: variables.primaryColor,
				accent: variables.primaryColor,
				error: variables.errorColor,
				success: variables.secondaryColor,
				info: variables.infoColor,
				select: variables.selectColor,
			},
		},
	},
})
