import $store from '@/services/vuex'

export default {
	/**
	 * Detects if the user is using the app in an unsupported browser. Returns true if true, otherwise it returns false.
	 */
	detectUnsupportedBrowser() {
		return navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1
	},
	/**
	 * Delays the execution of the function in the first param by the delay miliseconds in the second param (https://github.com/benwinding/vuetify-datatable-extended/blob/master/src/plugin/debounce.js)
	 * @param fn {function} The function to execute
	 * @param delay {integer} the number of miliseconds to delay the execution of the former function
	 */
	debounce(fn, delay) {
		var timeoutID = null
		return function () {
			clearTimeout(timeoutID)
			var args = arguments
			var that = this
			timeoutID = setTimeout(function () {
				fn.apply(that, args)
			}, delay)
		}
	},
	/**
	 * Builds the invite URL link for a new user of the easyreg app
	 * @param emailDomain {string} The email domain of the current client. In this format: {@}example.org. Can be without of a domain -> null or empty string
	 * @param inviteCode {string} The unique invite code generated by the easyreg app backend
	 */
	buildUserInviteLink(emailDomain, inviteCode) {
		let userDomain = ''
		if (emailDomain) {
			// indexOf() works for both if the emailDomain starts with a @ symbol or not
			userDomain = '&userDomain=' + emailDomain.substring(emailDomain.indexOf('@') + 1)
		}

		let inviteLink =
			location.origin +
			'/invite/accept?baseURL=' +
			$store.state.baseDomainURL +
			userDomain +
			'&code=' +
			inviteCode

		return inviteLink
	},
	/**
	 * Builds the data payload for sending the user invite email
	 * @param email {string} The email of the user to send to
	 * @param emailDomain {string} The email domain of the current client. In this format: {@}example.org. Can be without of a domain -> null or empty string
	 * @param inviteCode {string} The unique invite code generated by the easyreg app backend
	 * @param expires {function} The UTC date-time string of the expiration date-time of the invite code
	 */
	buildUserInvitePayload(email, emailDomain, inviteCode, expires) {
		let payload = {
			to: email,
			url: this.buildUserInviteLink(emailDomain, inviteCode),
			expires: new Intl.DateTimeFormat(
				$store.getters.getLanguageLocale($store.state.selectedLanguage.languageCode),
				{
					timeZone: 'Europe/Zurich',
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				}
			).format(new Date(expires + 'Z')),
			language: $store.state.selectedLanguage.languageCode,
		}

		return payload
	},
	/**
	 * Updates the tags of the tagged users in a passed in comment to the new user name values
	 * @param commentText {string} The actual comment text
	 * @param taggedUserIds {array} The array of tagged user IDs
	 * @param userDataArray {array} The array of user data objects. Each object should hold the necessary information about every user. Minimum required information is "id" and "fullName"
	 * @param deletedLabel {string} The translated label to the current UI language of the "deleted" user name; the user that is deleted but still tagged will have it's name replaced with this label
	 */
	updateTaggedUsersInComment(commentText, taggedUserIds, userDataArray, deletedLabel) {
		// process the domainComment text to replace the author names for the UI if the author was deleted or changed his name

		// convert the comment to actual DOM nodes for easyer processing
		let htmlNoddedComment = this.getHtmlNodes(this.htmlDecode(commentText))

		for (let i = 0; i < taggedUserIds.length; i++) {
			// find the correspoinding tag in the comment for every tagged user
			// and replace it's name with the current one. It will replace in any case, even if it didn't change. This shouldn't cause any problems and is just more all-encompassing
			// the format of this is @{111} where 111 is the id of the user
			htmlNoddedComment
				.querySelectorAll('[data-user-placeholder="@{' + taggedUserIds[i] + '}"]')
				.forEach(function (node) {
					node.value = '@' + userDataArray.find((user) => user.id === taggedUserIds[i]).fullName
				})
		}

		// but here also look for a "mark"/a "placeholder" of a "deleted" user
		// and here replace it's name with a "deleted tag"
		// the format of this is @{inactive:111} where 111 is the id of the deleted user
		htmlNoddedComment
			.querySelectorAll('[data-user-placeholder^="@{inactive:"][data-user-placeholder$="}"]')
			.forEach(function (node) {
				node.value = '@' + deletedLabel
			})

		// convert nodes back to string and returns
		return this.htmlDecode(htmlNoddedComment.innerHTML)
	},
	/**
	 * Takes in a string with possible HTML elements, and return the proper HTML nodes DOM object
	 * @param input {string} The string to convert to nodes
	 */
	getHtmlNodes(input) {
		let div = document.createElement('div')
		div.innerHTML = input
		return div
	},
	/**
	 * Source: https://stackoverflow.com/questions/1912501/unescape-html-entities-in-javascript
	 * Both decodes existing HTML entities and leaves in already properly formed HTML tags
	 * This is useful if we want to end up with a properly formed HTML code
	 * E.g. it will decode: &lt;b&gt;bold&lt;/b&gt;&nbsp; <span>some text</span> into: <b>bold</b> <span>some text</span>
	 * @param input {string} The string to decode
	 */
	htmlDecode(input, commentType = null) {
		const css = {
			main: 'font-size: 13px; font-weight: bold; color: #227753;',
			comment: 'text-transform: capitalize;',
		}

		let e = document.createElement('textarea')
		if (commentType && commentType.toLowerCase() !== 'generic comment') {
			e.innerHTML = `
				<p style="${css.main}">
					[<span style="${css.comment}">${commentType}</span>]
				</p>
				${input}
			`
		} else {
			e.innerHTML = input
		}
		// handle case of empty input
		return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
	},
	/**
	 * Decodes HTML entities (https://www.w3schools.com/html/html_entities.asp) in a string and returns the decoded string
	 * This is useful if we only want to end up with a pure text string. It removes the existing properly formed HTML tags.
	 * E.g. it will decode: &lt;b&gt;bold&lt;/b&gt;&nbsp; into: <b>bold</b>
	 * but if the text already had existing properly formed e.g. <span> elements etc. it will remove it
	 * e.g. it will decode: <span>some text</span> into: some text
	 * @param input {string} The string to decode
	 */
	decodeHTMLEntities(input) {
		let div = document.createElement('div')
		div.innerHTML = input
		return div.innerText
	},
	/**
	 * Get a random number between 1 (included) and 100 (excluded, so 99 included)
	 */
	getConfirmationNumber() {
		return Math.floor(Math.random() * (100 - 1)) + 1 // between 1 (included) and 100 (excluded, so 99 included)
	},
	/**
	 * Merges two arrays removing duplicate instances of the same item (deep object compare for objects)
	 * e.g. the union of {1,2,3,4} and {2,3,4,5} is {1,2,3,4,5}
	 * also e.g. the union of {2,2,2,3} and {2,3,3,5} is {2,3,5}
	 * works on strings, numbers and objects
	 * @param array1 {Array} An array.
	 * @param array2 {Array} An array.
	 */
	unionOfArrays(array1 = [], array2 = []) {
		let filteredArray1 = []
		for (let i = 0; i < array1.length; i++) {
			let doesAlreadyExist = false
			for (let j = 0; j < filteredArray1.length; j++) {
				if (JSON.stringify(array1[i]) === JSON.stringify(filteredArray1[j])) {
					doesAlreadyExist = true
					break
				}
			}
			if (!doesAlreadyExist) filteredArray1.push(array1[i])
		}

		let filteredArray2 = array2.filter((item) => {
			for (let i = 0; i < filteredArray1.length; i++) {
				if (JSON.stringify(filteredArray1[i]) === JSON.stringify(item)) {
					return false
				}
			}
			return true
		})

		return filteredArray1.concat(filteredArray2)
	},
	/**
	 * Processes a potential paragraphId range (e.g. 17:19.1) by stripping the ending paragraphId and returning only the starting paragraphId.
	 * If a normal paragraphId without a range is passed in, it just returns that one back. The colon character is the indicator if it's a range or not.
	 * @param paragraphIdRange {string} A range of paragraphId's, e.g. 16:20. Or a normal paragraphId without a range
	 */
	processParagraphIdRange(paragraphIdRange) {
		let paragraphId = paragraphIdRange
		if (paragraphIdRange.indexOf(':') > -1)
			paragraphId = paragraphIdRange.substring(0, paragraphIdRange.indexOf(':'))

		return paragraphId
	},
	/**
	 * The function for pdf rendering
	 * @param pageNumber {string/integer} The page number of the page that will be rendered.
	 * @param canvas {DOM node} The DOM canvas node on which the page will be rendered.
	 * @param thePdf {object} The processed pdf by PDF.js.
	 * @param viewer {DOM node} The DOM node in which canvas renders.
	 * @param upperY {string/float} Coordinate at the top of the pdf at which the rendering should start, above which the pdf is cut off.
	 * @param lowerY {string/float} Coordinate at the bottom of the pdf at which the rendering should finish, below which the pdf is cut off.
	 * @param upperLeftX {string/float} Coordinate at the left side of the pdf at which the rendering should start, left of which the pdf is cut off.
	 * @param lowerRightX {number/float} Coordinate at the right side of the pdf at which the rendering should finish, right of which the pdf is cut off.
	 * @param textLayerDiv {DOM node} The DOM node in which the pdf text layer will get rendered.
	 * @param setTheWidthAndHeight {bool} Should this function set the width and the height of the pdf canvas? Otherwise the width and the height was set somewhere else.
	 * @param displayPaddingLeft {integer} When calculating the width of the canvas the viewer width is taken. But the viewer can have CSS paddings/margins applyed.
	 		Take the left padding into account here by reducing the total canvas width by this padding ammount.
	 * @param displayPaddingRight {integer} When calculating the width of the canvas the viewer width is taken. But the viewer can have CSS paddings/margins applyed.
	 		Take the right padding into account here by reducing the total canvas width by this padding ammount.
	 */
	renderPage: function (
		pageNumber,
		canvas,
		thePdf,
		viewer,
		upperY = null,
		lowerY = null,
		upperLeftX = null,
		lowerRightX = null,
		textLayerDiv = null,
		setTheWidthAndHeight = true,
		displayPaddingLeft = 0,
		displayPaddingRight = 0
	) {
		// I am defining the renderPage() function here already because it is used accross multiple components and URL addresses
		if (upperY) upperY = parseFloat(upperY)
		if (lowerY) lowerY = parseFloat(lowerY)
		if (upperLeftX) upperLeftX = parseFloat(upperLeftX)
		if (lowerRightX) lowerRightX = parseFloat(lowerRightX)

		return thePdf
			?.getPage(pageNumber)
			.then(function (page) {
				let viewport = page.getViewport({ scale: 1 })
				let viewerComputedStyle = viewer?.currentStyle || window.getComputedStyle(viewer, null)
				let desiredWidth = viewer.clientWidth // without scrollbar
				desiredWidth -=
					parseFloat(viewerComputedStyle.paddingLeft) + parseFloat(viewerComputedStyle.paddingRight) // without padding
				desiredWidth -= displayPaddingLeft // without additional padding
				desiredWidth -= displayPaddingRight // without additional padding
				let scale = desiredWidth / viewport.width
				let bottomCrop = 0
				let originalWidth = viewport.viewBox[2]

				if (upperLeftX !== null && lowerRightX !== null) {
					// handle upperLeftX
					if (viewport.rotation === 90) {
						viewport.viewBox[1] = upperLeftX
					} else if (viewport.rotation === 180) {
						viewport.viewBox[2] = viewport.viewBox[2] - upperLeftX
					} else {
						// assumed rotation = 0
						viewport.viewBox[0] = upperLeftX
					}

					// handle lowerRightX
					if (viewport.rotation === 90) {
						viewport.viewBox[2] =
							viewport.viewBox[2] + (lowerRightX - viewport.viewBox[2] - upperLeftX)
					} else if (viewport.rotation === 180) {
						viewport.viewBox[0] = originalWidth - lowerRightX
					} else {
						// assumed rotation = 0
						viewport.viewBox[2] = viewport.viewBox[2] - (viewport.viewBox[2] - lowerRightX)
					}

					// recalculate the scale
					scale = desiredWidth / (viewport.viewBox[2] - viewport.viewBox[0])
				}

				if (upperY !== null) {
					// we are generating pages in the article display zone
					if (viewport.rotation === 90) {
						viewport.viewBox[0] = viewport.viewBox[0] + upperY
					} else {
						viewport.viewBox[3] = viewport.viewBox[3] - upperY
					}
				}

				var scaledViewport = page.getViewport({
					viewBox: viewport.viewBox,
					scale: scale,
				})

				if (lowerY !== null) {
					let scaledLowerY = lowerY * scale

					let cutAtTheTop = 0
					if (upperY !== null) {
						// we already cut off the page at the top
						// take this into consideration
						cutAtTheTop = upperY * scale
					}

					bottomCrop = scaledViewport.height - scaledLowerY + cutAtTheTop

					if (scaledViewport.rotation === 90)
						bottomCrop = viewport.height * scale - scaledLowerY + cutAtTheTop
				}

				let context = canvas.getContext('2d')
				if (setTheWidthAndHeight) {
					if (viewport.rotation === 90) {
						canvas.height = viewport.height * scale - bottomCrop
					} else {
						canvas.height = scaledViewport.height - bottomCrop
					}
					canvas.width = desiredWidth
				}

				canvas.id = 'canvas-' + pageNumber

				let renderContext = {
					canvasContext: context,
					viewport: scaledViewport,
				}

				if (textLayerDiv) {
					// if text layer element was passed in render the text layer too
					return page
						.render(renderContext)
						.promise.then(function () {
							// Returns a promise, on resolving it will return text contents of the page
							return page.getTextContent()
						})
						.then(function (textContent) {
							// add new line characters to format the text better for user selecting
							for (let i = 0; i < textContent.items.length; i++) {
								if (
									i &&
									textContent.items[i + 1] &&
									textContent.items[i].transform[5] !== textContent.items[i + 1].transform[5] &&
									(textContent.items[i + 1].transform[5] >
										textContent.items[i].transform[5] + textContent.items[i].height ||
										textContent.items[i + 1].transform[5] <
											textContent.items[i].transform[5] - textContent.items[i].height)
								)
									textContent.items[i].str += '\n'
							}

							// Assign CSS to the text-layer element
							textLayerDiv.style.left = canvas.offsetLeft + 'px'
							textLayerDiv.style.top = canvas.offsetTop + 'px'
							textLayerDiv.style.height = canvas.height + 'px'
							textLayerDiv.style.width = canvas.width + 'px'

							// render the text layer
							return $store.state.pdfjsLib.renderTextLayer({
								textContent: textContent,
								container: textLayerDiv,
								viewport: scaledViewport,
								textDivs: [],
							})
						})
						.catch(function (error) {
							console.log(error)
						})
				} else {
					// if no text layer element was passed in, just render the page normally on a <canvas>
					return page.render(renderContext).promise
				}
			})
			.catch(function (error) {
				console.log(error)
			})
	},
	processCopiedPdfText(e) {
		// replace multiple spaces with only one space
		// this also has a side effect of fixing: when the text with new lines is copied in Firefox, they are preserved when pasted into Word
		e.clipboardData.setData('text/plain', window.getSelection().toString().replace(/  +/g, ' '))
		e.preventDefault()
	},
	resetDialogPosition() {
		// for all the dialogs, you can move the pop-up dialog around, this is to reset it to original position
		const closestDialogs = document.querySelectorAll('.v-dialog')
		for (let i = 0; i < closestDialogs.length; i++) {
			closestDialogs[i].style.display = 'none'
			closestDialogs[i].style.position = ''
			closestDialogs[i].style.left = ''
			closestDialogs[i].style.top = ''
		}
	},
	/**
	 * Replaces a character at a specified index with a replacement string or a character
	 * @param {string} str The string we're working with.
	 * @param {number} index The index at which to replace.
	 * @param {string} replacement The character or string to be inserted.
	 */
	replaceAtIndex(str, index, replacement) {
		if (index > str.length - 1) return str
		return str.substring(0, index) + replacement + str.substring(index + 1)
	},
	/**
	 * Checks if some HTML element is inside of a link. Returnes the link <a> element or null
	 * @param {DOM node} element. The DOM element for which we are looking if he is inside of a link <a>
	 */
	isSomeParentALink(element) {
		if (element && element.nodeName === 'A') return element
		return element.parentNode && this.isSomeParentALink(element.parentNode)
	},

	hasSomeParentTheClass(element, classname) {
		// source: https://stackoverflow.com/questions/16863917/check-if-class-exists-somewhere-in-parent-vanilla-js
		if (element && element?.className && element?.className?.split(' ')?.indexOf(classname) >= 0)
			return true
		return element?.parentNode && this.hasSomeParentTheClass(element?.parentNode, classname)
	},

	hasSomeChildTheClass(element, classname) {
		if (element && element.querySelectorAll('.' + classname).length > 0) return true
		return false
	},
	/**
	 * Looks of the offset of an element
	 * Important in case of looking of offsets inside a table where a regular .offestTop doesn't work
	 * Source initially: https://stackoverflow.com/questions/1044988/getting-offsettop-of-element-in-a-table
	 * @param {DOM node} element ; The DOM element for which we are looking an offset
	 */
	offset(scrollableContainer, elem) {
		if (!elem) elem = this

		let x = 0
		let y = 0

		while (elem && scrollableContainer.contains(elem) && elem !== scrollableContainer) {
			x += elem.offsetLeft
			y += elem.offsetTop

			elem = elem.offsetParent
		}

		return { left: x, top: y }
	},
	/**
	 * Scrolls the element into view with possible offset at the top (default JavaScript scrollIntoView() can't deal with offsets)
	 * @param {DOM node} scrollableContainer ; the DOM container of the element that will scroll (get it e.g. with document.querySelector())
	 * @param {DOM node} element ; The DOM element that should be scrolled into view; appear at the top (get it e.g. with document.querySelector().offsetHeight)
	 * @param {integer} manualTopOffset ; A DOM element can take up some space at the top (e.g. the header of the table in the changes page)
	 *		(get it e.g. with document.querySelector()), or we manually want to add some offset at the top
	 */
	scrollIntoView(scrollableContainer, element, manualTopOffset = 0) {
		if (scrollableContainer && element) {
			let elemOffsetTop = this.offset(scrollableContainer, element).top
			scrollableContainer.scrollTop = elemOffsetTop - manualTopOffset
		}
	},
	isValidDate(d) {
		// source: https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript?page=1&tab=votes#tab-top
		return d instanceof Date && !isNaN(d)
	},
	/**
	 * (Z)ulu time is (UTC)
	 */
	ensureZuluString(dateTimeString) {
		// the user must be sure that the string passed here is in Zulu (UTC)
		// the only thing checked here if it has a trailing 'Z' character which is necessary for Date() to know that it's dealing with the UTC date-time

		if (typeof dateTimeString !== 'string') return false

		if (dateTimeString[dateTimeString.length - 1] !== 'Z') dateTimeString += 'Z'

		if (!this.isValidDate(new Date(dateTimeString))) return false

		return dateTimeString
	},
	/**
	 * Converts a Zulu date-time string to CET timezone and a "nice" ISO 8601 string format
	 */
	toNiceCETISO8601Format(dateTimeString) {
		let zuluDateTimeString = this.ensureZuluString(dateTimeString)

		let date = new Date(zuluDateTimeString)
			.toLocaleString('en-GB', {
				timeZone: 'Europe/Zurich',
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			})
			.split('/')
			.reverse()
			.join('-')
		let time = new Date(zuluDateTimeString).toLocaleString('en-GB', {
			timeZone: 'Europe/Zurich',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		})

		return date + ' ' + time
	},

	/* Object equality */
	isEqual(obj1, obj2, fieldsToCheckFor) {
		// console.log({ ...obj1 }, { ...obj2 })
		if (Object.keys(obj1).length > 0 && Object.keys(obj2).length > 0) {
			return fieldsToCheckFor.every((key) => {
				/* If the datatype is string then lowercase and compare it */
				if (typeof obj1[key] === 'string' && typeof obj2[key] === 'string') {
					return obj1[key].toLowerCase() === obj2[key].toLowerCase()
				}
				/* If the data type is not string then directly compare it */
				return obj1[key] === obj2[key]
			})
		}
		return true
	},

	/* This function is a helper function because it might be needed 
	at multiple places. It converts an encoded URL into a normal string.
	*/
	decodeURL(url) {
		if (url.includes('%')) {
			return encodeURIComponent(url)
		}
		return decodeURIComponent(url)
	},

	groupBy(list, keyGetter, emptyLabel = 'empty') {
		const map = new Map()
		list.forEach((item) => {
			let key = keyGetter(item)

			if (key === '' || key === null) {
				key = emptyLabel
			}

			const collection = map.get(key)
			if (!collection) {
				map.set(key, [item])
			} else {
				collection.push(item)
			}
		})
		return map
	},

	formatBytes(bytes, decimals = 2) {
		if (!+bytes) return '0 Bytes'
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		const i = Math.floor(Math.log(bytes) / Math.log(k))

		return {
			unit: sizes[i],
			size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
			text: `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`,
		}
	},

	downloadPdf(filename, document) {
		if (!filename) throw new Error('File name was not provided')
		const path = '/pdfs/' + filename
		const save = document.createElement('a')
		save.href = path
		save.download = filename
		save.target = '_blank'
		document.body.appendChild(save)
		save.click()
		document.body.removeChild(save)
	},

	groupByKey(arr, property) {
		return arr.reduce((result, obj) => {
			var key = obj[property]
			if (!result[key]) {
				result[key] = []
			}
			result[key].push(obj)
			return result
		}, {})
	},
}
