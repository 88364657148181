var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["mx-2 mt-2 mb-0 box", _vm.color], attrs: { dense: "" } },
    [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }