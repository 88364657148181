// the vuex store file
import Vuex from 'vuex'
import Vue from 'vue'
import RegArtFormatter from '@/services/reg-art-formatter'
import * as regArtFormattingDictionaries from '@/services/reg-art-formatting-dictionaries'
import SearchModule from '../store/search'
import CommentsModule from '../store/comments'
import SharedModule from '../store/shared'
import RegTextModule from '../store/regTextChange'
import CollaborationModule from '../store/collaboration'
import DataLoadModule from '../store/dataload'
import TocModule from '../store/toc'
import ChangesModule from '../store/changesPage'
import ContextModule from '../store/context'
import LogModule from '../store/log'
import ChatModule from '../store/chat'

let md5 = require('md5')

Vue.use(Vuex)

function getAvailableRolesFromStorage() {
	// needs to be defined here outside of the vuex store because it's used in the vuex "state"
	let additionalUserDataJson = localStorage.getItem('easyRegAdditionalUserData')
	if (additionalUserDataJson) {
		let additionalUserData = JSON.parse(additionalUserDataJson)
		if (additionalUserData.listOfRoles !== undefined) {
			return additionalUserData.listOfRoles
		}
	}
	return null
}

const store = new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production', // if we are in the development mode: use strict
	state: {
		// if we need to force reload the main component (like we do with the account component) update this variable with the new date
		reloadMainComponentKey: new Date().getTime(),
		// when the user refreshes the page all data is lost and we need to refetch from the server things like domainURL and some user data
		// so wait until thats finished before loading any components
		allStartupDataLoaded: false,
		baseMainURL:
			process.env.NODE_ENV === 'development'
				? process.env.VUE_APP_MAIN_API_URL
				: window.location.origin + process.env.VUE_APP_MAIN_API_URL, // base MAIN API URL
		// base DOMAIN API URL ('http://localhost:8090/'); will be updated when the user logs in
		baseDomainURL: null,
		pdfjsLib: null,
		availableLanguages: [], // list of available languages, it is fetched on created() of the application (the main.js file)
		// default language. It can be changed in the header dropdown for the duration of the opened application.
		selectedLanguage: null, // { languageCode: 'FR' },
		status: '', // authentication status
		token: localStorage.getItem('easyRegToken') || '',
		// list of all available roles, will be fetched from the domain server once the app gets the domain base URL from the main server
		listOfRoles: getAvailableRolesFromStorage(),
		currentUser: null, // data about the current user, will be fetched from the domain server on login
		titles: [
			// map server titles to translation hooks to make it easier to change the titles in just one place if names change
			// code = server title code; translationHook: the parameter name used in translation files
			{ code: 'MR', translationHook: 'titleMr' },
			{ code: 'MS', translationHook: 'titleMs' },
			{ code: 'MRS', translationHook: 'titleMrs' },
			// { code: 'DR', translationHook: 'titleDr' } // not used anymore
		],
		languages: [
			{ code: 'FR', translationHook: 'languageFrench', locale: 'fr-CH' },
			{ code: 'EN', translationHook: 'languageEnglish', locale: 'en-GB' },
			{ code: 'DE', translationHook: 'languageGerman', locale: 'de-CH' },
			{ code: 'IT', translationHook: 'languageItalian', locale: 'it-CH' },
		],
		// user can resize the panes in the Read, Search and Changes pages. These hold the default size
		defaultTopPaneSize: 62,
		defaultChangesPaneSize: 60.6,
		defaultContextPaneSize: 25,
		// the next 2 "Global" variables are used Loading Termination System down to Context zone / Comments block
		// timestamps of the last clicks are saved here; they are used to ensure the execution of only the last click, i.e. stops all mid-executions chains of previous clicks
		// it's neccessary to have those 2 separated otherwise the click in the Context zone would prevent loading in the Comment block
		lastUserClickGlobalForContext: null,
		lastUserClickGlobalForComments: null,
		administrativeEmail: process.env.VUE_APP_SITE_ADMINISTRATION_EMAIL,
		dataPrivacyFileName: process.env.VUE_APP_DATA_PRIVACY_FILE_NAME,
		termsAndConditionsFileName: process.env.VUE_APP_TERMS_AND_CONDITIONS_FILE_NAME,
		baseAppTitle: 'e-Reg',
		// on the Read and Search pages there are custom keyboard actions implemented. They should be disabled if modal windows are opened
		// this also disables some tooltips (share comment, share regart and ask question) that would get in the way if the user clicks on the button and opens a modal
		disableCustomKeyboardActions: false,
		// number of rows/results to return per search
		resultsPerSearch:
			window.innerWidth <= parseInt(process.env.VUE_APP_TABLET_BREAKPOINT)
				? parseInt(process.env.VUE_APP_RESULTS_PER_SEARCH_SMALL_DEVICES)
				: parseInt(process.env.VUE_APP_RESULTS_PER_SEARCH_NORMAL_DEVICES),
		tabletBreakpoint: parseInt(process.env.VUE_APP_TABLET_BREAKPOINT),
		phoneBreakpoint: parseInt(process.env.VUE_APP_PHONE_BREAKPOINT),
		// here we map the language codes we get from the backend to the language codes we use in I18n (internationalization plugin)
		localeMappings: {
			EN: 'en-GB',
			FR: 'fr-CH',
			DE: 'de-CH',
			IT: 'it-CH',
		},
		toc: null, // this holds the current Table of Contents elements from the Read page. It is used when a user searches for an article in the Header on the Read page
		pdfPadding:
			window.innerWidth <= parseInt(process.env.VUE_APP_TABLET_BREAKPOINT)
				? parseInt(process.env.VUE_APP_APPLICATION_PADDING) / 2
				: parseInt(process.env.VUE_APP_APPLICATION_PADDING), // padding on the left and right of the pdfs everywhere in pixels
		RegArtFormatter: RegArtFormatter,
		regArtFormattingDictionaries: regArtFormattingDictionaries,
		areWeUsingRegArtTooltip: true, // either it's used globally in the entire app or not
		updateLayoutProps: {
			// should the layout auto-update on browser resize or a message display in the footer that the user needs to do that himself by refreshing the app with F5
			autoUpdateLayoutOnBrowserResize: JSON.parse(
				process.env.VUE_APP_AUTO_UPDATE_LAYOUT_ON_BROWSER_RESIZE.toLowerCase()
			),
			updateLayout: 0, // different components listen to a change in this variable to trigger on-browser-resize actions
			// how long to wait to update the layout; useful if the user is dragging the window which triggers the "resize" event every couple of miliseconds; we don't want to update that often
			layoutUpdateDelay: 2000,
			layoutUpdateTimeoutHandle: null, // current timeout handle, clear this and set a new one once browser "resize happens" so to not start the countles timers
		},
		scrollbarWidth: 0,
		doubleClickDelay: 250, // how much time to wait between clicks to decide if it was a doubleclick or two single clicks (ms) (used in various places where we detect double click)
		// this is the width of the canvas on which the RegArt pdf will be rendered
		// there are still paddings/margin/borders that will be added arround the canvas inside the the tooltip component
		regArtTooltipWidth: parseInt(process.env.VUE_APP_REGART_TOOLTIP_WIDTH),
		// this holds a list of all possible Internal Comment statuses
		internalCommentsStatuses: null,
		// RegArtToDisplay in all languages for the current RegArt
		// either in the edit comment window - popup or in the comments status select dropdown in the internal comments pane or in the comments table
		currentRegArtsToDisplay: null,
		userConsents: null,
		servicePlan: null,
		regTextChangeRegulations: null,
		regTextChanges: null,
		enrichedUserNames: [],
		selectedUser: [],
		clearSearch: 0,
	},
	getters: {
		clearSearch: (state) => state.clearSearch,

		// getter responses are automatically cached unless: https://stackoverflow.com/questions/50236767
		isLoggedIn: (state) => !!state.token,

		currentUser: (state) => state.currentUser,

		authStatus: (state) => state.status,

		servicePlan(state) {
			return state.servicePlan
		},

		jwtData: (state) => (state.token ? JSON.parse(atob(state.token.split('.')[1])) : null),

		userRoles: (state) =>
			state.token ? JSON.parse(atob(state.token.split('.')[1])).roles.split(',') : [],

		getRole: (state) => (frontendCode) => {
			if (state.listOfRoles) {
				let roleObject = state.listOfRoles.find((obj) => {
					return obj.frontendCode === frontendCode
				})
				if (roleObject) {
					return roleObject.role
				} else {
					return null
				}
			} else {
				return null
			}
		},
		getConsents: (state) => {
			return state.userConsents
		},
		getRoleTranslationHook: (state) => (backendOrFrontendRoleCode) => {
			if (
				state.listOfRoles.find((obj) => {
					return obj.role === backendOrFrontendRoleCode
				})
			) {
				// this will pass if the passed in code is a BACKEND_CODE
				return state.listOfRoles.find((obj) => {
					return obj.role === backendOrFrontendRoleCode
				}).translationHook
			} else if (
				state.listOfRoles.find((obj) => {
					return obj.frontendCode === backendOrFrontendRoleCode
				})
			) {
				// this will pass if the passed in code is a frontendCode
				return state.listOfRoles.find((obj) => {
					return obj.frontendCode === backendOrFrontendRoleCode
				}).translationHook
			} else {
				// otherwise return an empty string
				return ''
			}
		},
		getTitleTranslationHook: (state) => (titleBackendCode) => {
			if (
				state.titles.find((obj) => {
					return obj.code === titleBackendCode
				})
			) {
				return state.titles.find((obj) => {
					return obj.code === titleBackendCode
				}).translationHook
			} else {
				// otherwise return an empty string
				return ''
			}
		},
		getLanguageTranslationHook: (state) => (languageBackendCode) => {
			if (
				state.languages.find((obj) => {
					return obj.code === languageBackendCode
				})
			) {
				return state.languages.find((obj) => {
					return obj.code === languageBackendCode
				}).translationHook
			} else {
				// otherwise return an empty string
				return ''
			}
		},
		getLanguageLocale: (state) => (languageBackendCode) => {
			if (
				state.languages.find((obj) => {
					return obj.code === languageBackendCode
				})
			) {
				return state.languages.find((obj) => {
					return obj.code === languageBackendCode
				}).locale
			} else {
				// otherwise return an empty string
				return ''
			}
		},
		getLanguageBackendCode: (state) => (locale) => {
			if (
				state.languages.find((obj) => {
					return obj.locale === locale
				})
			) {
				return state.languages.find((obj) => {
					return obj.locale === locale
				}).code
			} else {
				// otherwise return an empty string
				return ''
			}
		},
		getTopPaneSize: (state) => () => {
			let additionalUserDataJson = localStorage.getItem('easyRegAdditionalUserData')
			if (additionalUserDataJson) {
				let additionalUserData = JSON.parse(additionalUserDataJson)
				if (additionalUserData.topPaneSize !== undefined) {
					return additionalUserData.topPaneSize
				}
			}
			return state.defaultTopPaneSize
		},
		getChangesPaneSize: (state) => () => {
			let additionalUserDataJson = localStorage.getItem('easyRegAdditionalUserData')
			if (additionalUserDataJson) {
				let additionalUserData = JSON.parse(additionalUserDataJson)
				if (additionalUserData.changesPaneSize !== undefined) {
					return additionalUserData.changesPaneSize
				}
			}
			return state.defaultChangesPaneSize
		},
		getChangesBottomPaneSize: (state) => () => {
			let additionalUserDataJson = localStorage.getItem('easyRegAdditionalUserData')
			if (additionalUserDataJson) {
				let additionalUserData = JSON.parse(additionalUserDataJson)
				if (additionalUserData.changesBottomPaneSize !== undefined) {
					return additionalUserData.changesBottomPaneSize
				}
			}
			return state.defaultChangesPaneSize
		},
		getContextBottomPaneSize: (state) => () => {
			let additionalUserDataJson = localStorage.getItem('easyRegAdditionalUserData')
			if (additionalUserDataJson) {
				let additionalUserData = JSON.parse(additionalUserDataJson)
				if (additionalUserData.contextBottomPaneSize !== undefined) {
					return additionalUserData.contextBottomPaneSize
				}
			}
			return state.defaultContextPaneSize
		},
		currentUserExcludedFromOwa: (state) => () => {
			if (!window.OWATracker) {
				return true
			} else if (state.currentUser === null || !window.untrackedIDs || window.untrackedIDs === []) {
				return false
			} else if (
				window.untrackedIDs
					.map((v) => v.toLowerCase())
					.indexOf(state.currentUser.emailHash.toLowerCase()) > -1
			) {
				return true
			} else {
				return false
			}
		},
		getCurrentRegArtFormattingDictionary: (state) => () => {
			switch (state.selectedLanguage.languageCode) {
				case 'FR':
					return state.regArtFormattingDictionaries.RegArtFormattingFrench
				case 'EN':
					return state.regArtFormattingDictionaries.RegArtFormattingEnglish
				case 'DE':
					return state.regArtFormattingDictionaries.RegArtFormattingGerman
				case 'IT':
					return state.regArtFormattingDictionaries.RegArtFormattingItalian
			}
		},
		getRegArtFormattingDictionaryByLangCode: (state) => (langCode) => {
			switch (langCode) {
				case 'FR':
					return state.regArtFormattingDictionaries.RegArtFormattingFrench
				case 'EN':
					return state.regArtFormattingDictionaries.RegArtFormattingEnglish
				case 'DE':
					return state.regArtFormattingDictionaries.RegArtFormattingGerman
				case 'IT':
					return state.regArtFormattingDictionaries.RegArtFormattingItalian
			}
		},
		getTableSettings: () => (tableName) => {
			let additionalUserDataJson = sessionStorage.getItem('easyRegAdditionalUserData')
			if (additionalUserDataJson) {
				let additionalUserData = JSON.parse(additionalUserDataJson)
				if (additionalUserData[tableName] !== undefined) {
					return additionalUserData[tableName]
				}
			}
			return null
		},
		/**
		 * Builds and returns the RegArtToDisplay for the passed in RegArt in the current user's UI language
		 * @param regArtObject {object} The full RegArt object with all the properties. But really the only properties we are using here are the "complete" - the full "RegArt string" and the "childrenNaming"
		 * @param childrenNaming {object} an array with paragraph naming codes for internationalization of paragraph and sub-paragraph names.
		 * Check here for more details (https://easyfinreg.atlassian.net/wiki/spaces/MW/pages/442826774/RegArtToDisplay+Internationalisation)
		 * If it exist, if it's passed in, i.e. not null, use that one, otherwise use the one from the regArtObject
		 * @param regTextDisplayName {string} The display name of the RegText in the current users language. As this is a synchronous function, we must pass in here the already translated display name
		 * i.e. in the already right language, instead of the we asncronously fetching the language from the backend like we do for getRegArtToDisplayInLang()
		 * if no regTextDisplayName has been passed in, the method will build the RegArtToDisplay without it
		 * @param regArtEndString {string} The RegArt string of the "ending" RegArt. So if the RegArtToDisplay needs to be built for a range of RegArts, not just for a single one
		 */
		getRegArtToDisplay:
			(state, getters) =>
			({
				regArtObject,
				childrenNaming = null,
				regTextDisplayName = null,
				// regArtEndString = null,
			}) => {
				let regArtToDisplay = null

				regArtToDisplay = new state.RegArtFormatter(
					getters.getCurrentRegArtFormattingDictionary()
				).makeDisplayName(
					regArtObject.complete,
					regTextDisplayName,
					childrenNaming ? childrenNaming : regArtObject.childrenNaming
				)

				// if (
				// 	regArtObject &&
				// 	regArtObject.complete &&
				// 	((regArtObject.childrenNaming && regArtObject.childrenNaming.length) || childrenNaming) &&
				// 	regTextDisplayName
				// ) {
				// 	console.log('one', regArtObject)
				// 	// if regText display name was passed in
				// 	regArtToDisplay = new state.RegArtFormatter(
				// 		getters.getCurrentRegArtFormattingDictionary()
				// 	).makeDisplayName(
				// 		regArtObject.complete,
				// 		regTextDisplayName,
				// 		childrenNaming ? childrenNaming : regArtObject.childrenNaming
				// 	)
				// } else if (
				// 	regArtObject &&
				// 	regArtObject.complete &&
				// 	((regArtObject.childrenNaming && regArtObject.childrenNaming.length) || childrenNaming) &&
				// 	regArtEndString
				// ) {
				// 	console.log('two')
				// 	// if an ending RegArt string was passed in
				// 	regArtToDisplay = new state.RegArtFormatter(
				// 		getters.getCurrentRegArtFormattingDictionary()
				// 	).makeDisplayNameForRange(
				// 		regArtObject.complete,
				// 		regArtEndString,
				// 		childrenNaming ? childrenNaming : regArtObject.childrenNaming
				// 	)
				// } else if (
				// 	regArtObject &&
				// 	regArtObject.complete &&
				// 	((regArtObject.childrenNaming && regArtObject.childrenNaming.length) || childrenNaming)
				// ) {
				// 	console.log('three', regArtObject)
				// 	// if regText display name and the ending RegArt string was NOT passed in
				// 	// i.e. the default scenario
				// 	regArtToDisplay = new state.RegArtFormatter(
				// 		getters.getCurrentRegArtFormattingDictionary()
				// 	).makeDisplayNameNoRegText(
				// 		regArtObject.complete,
				// 		childrenNaming ? childrenNaming : regArtObject.childrenNaming
				// 	)
				// } else if (
				// 	regArtObject &&
				// 	regArtObject.completeParagraphId &&
				// 	(!regArtObject.childrenNaming || !regArtObject.childrenNaming.length) &&
				// 	!childrenNaming &&
				// 	regTextDisplayName
				// ) {
				// 	console.log('four')
				// 	// add another special case: if there is no childrenNaming
				// 	// in that case just concat the completeParagraphId and the regTextDisplayName
				// 	regArtToDisplay = regArtObject.completeParagraphId + ' ' + regTextDisplayName
				// }

				return regArtToDisplay
			},
		getRegTextChangeRegulations: (state) => {
			return state.regTextChangeRegulations
		},
		getRegTextChanges: (state) => {
			return state.regTextChanges
		},
		selectedUser: (state) => {
			return state.selectedUser
		},
	},
	mutations: {
		updateClearSearch(state) {
			state.clearSearch = state.clearSearch + 1
		},
		updateChangeRouterData(state, payload) {
			state.routerData = payload
		},
		setScrollbarWidth(state, payload) {
			state.scrollbarWidth = payload
		},
		setEnrichedUserNames(state, payload) {
			state.enrichedUserNames = payload
		},
		setSelectedUser(state, payload) {
			state.selectedUser.push(payload)
		},
		triggerUpdateLayout(state) {
			// this triggers a change in the variable on which is listened to from different components to trigger actions
			// update first resultsPerSearch if necessary
			state.resultsPerSearch =
				window.innerWidth <= parseInt(process.env.VUE_APP_TABLET_BREAKPOINT)
					? parseInt(process.env.VUE_APP_RESULTS_PER_SEARCH_SMALL_DEVICES)
					: parseInt(process.env.VUE_APP_RESULTS_PER_SEARCH_NORMAL_DEVICES)
			// update the pdf padding if necessary (set it to 0 for tablet size)
			state.pdfPadding =
				window.innerWidth <= parseInt(process.env.VUE_APP_TABLET_BREAKPOINT)
					? parseInt(process.env.VUE_APP_APPLICATION_PADDING) / 2
					: parseInt(process.env.VUE_APP_APPLICATION_PADDING)
			// now update the updateLayout variable
			state.updateLayoutProps.updateLayout = new Date().getTime()
			window.clearTimeout(state.updateLayoutProps.layoutUpdateTimeoutHandle) // clear the previous timeout if it was set
		},
		setTheTimeoutHandle(state, theTimeout) {
			state.updateLayoutProps.layoutUpdateTimeoutHandle = theTimeout
		},
		useRegArtTooltip(state, areWeUsingIt) {
			state.areWeUsingRegArtTooltip = areWeUsingIt
		},
		setAvailableLanguages(state, payload) {
			// the payload is an array with "language" objects
			state.availableLanguages = payload
		},
		setUserConsents(state, consents) {
			state.userConsents = consents
		},
		setAvailableRoles(state, payload) {
			let localListOfRoles = []

			// map server role names to frontend names to make it easier to change the role names in just one place if role names change
			// role = server role name; frontendCode: the name used in frontend code execution (should not change); translationHook: the parameter name used in translation files
			payload.forEach((role) => {
				switch (role) {
					case 'READER':
						localListOfRoles.push({
							role: 'READER',
							frontendCode: 'reader',
							translationHook: 'readerLabel',
							hintTranslationHook: 'readerRoleHint',
						})
						break
					case 'COMMENT_EDITOR':
						localListOfRoles.push({
							role: 'COMMENT_EDITOR',
							frontendCode: 'commentEditor',
							translationHook: 'commentEditorLabel',
							hintTranslationHook: 'commentEditorRoleHint',
						})
						break
					case 'ADMINISTRATOR':
						localListOfRoles.push({
							role: 'ADMINISTRATOR',
							frontendCode: 'administrator',
							translationHook: 'administratorLabel',
							hintTranslationHook: 'administratorRoleHint',
						})
						break
					case 'CHANGE_EDITOR':
						localListOfRoles.push({
							role: 'CHANGE_EDITOR',
							frontendCode: 'changeEditor',
							translationHook: 'changeEditorLabel',
							hintTranslationHook: 'changeEditorRoleHint',
						})
						break
					case 'CHANGE_READER':
						localListOfRoles.push({
							role: 'CHANGE_READER',
							frontendCode: 'changeReader',
							translationHook: 'changeReaderLabel',
							hintTranslationHook: 'changeReaderRoleHint',
						})
						break
					case 'ISOLATED_TESTER':
						localListOfRoles.push({
							role: 'ISOLATED_TESTER',
							frontendCode: 'isolatedTester',
							translationHook: 'isolatedTesterLabel',
							hintTranslationHook: 'isolatedTesterRoleHint',
						})
						break

					case 'EREG_FULL_DOMAIN_ADMIN':
						localListOfRoles.push({
							role: 'EREG_FULL_DOMAIN_ADMIN',
							frontendCode: 'fullDomainAdmin',
							translationHook: 'fullDomainAdmin',
							hintTranslationHook: 'fullDomainAdminHint',
						})
						break

					case 'EREG_STATICDB_DATA_ADMIN':
						localListOfRoles.push({
							role: 'EREG_STATICDB_DATA_ADMIN',
							frontendCode: 'staticDbAdmin',
							translationHook: 'staticDbAdmin',
							hintTranslationHook: 'staticDbAdminHint',
						})
						break

					default:
						localListOfRoles.push({
							role: role,
							frontendCode: role,
							translationHook: role,
							hintTranslationHook: role,
						})
				}
			})

			state.listOfRoles = localListOfRoles
		},
		changeSelectedDDL(state, payload) {
			// DDL = document display language
			// the payload is the "language code"
			state.currentUser.documentDisplayLanguage = payload
		},
		setBaseDomainURL(state, baseDomainURL) {
			if (baseDomainURL[baseDomainURL.length - 1] === '/')
				baseDomainURL = baseDomainURL.substring(0, baseDomainURL.length - 1) // remove the last slash if present
			state.baseDomainURL = baseDomainURL
		},
		clearBaseDomainURL(state) {
			state.baseDomainURL = null
		},
		// AUTHENTICATION
		auth_request(state) {
			state.status = 'loading'
		},
		auth_success(state, token) {
			state.status = 'success'
			state.token = token
			localStorage.setItem('easyRegToken', token)
			// for auth to be completely successful, the token needs to be added to all axios headers manually: $http.defaults.headers.common['Authorization'] = 'Bearer ' + token
		},
		auth_error(state) {
			state.status = 'error'
			localStorage.removeItem('easyRegToken')
		},
		logout(state, payload) {
			state.status = ''
			state.token = ''
			localStorage.clear()
			if (payload && payload.$http) {
				delete payload.$http.defaults.headers.common['Authorization']
			}
			state.currentUser = null
		},
		clearState(state) {
			state.CollaborationModule.internalCommentsFlat = []
			state.CollaborationModule.beautifiedRegArtNames = {}
			state.CollaborationModule.authorsDictionary = {}
			state.CollaborationModule.changeUsers = []
			state.CollaborationModule.changeUsersDictionary = {}
			state.CollaborationModule.commentTypes = []
			state.CollaborationModule.commentTypesDictionary = {}
			state.CollaborationModule.statusTypes = []
			state.CollaborationModule.statusTypesDictionary = {}
		},
		setUserData(state, user) {
			state.currentUser = {
				// I have to save the database ID here for the edge case if admin changed his own email
				// then clicked on the edit button for his own account again in the users list
				// here now I have no way of figuring out that he is editing his own account
				// because the emails from the JWT (which would identify the current user) and the one from the users list table are different
				// because the JWT didn't get updated when admin changed his email
				// and I need to know if he is editing his won account or someone elses to display the "Change Password" or "Reset Password" button
				id: user.id,
				idHash: md5(user.id), // used for tracking
				preferredLanguage: user.preferredLanguage,
				fullName: user.fullName,
				emailHash: md5(user.email),
				email: user.email,
				title: user.title,
				documentDisplayLanguage: user.documentDisplayLanguage,
			}

			// build the initials
			let re = /\s{1,}/
			// break the full name into array of words
			let nameList = user.fullName.split(re)
			let initials = ''
			for (let i = 0; i < nameList.length; i++) {
				// loop through words
				if (i < 3) {
					// look at only the first 3 and take the first letters of those first 3 words and capitalize them
					initials += nameList[i][0].toString().toUpperCase()
				}
			}
			state.currentUser.initials = initials

			// set the language
			for (let i = 0; i < state.availableLanguages.length; i++) {
				if (state.availableLanguages[i].languageCode === user.preferredLanguage) {
					state.selectedLanguage = state.availableLanguages[i]
					break
				}
			}
		},
		setTheStartupDataLoadedState(state, allStartupDataLoaded) {
			state.allStartupDataLoaded = allStartupDataLoaded
		},
		reloadTheMainComponent(state) {
			state.reloadMainComponentKey = new Date().getTime()
		},
		setAdditionalUserData(state, data) {
			let additionalUserDataJson = localStorage.getItem('easyRegAdditionalUserData')
			let additionalUserData = {}
			if (additionalUserDataJson) {
				additionalUserData = JSON.parse(additionalUserDataJson)
			}
			let keyNames = Object.keys(data)
			for (let i = 0; i < keyNames.length; i++) {
				additionalUserData[keyNames[i]] = data[keyNames[i]]
			}
			localStorage.setItem('easyRegAdditionalUserData', JSON.stringify(additionalUserData))
		},
		setAdditionalUserDataToSessionStorage(state, data) {
			let additionalUserDataJson = sessionStorage.getItem('easyRegAdditionalUserData')
			let additionalUserData = {}
			if (additionalUserDataJson) {
				additionalUserData = JSON.parse(additionalUserDataJson)
			}
			let keyNames = Object.keys(data)
			for (let i = 0; i < keyNames.length; i++) {
				additionalUserData[keyNames[i]] = data[keyNames[i]]
			}
			sessionStorage.setItem('easyRegAdditionalUserData', JSON.stringify(additionalUserData))
		},
		setPdfjsLib(state, pdfjsLib) {
			state.pdfjsLib = pdfjsLib
		},
		setInternalCommentsStatuses(state, internalCommentsStatuses) {
			state.internalCommentsStatuses = internalCommentsStatuses
		},
		setLastUserClickGlobalForContext(state, clickTime) {
			state.lastUserClickGlobalForContext = clickTime
		},
		setLastUserClickGlobalForComments(state, clickTime) {
			state.lastUserClickGlobalForComments = clickTime
		},
		setDialogState(state, isDialogOpened) {
			state.disableCustomKeyboardActions = isDialogOpened
		},
		setToc(state, toc) {
			state.toc = toc
		},
		setCurrentRegArtsToDisplay(state, regArtsToDisplay) {
			state.currentRegArtsToDisplay = regArtsToDisplay
		},
		clearCurrentRegArtsToDisplay(state) {
			state.currentRegArtsToDisplay = null
		},
		setRegTextChangeRegulation(state, regTextChangeRegulations) {
			state.regTextChangeRegulations = regTextChangeRegulations
		},
		setRegTextChanges(state, regTextChanges) {
			state.regTextChanges = regTextChanges
		},
		setServicePlan(state, servicePlan) {
			state.servicePlan = servicePlan.servicePlanName
		},
	},
	actions: {
		getServicePlan({ commit, state }, payload) {
			payload
				.$http({
					url: state.baseMainURL + '/domain/ServicePlan',
					method: 'GET',
				})
				.then((result) => {
					commit('setServicePlan', result.data)
				})
		},
		setChangeRouterData({ commit }, payload) {
			commit('updateChangeRouterData', payload)
		},
		getRegArtToDisplayInLang(
			{ state, getters, dispatch },
			{ $http, commentRegArtObject, langCode }
		) {
			// generate and return the RegArtToDisplay in the passed in language for the passed in RegArt
			return new Promise((resolve, reject) => {
				dispatch('getRegTextData', {
					$http: $http,
					regTextId: commentRegArtObject.regTextId,
					primaryLanguage: langCode,
					fallbackLanguage: langCode,
				})
					.then((regTextData) => {
						let regArtToDisplay = new state.RegArtFormatter(
							getters.getRegArtFormattingDictionaryByLangCode(langCode)
						).makeDisplayName(
							commentRegArtObject.complete,
							regTextData.displayName,
							commentRegArtObject.childrenNaming
						)

						resolve(regArtToDisplay)
					})
					.catch((error) => {
						console.log('Error getting RegText data:')
						console.log(error)
						reject(false)
					})
			})
		},
		setCurrentRegArtsToDisplay(
			{ state, getters, dispatch, commit },
			{ $http, commentRegArtObject }
		) {
			// sets all regArtsToDisplay for the passed in RegArt for all languages
			return new Promise((resolve, reject) => {
				// if the reg arts to display have already been set just exit here with a success
				if (state.currentRegArtsToDisplay) return resolve(true)

				// otherwise do all that needs to be done
				let regArtsToDisplay = {}
				let startupActions = []

				for (let i = 0; i < state.languages.length; i++) {
					// we need to do this for every language: call getRegTextData (4 times, 1 for every language) which calls POST /ListOfRegulation 4 times
					// reference Jira: https://easyfinreg.atlassian.net/browse/FE-477
					if (commentRegArtObject && commentRegArtObject.regTextId) {
						startupActions.push(
							dispatch('getRegTextData', {
								$http: $http,
								regTextId: commentRegArtObject?.regTextId,
								primaryLanguage: state.languages[i]?.code,
								fallbackLanguage: state.languages[i]?.code,
							})
								.then((regTextData) => {
									regArtsToDisplay[state.languages[i]?.code] = new state.RegArtFormatter(
										getters.getRegArtFormattingDictionaryByLangCode(state.languages[i]?.code)
									).makeDisplayName(
										commentRegArtObject.complete,
										regTextData.displayName,
										commentRegArtObject.childrenNaming
									)

									// here we must return instead of resolve()
									// because if we resolve() the first request in the for() loop will exit and return it's value to the whole setCurrentRegArtsToDisplay() function
									return true
								})
								.catch((error) => {
									console.log('Error getting RegText data:')
									console.log(error)

									// here we should reject() instead of return because this tells the Promise.all() function that there was an error
									// and it will exit the setCurrentRegArtsToDisplay() function with a reject()
									reject(false)
								})
						)
					}
				}

				Promise.all(startupActions)
					.then(function () {
						commit('setCurrentRegArtsToDisplay', regArtsToDisplay)
						resolve(true)
					})
					.catch(function (error) {
						console.log(error)
						reject(false)
					})
			})
		},
		getRegTextData({ state }, { $http, regTextId, primaryLanguage, fallbackLanguage }) {
			return new Promise((resolve, reject) => {
				$http({
					url: state.baseMainURL + '/ListOfRegulation/',
					method: 'POST',
					data: {
						fallbackLanguage: fallbackLanguage,
						primaryLanguage: primaryLanguage,
						regTextIds: [regTextId],
					},
				})
					.then((result) => {
						// yes this is a correct syntax. the data returned is an array, but it has only 1 element because we are requesting only 1 regTextId at a time
						resolve(result.data[0])
					})
					.catch((error) => reject(error))
			})
		},
		registerBrowserResize({ commit, state }) {
			if (state.updateLayoutProps.autoUpdateLayoutOnBrowserResize) {
				window.clearTimeout(state.updateLayoutProps.layoutUpdateTimeoutHandle) // clear the previous timeout if it was set
				// remember the updateLayout value at this point, after the delay check if it's been changed (if something else [orientation change]) already triggered the layout update
				// if it has, then don't do this update, this could hapen if we are on a handheld device and are changing orientation
				// but with clearing the timeout everytime we set the updateLayout, this will probably never happen
				let localUpdateLayout = state.updateLayoutProps.updateLayout
				commit(
					'setTheTimeoutHandle',
					window.setTimeout(function () {
						if (state.updateLayoutProps.updateLayout === localUpdateLayout)
							commit('triggerUpdateLayout')
					}, state.updateLayoutProps.layoutUpdateDelay)
				)
			}
		},
		// AUTHENTICATION
		login({ commit, state, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				commit('auth_request')
				dispatch('setBaseDomainUrl', {
					$http: payload.$http,
					email: payload.user.email,
				})
					.then(() => {
						payload
							.$http({
								url: state.baseDomainURL + '/users/authenticate',
								data: payload.user,
								method: 'POST',
							})
							.then((domainResp) => {
								const token = domainResp.data.token

								payload.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token

								dispatch('getUserData', { $http: payload.$http })
									.then((getUserDataResp) => {
										commit('auth_success', token)
										resolve(getUserDataResp)
									})
									.catch((getUserDataErr) => {
										console.log('Error getting user data.')
										reject(getUserDataErr)
									})
							})
							.catch((domainErr) => {
								commit('auth_error')
								reject(domainErr)
							})
					})
					.catch((mainErr) => {
						commit('auth_error')
						reject(mainErr)
					})
			})
		},

		async loginByEmail({ dispatch }, email) {
			try {
				const baseData = await dispatch(
					'setBaseDomainUrl',
					{ $http: this._vm.$http, email },
					{ root: true }
				)
				const endpoint = `${
					baseData.data.baseUrl
				}/users/requestLoginPerEmail?email=${encodeURIComponent(email)}`
				const data = await this._vm.$http.get(endpoint)
				return data
			} catch (e) {
				console.log(e)
			}
		},

		async loginUsingToken({ dispatch, commit }, payload) {
			try {
				// GET BASE DOMAIN URL
				const baseData = await dispatch(
					'setBaseDomainUrl',
					{ $http: this._vm.$http, email: payload.email },
					{ root: true }
				)

				// DO LOGIN BY EMAIL
				const endpoint = `${baseData.data.baseUrl}/users/loginUsingToken?email=${encodeURIComponent(
					payload.email
				)}&temporaryToken=${payload.temporaryToken}`

				const resp = await this._vm.$http.post(endpoint)
				const token = resp.data.token
				this._vm.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token

				// GET USER DATA
				const userData = await dispatch('getUserData', {
					$http: this._vm.$http,
				})

				commit('auth_success', token)

				return {
					response: resp,
					userData,
				}
			} catch (e) {
				return {
					type: 'error',
					...e.response.data,
				}
			}
		},

		logout({ commit }, payload) {
			return new Promise((resolve) => {
				commit('logout', payload)
				commit('clearState')
				resolve()
			})
		},
		changeSelectedDDL({ commit, state, getters }, payload) {
			return new Promise((resolve, reject) => {
				let domainPayload = {
					documentDisplayLanguage: payload.languageCode,
					fallbackLanguage: state.currentUser.preferredLanguage,
					fullName: state.currentUser.fullName,
					preferredLanguage: state.currentUser.preferredLanguage,
					title: state.currentUser.title,
					email: state.currentUser.email,
				}
				if (getters.userRoles.includes(getters.getRole('administrator')))
					domainPayload.roles = getters.userRoles
				payload.$http
					.put(state.baseDomainURL + '/users/' + state.currentUser.id, domainPayload)
					.then((domainResult) => {
						commit('changeSelectedDDL', domainResult.data.user.documentDisplayLanguage)
						resolve(domainResult)
					})
					.catch((domainError) => {
						console.log(domainError.response)
						reject(domainError)
					})
			})
		},
		setBaseDomainUrl({ commit, state }, payload) {
			return new Promise((resolve, reject) => {
				payload
					.$http({
						url: state.baseMainURL + '/domain/base-url?email=' + encodeURIComponent(payload.email),
						method: 'GET',
						// transformRequest: [
						// 	(data, headers) => {
						// 		delete headers.common.Authorization
						// 		return data
						// 	},
						// ],
					})
					.then((mainResp) => {
						commit('setBaseDomainURL', mainResp.data.baseUrl)
						resolve(mainResp)
					})
					.catch((mainErr) => {
						reject(mainErr)
					})
			})
		},
		// get list of notification with id
		getUserConsents({ commit, state }, payload) {
			payload
				.$http({
					url: state.baseDomainURL + '/UserEmailPreferences/consents',
					method: 'GET',
				})
				.then((result) => {
					commit('setUserConsents', result.data)
				})
		},
		// send user selected notifications
		UserConsents({ state }, consentList) {
			consentList.$http({
				url: state.baseDomainURL + '/UserEmailPreferences/',
				method: 'PUT',
				data: consentList.consents,
			})
		},
		getAvailableUserRoles({ commit, state }, payload) {
			// role management
			// get the roles
			return new Promise((resolve, reject) => {
				payload
					.$http({
						url: state.baseMainURL + '/domain/Roles',
						method: 'GET',
					})
					.then((result) => {
						let availableRolesLocal = []
						for (let i = 0; i < result.data.length; i++) {
							availableRolesLocal.push(result.data[i])
						}
						commit('setAvailableRoles', availableRolesLocal)
						commit('setAdditionalUserData', { listOfRoles: state.listOfRoles })
						resolve(result)
					})
					.catch((error) => reject(error))
			})
		},
		async getRegTextChangeRegulation({ state }) {
			try {
				const result = await this._vm.$http({
					url: state.baseMainURL + '/ListOfRegulation/RegTextChange',
					method: 'GET',
				})
				this.commit('setRegTextChangeRegulation', result.data)
				return result
			} catch (err) {
				console.log(err.message)
				throw err
			}
		},
		getRegTextChange({ state }, payload) {
			//console.log(payload)
			return new Promise((resolve, reject) => {
				payload
					.$http({
						url: state.baseDomainURL + '/RegTextChange/',
						method: 'GET',
					})
					.then((result) => {
						this.commit('setRegTextChanges', result.data)
						resolve(result)
					})
					.catch((err) => {
						console.log(err.message)
						reject(err)
					})
			})
		},
		getUserData({ commit, state, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				payload
					.$http({ url: state.baseDomainURL + '/users/current', method: 'GET' })
					.then((domainResp) => {
						commit('setUserData', domainResp.data)
						dispatch('getAvailableUserRoles', { $http: payload.$http })
							.then((availableUserRoles) => resolve(availableUserRoles))
							.catch((userRolesError) => reject(userRolesError))
					})
					.catch((mainErr) => {
						if (payload.startupRequest && mainErr.response.status === 401) {
							// remove the token from localStorage because it is invalid
							commit('clearBaseDomainURL')
							commit('logout', payload)
							resolve(true)
						} else {
							reject(mainErr)
						}
					})
			})
		},
		owaExists() {
			// OWA is downloaded async so it doesn't exist immediately, check here when it becomes available
			return new Promise((resolve, reject) => {
				// DISABLE OWA
				const allowOWA = process.env.VUE_APP_ALLOW_OWA === 'true'
				if (!allowOWA) reject()

				const checkInterval = 50
				const timeout = 3000
				const waitStart = Date.now()

				const interval = setInterval(() => {
					if (window.OWATracker) {
						clearInterval(interval)

						return resolve()
					}

					if (Date.now() >= waitStart + timeout) {
						clearInterval(interval)

						reject(new Error('window.OWATracker undefined after waiting for: ' + timeout + ' ms.'))
					}
				}, checkInterval)
			})
		},
		trackPageVisit({ state, dispatch, getters }, payload) {
			const allowOWA = process.env.VUE_APP_ALLOW_OWA === 'true'

			if (!allowOWA) return

			// build base URL
			const loc = window.location
			let protocol = loc.protocol
			if (protocol.slice(-1) !== ':') protocol += ':'
			const baseUrl = protocol + '//' + loc.host
			const baseUrlHasSlash = baseUrl.slice(-1) === '/'
			let url =
				baseUrl +
				(baseUrlHasSlash ? payload.route.fullPath.replace(/^\//, '') : payload.route.fullPath)
			if (payload.urlAdd) {
				// urlAdd is something to be attached to the real URL
				if (url.indexOf('?') === -1) url += payload.urlAdd
				else url = url.replace('?', payload.urlAdd + '?')
			}

			dispatch('owaExists')
				.then(function () {
					const allowOWA = process.env.VUE_APP_ALLOW_OWA === 'true'
					if (!getters.currentUserExcludedFromOwa() && allowOWA) {
						let myEvent = window.OWATracker.makeEvent()

						myEvent.setEventType('base.page_request')

						if (payload && payload.actionData) {
							const event_name = payload.actionData?.event_name

							if (!url.includes('?')) {
								url += `?event_name=${event_name}`
							} else {
								url += `&event_name=${event_name}`
							}

							if (payload.actionData.facet) {
								url += `&facet=${payload.actionData.facet}`
							}

							if (payload.actionData.toc) {
								url += `&toc=${payload.actionData.toc}`
							}

							if (payload.actionData.warningText) {
								url += `&warning=${payload.actionData.warningText}`
							}

							if (payload.actionData.language) {
								url += `&language=${payload.actionData.language}&versionDate=${payload.actionData.versionDate}`
							}

							if (payload.actionData.event_name === 'user_deleted') {
								const { user_id, name, email } = payload.actionData
								url += `&userId=${user_id}&name=${name}&email=${email}`
							}

							if (payload.actionData.searchTerm) {
								url += `&searchTerm=${payload.actionData.searchTerm}`
							}

							if (payload.actionData.typeOfFilter) {
								url += `&typeOfFilter=${payload.actionData.typeOfFilter}`
							}
						}

						myEvent.set('page_url', url)

						if (payload.pageType) {
							window.OWATracker.setPageType(payload.pageType)
						}

						window.OWATracker.setCustomVar(
							1,
							'user_id',
							state.currentUser ? state.currentUser.idHash : null,
							'visitor'
						)

						window.OWATracker.setCustomVar(2, 'base_domain_url', state.baseDomainURL, 'session')

						window.OWATracker.setCustomVar(3, 'screen_width', window.innerWidth, 'page')

						window.OWATracker.setCustomVar(4, 'screen_height', window.innerHeight, 'page')

						window.OWATracker.trackEvent(myEvent)
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
	},
	modules: {
		SearchModule,
		CommentsModule,
		SharedModule,
		RegTextModule,
		CollaborationModule,
		DataLoadModule,
		TocModule,
		ChangesModule,
		context: ContextModule,
		log: LogModule,
		chat: ChatModule,
	},
})

export default store
