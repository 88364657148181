var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "mt-8 mx-lg-2 collaboration_dashboard",
      attrs: { fluid: "" }
    },
    [
      !_vm.isChatUser(_vm.$store.getters.userRoles)
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "9", md: "7", lg: "5", xl: "4" } },
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "2", raised: "", rounded: "" } },
                    [
                      _c("v-card-title", { staticClass: "mb-3" }, [
                        _c("span", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$t("landingDashboard.heading")))
                        ])
                      ]),
                      _c(
                        "v-card-text",
                        {
                          staticClass: "pb-1 pl-6 text",
                          attrs: { "data-cy": "active-todo" }
                        },
                        [
                          _vm.todo_v1
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("landingDashboard.todo.v1")) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.todo_v2
                            ? _c(
                                "div",
                                [
                                  _c("i18n", {
                                    attrs: { path: "landingDashboard.todo.v2" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "X1",
                                          fn: function() {
                                            return [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                  attrs: {
                                                    to: {
                                                      name: "comments",
                                                      query: {
                                                        taggedUserIds:
                                                          _vm.userId,
                                                        status: [1, 2],
                                                        resolved: 0
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.statistic
                                                          .noOfToDoComments +
                                                          _vm.statistic
                                                            .noOfInProgressComments
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1653387715
                                    )
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.todo_v3
                            ? _c(
                                "div",
                                [
                                  _c("i18n", {
                                    attrs: { path: "landingDashboard.todo.v3" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "X1",
                                          fn: function() {
                                            return [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                  attrs: {
                                                    to: {
                                                      name: "comments",
                                                      query: {
                                                        taggedUserIds:
                                                          _vm.userId,
                                                        status: [1],
                                                        resolved: 0
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.statistic
                                                          .noOfToDoComments
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "X2",
                                          fn: function() {
                                            return [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                  attrs: {
                                                    to: {
                                                      name: "comments",
                                                      query: {
                                                        taggedUserIds:
                                                          _vm.userId,
                                                        status: [2],
                                                        resolved: 0
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.statistic
                                                          .noOfInProgressComments
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3973710019
                                    )
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      ),
                      !_vm.activeUser || _vm.roleLength
                        ? _c(
                            "v-card-text",
                            {
                              staticClass: "pb-1 pl-6 text",
                              attrs: { "data-cy": "active-changes" }
                            },
                            [
                              _c("i18n", {
                                attrs: {
                                  path: "landingDashboard.owner",
                                  tag: "div"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "Y",
                                      fn: function() {
                                        return [
                                          _vm.statistic
                                            .noOfOnGoingChangeElements > 0
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                  attrs: {
                                                    to: {
                                                      name: "changes",
                                                      query: {
                                                        "domainData.owner":
                                                          _vm.userId,
                                                        "domainData.status": [
                                                          "R",
                                                          "G",
                                                          "A",
                                                          "?",
                                                          "null"
                                                        ]
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.statistic
                                                          .noOfOnGoingChangeElements
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.statistic
                                                        .noOfOnGoingChangeElements
                                                    ) +
                                                    " "
                                                )
                                              ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1967450001
                                )
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-card-text",
                        {
                          staticClass: "pl-6 text",
                          attrs: { "data-cy": "total-comments" }
                        },
                        [
                          _vm.statistic.noOfTotalTaggedComments > 0
                            ? _c(
                                "div",
                                [
                                  _c("i18n", {
                                    attrs: {
                                      path: "landingDashboard.tagged.v2",
                                      tag: "div"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "Z",
                                          fn: function() {
                                            return [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "px-1 font-weight-bold",
                                                  attrs: {
                                                    to: {
                                                      name: "comments",
                                                      query: {
                                                        taggedUserIds:
                                                          _vm.userId,
                                                        status: [],
                                                        resolved: 0
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.statistic
                                                          .noOfTotalTaggedComments
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      1127065769
                                    )
                                  })
                                ],
                                1
                              )
                            : _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("landingDashboard.tagged.v1"))
                                )
                              ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }