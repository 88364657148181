<template>
	<div dense :class="['mx-2 mt-2 mb-0 box', color]">
		{{ alertMessage }}
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: 'info',
		},

		alertMessage: {
			type: String,
			default: () => 'Alert Message',
		},
	},
	computed: {
		color() {
			switch (this.type) {
				case 'warning-1':
					return 'warning-1-color'

				case 'warning-2':
					return 'warning-2-color'

				case 'warning-3':
					return 'warning-3-color'

				default:
					return 'gray'
			}
		},
	},
}
</script>

<style scoped>
.box {
	padding: 5px;
	border-radius: 2px;
	font-size: 13px;
}
.warning-1-color {
	background: gray;
	color: white;
}

.warning-2-color {
	background: #85cdd9;
	color: #237853;
}

.warning-3-color {
	background: #ed823d;
	color: #fff;
}
</style>
