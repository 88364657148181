var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      ref: "tooltip",
      attrs: {
        "content-class": _vm.hideTheTooltipInitially
          ? "regart-tooltip-content hide-tooltip"
          : _vm.returnedMessages.length > 0
          ? _vm.articleLoading || !_vm.displayRegArtTooltip
            ? "regart-tooltip-content with-messages"
            : "regart-tooltip-content with-messages show-tooltip"
          : _vm.articleLoading || !_vm.displayRegArtTooltip
          ? "regart-tooltip-content"
          : "regart-tooltip-content show-tooltip"
      },
      model: {
        value: _vm.displayRegArtTooltip,
        callback: function($$v) {
          _vm.displayRegArtTooltip = $$v
        },
        expression: "displayRegArtTooltip"
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.onClickOutside,
              expression: "onClickOutside"
            }
          ],
          ref: "tooltip-viewer",
          staticClass: "tooltip-viewer",
          class: [
            { loading: _vm.articleLoading },
            { "tablet-screen": _vm.tabletScreenWidth },
            { "phone-screen": _vm.phoneScreenWidth }
          ],
          on: { click: _vm.handleClick }
        },
        [
          _vm.articleLoading
            ? _c("v-progress-linear", {
                attrs: { indeterminate: "", height: "4" }
              })
            : _vm._e(),
          _vm.currentErrorCode
            ? _c(
                "div",
                {
                  ref: "error-div",
                  staticClass: "notification-text error--text"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("regArtTooltip." + _vm.currentErrorCode)) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.returnedMessages.length > 0
            ? _c("MessagesComponent", {
                staticClass: "messages",
                attrs: { messages: _vm.returnedMessages }
              })
            : _vm._e(),
          _vm._l(_vm.pageNumbersToRender, function(pageNumber) {
            return _c(
              "div",
              { key: pageNumber, staticClass: "canvas-container" },
              [
                _c("canvas", {
                  ref: "tooltip-canvas-" + pageNumber,
                  refInFor: true,
                  attrs: { tabindex: "1" }
                })
              ]
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }