<!-- the original vuetify data table enhanced with additional functionality. It should have everything the original v-data-table has -->
<!-- + "easyreg-table-name" is required to be set on the table where it's used -->
<!-- documentaion confluence page: https://easyfinreg.atlassian.net/wiki/spaces/FRON/pages/1306263568/Easyreg+table+component -->
<!--
corresponding implementaion Jiras:
Research Epic: https://easyfinreg.atlassian.net/browse/FE-356
Implementaion Epic: https://easyfinreg.atlassian.net/browse/FE-409
More Jiras: https://easyfinreg.atlassian.net/browse/FE-458
-->
<template>
	<div class="easyreg-table">
		<div
			class="global-functionalities"
			@focus.capture="globalFunctionsFocused = true"
			@blur.capture="globalFunctionsFocused = false"
		>
			<v-text-field
				ref="searchInput"
				v-model="searchValue"
				dense
				solo
				flat
				hide-details
				clearable
				:placeholder="searchPlaceholder"
				autocomplete="off"
				class="easyreg-table-search"
			>
				<v-icon slot="prepend" color="white">search</v-icon>
			</v-text-field>

			<slot name="toolbar"></slot>

			<v-menu
				ref="settings-dialog"
				content-class="easyreg-table-settings-content"
				:close-on-content-click="false"
				bottom
				nudge-top="6"
				left
				nudge-right="6"
			>
				<template v-slot:activator="{ on }">
					<v-icon class="settings-icon" color="white" v-on="on">settings</v-icon>
				</template>

				<v-list class="settings-list">
					<v-list-item class="close-settings-dialog">
						<v-list-item-title>
							<!-- this close icon additionally closes the menu -->
							<v-icon @click="$refs['settings-dialog'].isActive = false">clear</v-icon>
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-title>
							<!-- this button removes all filters -->
							<v-btn color="primary" @click="removeAllFilters()">
								<span>{{ $t('easyregTable.removeAllFiltersButton') }}</span>
							</v-btn>
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-title>
							<!-- this button resets all the filters to the state they were when the user loaded this page -->
							<v-btn color="primary" @click="resetSettingsToBaseState()">
								<span>{{ $t('easyregTable.resetAllFiltersButton') }}</span>
							</v-btn>
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-title>
							<!-- this button resets all filters to the state they were before the user clicked on the "clear all filters button" -->
							<v-btn color="primary" @click="reApplyFilters()">
								<span>{{ $t('easyregTable.reApplyFiltersButton') }}</span>
							</v-btn>
						</v-list-item-title>
					</v-list-item>
					<v-divider class="columns-divider"></v-divider>
					<div class="columns-title">
						{{ $t('easyregTable.columnsTitleLabel') }}
					</div>
					<v-divider class="columns-title-divider"></v-divider>
					<draggable
						v-model="enrichedHeaders"
						draggable=".draggable-list-item"
						class="columns-list"
						@change="saveColumnSettings"
					>
						<v-list-item
							v-for="header in enrichedHeaders"
							:key="header.value"
							class="draggable-list-item"
						>
							<v-list-item-title class="columns-list-title-item">
								<!-- header visibility toggling is automatic. so we don't even have to do anything else special here. it will be reflected automatically everywhere it's needed -->
								<!-- but we need to save in SessionStorage/soon to be BE database to be used next time user visits this page -->
								<v-checkbox
									v-model="header.visible"
									class="header-checkbox-option"
									:label="header.text"
									hide-details
									@change="saveColumnSettings"
								></v-checkbox>
							</v-list-item-title>
						</v-list-item>
					</draggable>
				</v-list>
			</v-menu>
			<v-expand-x-transition>
				<!-- Vuetify mx-auto class makes it transition nicely from the center -->
				<hr v-show="globalFunctionsFocused" class="mx-auto global-functions-animation" />
			</v-expand-x-transition>
		</div>
		<!-- sortable by dragging the header could be provided by: https://stackoverflow.com/questions/58238835/dragging-columns-in-a-vuetify-v-data-table -->

		<v-data-table
			ref="vDataTable"
			v-bind="$attrs"
			:items="userFilteredItems || []"
			:headers="userSelectedHeaders || []"
			:search="searchValueDebounced"
			:hide-default-header="!isMobile"
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
			:class="[isMobile ? 'mobile-display' : null]"
			:custom-sort="$attrs['custom-sort'] ? $attrs['custom-sort'] : easyregCustomSort"
			:custom-filter="easyregCustomFilter"
			:no-data-text="
				$attrs['no-data-text'] ? $attrs['no-data-text'] : $t('easyregTable.noTableDataText')
			"
			:no-results-text="
				$attrs['no-results-text']
					? $attrs['no-results-text']
					: $t('easyregTable.noTableResultsText')
			"
			:loading-text="
				$attrs['loading-text'] ? $attrs['loading-text'] : $t('easyregTable.tableDataLoadingText')
			"
			v-on="$listeners"
		>
			<!-- Pass on all named slots -->
			<slot v-for="slot in Object.keys($slots)" :slot="slot" :name="slot" />
			<!-- Pass on all scoped slots -->
			<template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
				<slot :name="slot" v-bind="scope" />
			</template>

			<!-- build a custom header with a filter button for the easyreg table -->
			<!-- in mobile display -->
			<template v-if="isMobile" v-slot:item="props">
				<tr class="v-data-table__mobile-table-row">
					<td v-for="header in props.headers" :key="header.value" class="v-data-table__mobile-row">
						<div class="v-data-table__mobile-row__header">
							<!-- this is actually a header inside a table -->
							<!-- so do the necessary modifications to the header -->
							<v-icon>filter_list_alt</v-icon>
							{{ header.text }}
						</div>
						<!-- <div class="v-data-table__mobile-row__cell">{{ props.item[header.value] }}</div> -->
						<div class="v-data-table__mobile-row__cell">
							<!-- offer the individual item slot to edit to the outside here -->
							<slot :name="'item.' + header.value" :item="props.item">
								<!-- fallback to the default value if it's not modified from the outside -->
								{{ props.item[header.value] }}
							</slot>
						</div>
					</td>
				</tr>
			</template>
			<!-- in desktop display -->
			<template v-if="!isMobile" v-slot:header="{ props }">
				<thead>
					<tr>
						<th
							v-for="header in props.headers"
							:key="header.value"
							class="easyreg-table-header"
							:class="[{ 'column-sorted': sortBy === header.value }]"
							:style="
								header.width ? 'min-width:' + header.width + ';width:' + header.width + ';' : null
							"
						>
							<v-tooltip top :disabled="!$slots[`header.${header.value}.tooltipText`]">
								<!-- tooltip is disabled if it's not used from the parent -->
								<template v-slot:activator="{ on }">
									<span v-on="on">
										<div
											class="easyreg-header-text"
											v-on="on"
											@click="sortColumn(header)"
											@mouseover="header.isHovering = true"
											@mouseleave="header.isHovering = false"
										>
											{{ header.text }}
										</div>
									</span>
								</template>
								<slot :name="`header.${header.value}.tooltipText`">
									<!-- when hovering over the header text here is where the tooltip text will be inserted -->
								</slot>
							</v-tooltip>
							<v-menu
								:ref="header.value + '-filter-menu'"
								content-class="easyreg-table-filter-content"
								:close-on-content-click="false"
								bottom
								nudge-top="8"
								left
								nudge-right="8"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										:class="{
											'filter-active':
												uniqueColumnValues &&
												getObjectValueByPath(uniqueColumnValues, header.value).some(
													(uniqueDataObject) => !uniqueDataObject.visible
												),
										}"
										:data-cy="header.value.toLowerCase() + '-filter-menu'"
										v-on="on"
									>
										filter_list_alt
									</v-icon>
								</template>

								<v-list class="filter-list">
									<v-list-item class="filters-dialog-header">
										<v-list-item-title>
											<div class="title-text">{{ header.text }}</div>
											<!-- this close icon additionally closes the menu -->
											<v-icon @click="$refs[header.value + '-filter-menu'][0].isActive = false">
												clear
											</v-icon>
										</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title>
											<!-- this button removes the columns from the UI -->
											<v-btn
												color="primary"
												@click=";(header.visible = !header.visible), saveColumnSettings()"
											>
												<span>{{ $t('easyregTable.hideColumnButton') }}</span>
											</v-btn>
										</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title>
											<!-- this button sets the current column to be sorted by; and reverses the sort order (ascending - descending) -->
											<v-hover v-slot="{ hover }">
												<v-btn
													color="primary"
													class="sort-column-button"
													:class="[{ 'column-sorted': sortBy === header.value }]"
													@click="sortColumn(header)"
												>
													<span>{{ $t('easyregTable.sortColumnButton') }}</span>
													<!-- display this "remove" - "minus" icon as a placeholder to indicate that we have not sorted by this column currently -->
													<!-- it just makes for a better UX to have it here -->
													<v-icon
														v-if="!hover && sortBy !== header.value"
														class="sorting-indicator-icon not-sorted-icon"
													>
														remove
													</v-icon>
													<!-- render this sorted-ascending icon if we are hovering over it and the column is not already active. or if it is active -->
													<v-icon
														v-if="
															(hover && sortBy !== header.value) ||
															(!sortDesc && sortBy === header.value)
														"
														class="sorting-indicator-icon sorted-ascending-icon"
													>
														arrow_upward
													</v-icon>
													<!-- render this sorted-descending icon only if this sort order is active on this column -->
													<v-icon
														v-if="sortDesc && sortBy === header.value"
														class="sorting-indicator-icon sorted-descending-icon"
													>
														arrow_downward
													</v-icon>
												</v-btn>
											</v-hover>
										</v-list-item-title>
									</v-list-item>
									<v-divider class="auto-filter-divider"></v-divider>
									<div class="auto-filter-title">
										{{ $t('easyregTable.autoFilterTitleLabel') }}
									</div>
									<v-divider class="auto-filter-title-divider"></v-divider>
									<v-list-item class="auto-filter-list">
										<v-list-item-title class="auto-filter-title-item">
											<!-- this holds the auto-filter component - so another list within a list -->
											<v-list v-if="uniqueColumnValues">
												<v-list-item class="auto-filter-list-item">
													<v-list-item-title class="auto-filter-item-title">
														<v-checkbox
															:input-value="
																uniqueColumnValues[header.value].every(
																	(uniqueDataObject) => uniqueDataObject.visible
																)
															"
															:indeterminate="
																uniqueColumnValues[header.value].some(
																	(uniqueDataObject) => uniqueDataObject.visible === true
																) &&
																uniqueColumnValues[header.value].some(
																	(uniqueDataObject) => uniqueDataObject.visible === false
																)
															"
															:label="$t('easyregTable.selectAllLabel')"
															hide-details
															class="auto-filter-checkbox"
															:data-cy="header.value.toLowerCase() + '-selectall-checkbox'"
															@change="
																updateAllValuesVisibility(uniqueColumnValues[header.value]),
																	saveAutoFilterSettings(header.value)
															"
														></v-checkbox>
													</v-list-item-title>
												</v-list-item>

												<!-- the :key here is built like so: -->
												<!-- if the value is an object that has an id: use the id as the key -->
												<!-- if it's an object that doesn't have an id but has a filterValue: use the filterValue -->
												<!-- else use the value it self because the value should be a primitive here -->
												<v-list-item
													v-for="uniqueDataObject in uniqueColumnValues[header.value]"
													:key="
														uniqueDataObject.value && uniqueDataObject.value.id
															? uniqueDataObject.value.id
															: uniqueDataObject.value && uniqueDataObject.value.filterValue
															? uniqueDataObject.value.filterValue
															: uniqueDataObject.value
													"
													class="auto-filter-list-item"
												>
													<v-list-item-title class="auto-filter-item-title">
														<v-checkbox
															v-model="uniqueDataObject.visible"
															hide-details
															class="auto-filter-checkbox"
															:data-cy="
																header.value.toLowerCase() +
																'-' +
																getAutoFilterLabel(uniqueDataObject, header.value) +
																'-checkbox'
															"
															@change="saveAutoFilterSettings(header.value)"
														>
															<template v-slot:label>
																<!-- offer the checkbox's label slot to edit to the outside -->
																<!-- Fallback to the default label display if not specifically hooked into by another user -->
																<slot
																	:name="`header.${header.value}.uniqueAutoFilterObject`"
																	:uniqueAutoFilterObject="uniqueDataObject"
																>
																	<span
																		v-if="header.value === 'comment'"
																		v-html="getAutoFilterLabel(uniqueDataObject, header.value)"
																	/>

																	<span v-else style="text-transform: capitalize">
																		{{ getAutoFilterLabel(uniqueDataObject, header.value) }}
																	</span>
																</slot>
															</template>
														</v-checkbox>
													</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
							<!-- render this sorted-ascending icon if we are hovering over it and the column is not already active. or if it is active -->
							<v-icon
								v-if="
									(header.isHovering && sortBy !== header.value) ||
									(!sortDesc && sortBy === header.value)
								"
								class="sorting-indicator-icon sorted-ascending-icon"
								@click="sortColumn(header)"
							>
								arrow_upward
							</v-icon>
							<!-- render this sorted-descending icon only if this sort order is active on this column -->
							<v-icon
								v-if="sortDesc && sortBy === header.value"
								class="sorting-indicator-icon sorted-descending-icon"
								@click="sortColumn(header)"
							>
								arrow_downward
							</v-icon>
						</th>
					</tr>
				</thead>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import draggable from 'vuedraggable'
import { defaultFilter } from 'vuetify/lib/util/helpers.js'
// column passed in the header can be a path, not just simple first level property of an object that's why the need for the getObjectValueByPath() function
import { sortItems, getObjectValueByPath } from 'vuetify/lib/util/helpers.js'
import Tracker from '../../mixins/tracker'

export default {
	name: 'EasyregTable',
	components: {
		draggable,
	},
	mixins: [Tracker],
	props: {
		items: {
			// documentation: https://easyfinreg.atlassian.net/wiki/spaces/FRON/pages/1306263568/Easyreg+table+component#Items-data-structure
			type: Array,
			default() {
				return []
			},
		},
		headers: {
			type: Array,
			default() {
				return []
			},
		},
		removeFilters: {
			type: Boolean,
			default: false,
		},
		searchPlaceholder: {
			type: String,
			default: () => 'Search',
		},
		commentTypeDictionary: {
			type: Object,
			default: () => {},
		},
	},
	data: function () {
		return {
			debounce: null, // setTimeout will be assigned to this variable for debouncing
			debounceDelayTime: 500, // Time in milliseconds

			// the original searched value
			searchValue: '',
			// the search value after a delay (so the table filtering doesn't trigger as user types fast and downgrades performance)
			searchValueDebounced: '',
			// holds the passed in headers enriched with additional values like visibility. and this is the object array worked on
			enrichedHeaders: [],
			// tableSettings holds all the information about this table: column settings and auto-filter settings
			// this is an active worked on tableSetting object
			// there will always be only one backend user settings id for 1 easyReg table and all the user settings for this table will be saved under that ID
			// and it's label will be the value from this.$attrs['easyreg-table-name'] and it will be saved as a property "userSettingsId" of a tableSettings object
			tableSettings: null,
			// another copy of these table settings to be used when the user clicks on the "clear all filters button"
			// to revert the filters state the way they were when he got to the page
			staticTableSettings: null,
			// sort the table by some property. Sort by nothing by default
			sortBy: null,
			// sort the table descending?
			sortDesc: false,
			uniqueColumnValues: null,
			// is the "global functions/functionalities" bar -> search, options, etc. focused?
			globalFunctionsFocused: false,
			// is the Vuetify table in it's mobile display mode?
			isMobile: this.$vuetify.breakpoint.width < this.$attrs['mobile-breakpoint'],
		}
	},
	computed: {
		userSelectedHeaders() {
			return this.enrichedHeaders.filter((header) => header.visible === true)
		},
		userFilteredItems() {
			// if there is no user set filters yet. just return all the items passed in
			if (!this.uniqueColumnValues) return this.items

			// else go through all the user set filters and filter out original data and put that in the table
			let userFilteredItems = []
			for (let i = 0; i < this.items.length; i++) {
				let currentItem = this.items[i]

				let anyHeaderFilteredOut = false
				for (const column in this.uniqueColumnValues) {
					// check every column
					// find the corresponding "filter" data item to the current "raw" data item we're looking at
					let filterDataItem = getObjectValueByPath(this.uniqueColumnValues, column).find(
						// must use stringify here as well as values can be simple objects
						(uniqueValue) =>
							JSON.stringify(uniqueValue.value) ===
							JSON.stringify(getObjectValueByPath(currentItem, column))
					)
					if (filterDataItem.visible === false) {
						// so if the current filter data item is filtered out - user set it to be invisible
						// do not put this raw data item into the final table to be displayed
						anyHeaderFilteredOut = true
						// exit the for loop because there is no more point in continuing
						break
					}
				}

				// if no header/property from the current data item has been filtered out by any user filter
				// then push this data item into the final array of items that will be displayed in the table
				if (!anyHeaderFilteredOut) userFilteredItems.push(currentItem)
			}

			return userFilteredItems
		},
	},
	watch: {
		'$vuetify.breakpoint.width'(newWidth) {
			this.isMobile = newWidth < this.$attrs['mobile-breakpoint']
		},
		removeFilters(newVal) {
			if (newVal) {
				this.removeAllFilters()
			}
		},
		searchValue(newVal) {
			clearTimeout(this.debounce)

			this.debounce = setTimeout(() => {
				this.searchValueDebounced = newVal

				this.decideAction('search_on_table', {
					searched: newVal,
				})
			}, this.debounceDelayTime)
		},
		items: {
			// when we get the items or items update: trigger processing
			deep: true, // even if nested properties change
			handler() {
				this.setUniqueColumnValues()
			},
		},
		headers() {
			// watch on the headers prop and trigger the enrichment in case it's updated later then this component initialization
			this.enrichHeaders()
		},
	},
	created: function () {
		let self = this

		// coment page sorting
		self.sessionDataforComments()

		// changes page sorting
		self.sessionDataforChange()

		let cachedUserSettings = self.$store.getters.cachedUserSettings
		if (cachedUserSettings) {
			self.tableSettings = cachedUserSettings
			if (self.headers.length) self.enrichHeaders()
			if (self.items.length) self.setUniqueColumnValues()
		} else {
			self.getUserSettings().then((finalTableSettingsObject) => {
				self.tableSettings = finalTableSettingsObject
				this.$store.commit('SET_CHACHED_USER_SETTINGS', self.tableSettings)
				self.staticTableSettings = Object.assign({}, finalTableSettingsObject)
				if (self.headers.length) self.enrichHeaders()
				// if some items were already passed in on init trigger unique column values setting
				if (self.items.length) self.setUniqueColumnValues()
			})
		}
	},
	beforeCreate: function () {},
	beforeMount: function () {},
	mounted: function () {
		// On mounted: The DOM is now rendered and ready
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	methods: {
		sessionDataforComments() {
			if (
				(this.$route.query['taggedUserIds'] || this.$route.query.status) &&
				this.$route.name === 'comments'
			) {
				// if there is no user set filters yet. just );
				const userValues = []

				this.items.forEach((el) => {
					if (!el.taggedUserNames.includes(this.$store.state.currentUser.fullName)) {
						userValues.push({
							visible: false,
							value: el.taggedUserNames,
						})
					} else {
						userValues.push({
							visible: true,
							value: el.taggedUserNames,
						})
					}
				})

				// remove dublicates
				const userUniqueValues = []
				userValues.map((x) =>
					userUniqueValues.filter((a) => a.value == x.value).length
						? null
						: userUniqueValues.push(x)
				)

				// by status id
				const statusValues = []
				let newSessionData = []
				const queryStatus = this.$route.query.status
				if (queryStatus) {
					let queryedStatusId = null

					if (Array.isArray(queryStatus)) {
						queryedStatusId = queryStatus.map((x) => (x = parseInt(x)))
					} else {
						queryedStatusId = parseInt(queryStatus)
					}

					this.items.forEach((el) => {
						if (el.status.id === queryedStatusId) {
							statusValues.push({
								visible: true,
								value: { id: el.status.id },
							})
						} else {
							statusValues.push({
								visible: false,
								value: { id: el.status.id },
							})
						}
					})

					// remove dublicates
					const statusUniqueValues = []

					statusValues.map((x) =>
						statusUniqueValues.filter((a) => a.value == x.value).length
							? null
							: statusUniqueValues.push(x)
					)

					newSessionData = [
						{ value: 'taggedUserNames', uniqueValues: userUniqueValues },
						{ value: 'status', uniqueValues: statusUniqueValues },
					]
				} else {
					newSessionData = [{ value: 'taggedUserNames', uniqueValues: userUniqueValues }]
				}

				let additionalUserData = {}
				// save only the auto-filter data
				additionalUserData[this.$attrs['easyreg-table-name']] = {
					autoFilterSettings: newSessionData,
				}

				console.log(additionalUserData)

				this.$store.commit('setAdditionalUserDataToSessionStorage', additionalUserData)
			}
		},
		sessionDataforChange() {
			console.time()
			const isQueryedData =
					this.$route.query['domainData.owner'] || this.$route.query['domainData.status']
						? true
						: false,
				isActiveRoute =
					this.$route.name === 'changesWithSlug' || this.$route.name === 'changes' ? true : false

			if (isQueryedData && isActiveRoute) {
				const uniqueValues = []
				// filter all users and set non logined to false
				this.items.filter((x) => {
					if (parseInt(this.$route.query['domainData.owner']) !== x.domainData.owner.id) {
						uniqueValues.push({
							visible: false,
							value: { id: x.domainData.owner.id },
						})
					} else {
						uniqueValues.push({
							visible: true,
							value: { id: x.domainData.owner.id },
						})
					}
				})
				// remove dublicates
				const filteredOwners = []
				uniqueValues.map((x) =>
					filteredOwners.filter((a) => a.value.id == x.value.id).length
						? null
						: filteredOwners.push(x)
				)
				// data to set in session
				const newSessionData = [
					{
						uniqueValues: [
							{ visible: false, value: 'D' },
							{ visible: false, value: 'O' },
						],
						value: 'domainData.status',
					},
					{
						uniqueValues: filteredOwners,
						value: 'domainData.owner',
					},
				]
				let additionalUserData = {}
				// save only the auto-filter data
				additionalUserData[this.$attrs['easyreg-table-name']] = {
					autoFilterSettings: newSessionData,
				}
				this.$store.commit('setAdditionalUserDataToSessionStorage', additionalUserData)
			}
			console.timeEnd()
		},
		async getUserSettings() {
			let self = this

			// get the user settings from the backend for this table
			return await self.$http
				.get(
					self.$store.state.baseDomainURL +
						'/UserPreferences/label/' +
						self.$attrs['easyreg-table-name']
				)
				.then((result) => {
					let userSettingsId = { userSettingsId: result.data.id }
					let databaseSettings = JSON.parse(result.data.preferences)
					let sessionStorageSettings = self.$store.getters.getTableSettings(
						self.$attrs['easyreg-table-name']
					)

					// merge all the settings objects properties into the final table settings object
					return {
						...userSettingsId,
						...databaseSettings,
						...sessionStorageSettings,
					}
				})
				.catch(() => {
					// there are no saved user preferences for this table. Do nothing. Not even logging an error. Because this is not an error.
					// Just look for the SessionStorage setting if they exist
					return self.$store.getters.getTableSettings(self.$attrs['easyreg-table-name'])
				})
		},
		// set the imported method as a this components method so it can be used in template
		getObjectValueByPath,
		easyregCustomSort(items, sortBy, sortDesc, locale, customSorters) {
			let sortingHeaderName = sortBy[0]
			if (
				items &&
				items.length &&
				getObjectValueByPath(items[0], sortingHeaderName + '.filterValue') !== undefined
			) {
				// if there are items and the header we are trying to sort exists. but it's value is not a primitive as normal but an object
				// then this object must be the one we defined that it's possible in easyreg and it must have a parameter "filterValue"
				// if it does: use that value as a sorting value
				let srt = sortBy[0] + '.filterValue'
				sortBy[0] = srt
			}

			// sort with default Vuetify's sort function
			return sortItems(items, sortBy, sortDesc, locale, customSorters)
		},
		easyregCustomFilter(value, search, item) {
			let doesItPassTheFilters = true

			// if a custom filter has been passed in: filter by that one first
			if (this.$attrs['custom-filter']) {
				doesItPassTheFilters = this.$attrs['custom-filter'](value, search, item)
			}

			if (doesItPassTheFilters) {
				// if it passes the user set filter: additionally filter with our own easyreg filter
				// just look if the value we are looking at is an object and compare with the property inside we have defined that will be used for filtering
				if (value && value.filterValue !== undefined) {
					// filter by this our set filter value inside the object by the default Vuetify filtering mechanism
					return defaultFilter(value.filterValue, search, item)
				} else {
					// filter with the default Vuetify filter the default - non object value
					return defaultFilter(value, search, item)
				}
			}

			// some filter has not been passed: return false to filter out this item
			return false
		},
		getAutoFilterLabel(uniqueDataObject, headerValue) {
			if (Array.isArray(uniqueDataObject) && headerValue === 'commentType') {
				return uniqueDataObject.find()
			}

			if (uniqueDataObject.value) {
				// if value does exist; is not empty
				if (uniqueDataObject.value.filterValue) {
					// if the value is an object and a "filter value" is set: use that
					return uniqueDataObject.value.filterValue
				} else if (uniqueDataObject.value.filterValue !== undefined) {
					// filterValue is set but it has a "falsy" value: e.g. an empty string, null etc.
					// use the "empty label string" here
					return this.$t('easyregTable.emptyFieldLabel')
				} else {
					if (headerValue === 'commentType') {
						return this.commentTypeDictionary[uniqueDataObject.value].toLowerCase()
					}
					// the value is a primitive: integer, string etc.: so use that
					return uniqueDataObject.value
				}
			} else {
				// the value is empty. return an empty label string translation
				return this.$t('easyregTable.emptyFieldLabel')
			}
		},
		reApplyFilters() {
			let self = this

			// re-applyes the last user saved filters from the database/localStorage
			self.getUserSettings().then((finalTableSettingsObject) => {
				self.tableSettings = finalTableSettingsObject
				self.enrichHeaders()
				self.setUniqueColumnValues()
			})
		},
		removeAllFilters() {
			// removes all filters and sets the data and the settings at the raw state
			this.tableSettings = null
			this.searchValue = ''
			this.enrichHeaders()
			this.setUniqueColumnValues()

			// get set filters from local session
			const sessionData = JSON.parse(sessionStorage.getItem('easyRegAdditionalUserData'))
			// get active table name by attr and set all visabile to true
			sessionData[this.$attrs['easyreg-table-name']]?.autoFilterSettings.map((x) =>
				x.uniqueValues.map((x) => (x.visible = true))
			)
			// remove any query if there is one
			this.$router.replace({ query: null })
			// rewrite localsession with nes data
			sessionStorage.setItem('easyRegAdditionalUserData', JSON.stringify(sessionData))
		},
		resetSettingsToBaseState() {
			// set all the settings the way they were on page load
			// doesn't save to database/SessionStorage
			// it seems only the Object.assing() can set the tableSettings properly without making staticTableSettings reactive and thus overwritting the base settings state at page load
			this.tableSettings = Object.assign({}, this.staticTableSettings)
			// run the below 2 functions to recalculate local data based on the tableSettings param again
			this.enrichHeaders()
			this.setUniqueColumnValues()
		},
		updateAllValuesVisibility(column) {
			if (column.every((uniqueDataObject) => uniqueDataObject.visible)) {
				// if every unique object in this header/column is already visible: set them all to be invisible
				column.forEach((uniqueDataObject) => (uniqueDataObject.visible = false))
			} else {
				// otherwise, in case of intermediate status (some are visible some not) or everything is invisible already: set everything to be visible
				column.forEach((uniqueDataObject) => (uniqueDataObject.visible = true))
			}
		},
		sortColumn(header) {
			if (this.sortBy !== header.value) {
				// if we are sorting this header for the first time
				// set it as the active header to be sorted on
				// and sort ascending
				this.sortBy = header.value
				this.sortDesc = false
			} else if (this.sortDesc === false) {
				// if we have already this column sorted
				// but it's sorted for the first time - ascending
				// sort it now descending
				this.sortDesc = !this.sortDesc
			} else {
				// we already have this column sorted
				// and it was sorted twice already - first ascending and then descending
				// here now remove all the sorting that exists in the table; I.e. set everything to default values
				this.sortBy = null
				this.sortDesc = false
			}
		},
		saveColumnSettings() {
			let self = this
			// when the user toggled a header visibility/order : update JSON data about it to be used sometime later. In the BE database.

			// if no tables settings have been saved yet: create a new object
			if (!self.tableSettings) self.tableSettings = {}

			// if the columnSettings data has already been saved
			// update the entire JSON object. For one it's easyer.
			// but for the other, it's to be sure that no columns have been added deleted in the meantime from some unknown source
			// and if the data has not yet been saved. save it now
			self.tableSettings.columnSettings = []
			for (let i = 0; i < self.enrichedHeaders.length; i++) {
				let settingsObject = {
					value: self.enrichedHeaders[i].value,
					order: self.enrichedHeaders[i].order,
					visible: self.enrichedHeaders[i].visible,
				}
				self.tableSettings.columnSettings.push(settingsObject)
			}

			// save the columnSettings in the database
			if (self.tableSettings.userSettingsId) {
				// if we already have something saved in a database: update it
				self.$http
					.put(
						self.$store.state.baseDomainURL +
							'/UserPreferences/' +
							self.tableSettings.userSettingsId,
						{
							label: self.$attrs['easyreg-table-name'],
							preferences: JSON.stringify({
								columnSettings: self.tableSettings.columnSettings,
							}),
						}
					)
					.then(() => {
						// then nothing
					})
					.catch((error) => {
						// if there was an error just log it
						// todo: eventually maybe when the Vuetify alert component has been implemented send a notification to the user here
						console.log(error.response)
					})
			} else {
				// otherwise create a new user preference record
				// must be in this format which has the baseURL param so the axios interceptor in main.js triggers and GETs the preference again from the backend to get the ID
				self
					.$http({
						method: 'POST',
						baseURL: self.$store.state.baseDomainURL,
						url: '/UserPreferences',
						data: {
							label: self.$attrs['easyreg-table-name'],
							preferences: JSON.stringify({
								columnSettings: self.tableSettings.columnSettings,
							}),
						},
					})
					.then((result) => {
						// then save the newly created ID locally
						self.tableSettings.userSettingsId = result.data.id
					})
					.catch((error) => {
						// if there was an error just log it
						// todo: eventually maybe when the Vuetify alert component has been implemented send a notification to the user here
						console.log(error.response)
					})
			}
		},
		saveAutoFilterSettings(headerName) {
			// when the user changes filtering options: save that too as JSON in SessionStorage

			// record this action in OWA
			this.$store.dispatch('trackPageVisit', {
				route: this.$route,
				pageType: 'Easyreg_Autofilter',
				urlAdd: '/ColumnName/' + headerName,
			})

			// if no tables settings have been saved yet: create a new object
			if (!this.tableSettings) this.tableSettings = {}

			this.tableSettings.autoFilterSettings = []
			for (let i = 0; i < this.enrichedHeaders.length; i++) {
				let settingsObject = {
					value: this.enrichedHeaders[i].value,
				}

				// if some user filtering options exist already. check that. and save it.
				if (this.uniqueColumnValues && this.uniqueColumnValues[this.enrichedHeaders[i].value])
					Object.assign(settingsObject, {
						uniqueValues: this.uniqueColumnValues[this.enrichedHeaders[i].value].map(
							(uniqueValue) => {
								// check every column and see if their unique values have an "id" property set
								// if they do, save only that "id" instead of the whole object. Only this "id" will be used to recognize the unique value everywhere
								if (uniqueValue.value && uniqueValue.value.id) {
									// Object.assign() prevents reactivity
									let newUniqueValue = Object.assign({}, uniqueValue)
									newUniqueValue.value = {
										id: uniqueValue.value.id,
									}
									return newUniqueValue
								}
								return uniqueValue
							}
						),
					})
				this.tableSettings.autoFilterSettings.push(settingsObject)
			}

			let additionalUserData = {}
			// save only the auto-filter data
			additionalUserData[this.$attrs['easyreg-table-name']] = {
				autoFilterSettings: this.tableSettings.autoFilterSettings,
			}

			this.$store.commit('setAdditionalUserDataToSessionStorage', additionalUserData)
		},
		enrichHeaders() {
			let enrichedHeaders = []

			let headersToLoopOver = []

			if (this.tableSettings && this.tableSettings.columnSettings) {
				// if the headers/columns for this table have already been saved, loop through them because they are in the right order

				// but check if some of the saved headers no longer exist among the passed in raw headers and don't put them in the final list
				this.tableSettings.columnSettings.forEach((savedHeader) => {
					if (this.headers.some((rawHeader) => rawHeader.value === savedHeader.value)) {
						// so if any of the raw header matches the saved header: use it
						headersToLoopOver.push(savedHeader)
					}
				})

				// and finally check if some new headers have been added in the meantime that we don't have saved
				this.headers.forEach((rawHeader) => {
					if (headersToLoopOver.every((savedHeader) => savedHeader.value !== rawHeader.value)) {
						// if we don't already have this raw header among the saved headers: add the header at the end of the list of headers/columns
						headersToLoopOver.push(rawHeader)
					}
				})
			} else {
				// loop through the passed in raw headers
				headersToLoopOver = this.headers
			}

			for (let i = 0; i < headersToLoopOver.length; i++) {
				// copy over this header locally. JSON magic prevents Vue reactivity
				let header = JSON.parse(JSON.stringify(headersToLoopOver[i]))

				// if hovering indicator isn't set - set it to a default true
				if (header.isHovering === undefined) header.isHovering = false
				// if visibility isn't set - set it to a default true
				if (header.visible === undefined) header.visible = true
				// if order isn't set - just basically put a string message to the programmer that the order in the array is what we are using as default
				if (header.order === undefined) header.order = 'byIndexInThisArray'

				// now no mather in which set of headers we've looped over visible, order and value will be defined
				// if we've looped over the raw headers passed in by the user, then we already have all we need
				// but if we've looped over the saved headers, we need to add the other raw data that might have been passed in in this component
				// use the JSON parse-stringify magic so you don't overwrite the original raw headers but only return the merged header (might not be necessary but just in case)
				const mergedHeader = Object.assign(
					JSON.parse(JSON.stringify(this.headers)).find(
						(rawHeader) => rawHeader.value === header.value
					),
					header
				)

				// check if custom sorting and/or filtering function has been passed in and manually transfer that too to the enriched header
				if (this.headers.find((header) => header.value === headersToLoopOver[i].value).sort)
					mergedHeader.sort = this.headers.find(
						(header) => header.value === headersToLoopOver[i].value
					).sort
				if (this.headers.find((header) => header.value === headersToLoopOver[i].value).filter)
					mergedHeader.filter = this.headers.find(
						(header) => header.value === headersToLoopOver[i].value
					).filter

				enrichedHeaders.push(mergedHeader)
			}

			this.enrichedHeaders = enrichedHeaders
		},
		setUniqueColumnValues() {
			let uniqueColumnValues = {}

			// initialize columns based on headers
			for (let i = 0; i < this.headers.length; i++) {
				// if the current property/column doesn't exist in our new object we're constructing. Initialize it
				if (!uniqueColumnValues[this.headers[i].value])
					uniqueColumnValues[this.headers[i].value] = []
			}

			for (let i = 0; i < this.items.length; i++) {
				// loop through all data items
				for (const column in this.items[i]) {
					// loop through all object properties in this current data item

					// if this item's property is a header/column in the table. i.e. displayed in the table, i.e. not just some other data passed in for some other reason
					// and if our constructed unique object doesn't already include the data item's value we're currently looking at: add it to our unique data object
					if (
						uniqueColumnValues[column] &&
						!uniqueColumnValues[column].find(
							// we must stringify values because they can be objects
							// they shouldn't be deep objects or with functions, just simple objects with few possible propertyes id, filterValue and displayValue, all of which should only hold primitive values
							// so we can compare the equality of the JSON string
							(dataItem) =>
								JSON.stringify(dataItem.value) ===
								JSON.stringify(getObjectValueByPath(this.items[i], column))
						)
					)
						getObjectValueByPath(uniqueColumnValues, column).push({
							visible: true,
							value: getObjectValueByPath(this.items[i], column),
						})

					// if the "column" property of this item is an object
					if (typeof this.items[i][column] === 'object' && this.items[i][column] !== null) {
						// check if any of it's sub-property paths match any of our set headers (columnKey is our set header)
						for (const columnKey in uniqueColumnValues) {
							// there exists a sub-property in this items object that matches one of the headers (the one were currently looking at: columnKey)
							// and it's value is not among our already saved unique values for the header
							if (
								columnKey.split('.').length > 1 &&
								getObjectValueByPath(
									this.items[i][column],
									columnKey.split('.').slice(1).join('.')
								) !== undefined &&
								uniqueColumnValues[columnKey].every(
									(dataItem) =>
										JSON.stringify(dataItem.value) !==
										JSON.stringify(getObjectValueByPath(this.items[i], columnKey))
								)
							) {
								// add this value among our unique values as well
								getObjectValueByPath(uniqueColumnValues, columnKey).push({
									visible: true,
									value: getObjectValueByPath(this.items[i], columnKey),
								})
							}
						}
					}
				}
			}

			// now that all the default values have been set: look if we have already saved some data about filtering and overwrite default values with the saved data
			if (this.tableSettings && this.tableSettings.autoFilterSettings) {
				for (let i = 0; i < this.tableSettings.autoFilterSettings.length; i++) {
					// copy over this header/column locally. JSON magic prevents Vue reactivity
					let savedHeader = JSON.parse(JSON.stringify(this.tableSettings.autoFilterSettings[i]))

					if (uniqueColumnValues[savedHeader.value]) {
						// if the header does indeed exist, and some filtering data about it has indeed already been saved. Overwrite some of that data
						// loop through all the previously saved unique values for this header/column
						for (let j = 0; j < savedHeader.uniqueValues.length; j++) {
							// if we found a match: a unique value that we both are looking at now from the new raw data and that was already saved by the user previously
							// overwrite the currently set default value by the previously saved one
							// we are doing this just for the "visible" property for now
							uniqueColumnValues[savedHeader.value] = uniqueColumnValues[savedHeader.value].map(
								(uniqueValue) => {
									if (
										JSON.stringify(uniqueValue.value) ===
										JSON.stringify(savedHeader.uniqueValues[j].value)
									) {
										// we must stringify values to be able to properly compare because they can be "simple" objects
										return {
											...uniqueValue,
											visible: savedHeader.uniqueValues[j].visible,
										}
									} else if (
										uniqueValue.value &&
										uniqueValue.value.id &&
										savedHeader.uniqueValues[j].value &&
										savedHeader.uniqueValues[j].value.id &&
										uniqueValue.value.id === savedHeader.uniqueValues[j].value.id
									) {
										// if we are only saving the ID for this unique value: compare only the id's to see if you found a match
										return {
											...uniqueValue,
											visible: savedHeader.uniqueValues[j].visible,
										}
									} else {
										return uniqueValue
									}
								}
							)
						}
					}
				}
			}

			this.uniqueColumnValues = uniqueColumnValues
		},
	},
}
</script>

<style scoped lang="scss" src="./easyreg-table.scss"></style>
