<!-- Communication between this component and the parent component goes only through few ways: -->
<!-- Parent can call the onMouseover() method -->
<template>
	<v-tooltip
		ref="tooltip"
		v-model="displayRegArtTooltip"
		:content-class="
			hideTheTooltipInitially
				? 'regart-tooltip-content hide-tooltip'
				: returnedMessages.length > 0
				? articleLoading || !displayRegArtTooltip
					? 'regart-tooltip-content with-messages'
					: 'regart-tooltip-content with-messages show-tooltip'
				: articleLoading || !displayRegArtTooltip
				? 'regart-tooltip-content'
				: 'regart-tooltip-content show-tooltip'
		"
	>
		<div
			ref="tooltip-viewer"
			v-click-outside="onClickOutside"
			class="tooltip-viewer"
			:class="[
				{ loading: articleLoading },
				{ 'tablet-screen': tabletScreenWidth },
				{ 'phone-screen': phoneScreenWidth },
			]"
			@click="handleClick"
		>
			<v-progress-linear v-if="articleLoading" indeterminate height="4"></v-progress-linear>
			<div v-if="currentErrorCode" ref="error-div" class="notification-text error--text">
				{{ $t('regArtTooltip.' + currentErrorCode) }}
			</div>
			<MessagesComponent
				v-if="returnedMessages.length > 0"
				:messages="returnedMessages"
				class="messages"
			/>
			<div v-for="pageNumber in pageNumbersToRender" :key="pageNumber" class="canvas-container">
				<canvas :ref="'tooltip-canvas-' + pageNumber" tabindex="1"></canvas>
			</div>
		</div>
	</v-tooltip>
</template>

<script>
import MessagesComponent from '@/components/common/MessagesComponent'

export default {
	name: 'RegArtTooltip',
	components: {
		MessagesComponent,
	},
	data: function () {
		return {
			displayRegArtTooltip: true, // display RegArt tooltip for the RegArt we are currently hovering
			articleLoading: false, // are the articles loading in the tooltip
			pageMetadata: null, // pageMetadata object of the current article to be displayed
			pageNumbersToRender: [], // this holds the page numbers for the tooltip display; it is used to generate pages/canvas elements
			hideTheTooltipInitially: true, // the tooltip must be initially rendered (displayRegArtTooltip) but hidden (hideTheTooltipInitially) so we are able to position it at the first time display
			regTextId: null, // current regTextId
			versionDate: null,
			paragraphId: null, // current paragraphId = articleNumber (paragraphId is a more proper name)
			tabletScreenWidth: window.innerWidth <= this.$store.state.tabletBreakpoint,
			phoneScreenWidth: window.innerWidth <= this.$store.state.phoneBreakpoint,
			clickCount: 0, // for handling click and doubleclick on elements
			clickTimer: null, // for handling click and doubleclick on elements
			returnedMessages: [], // potentially returned tips and/or errors by the /RegArtCoordinates endpoint
			currentErrorCode: '',
			errorMessagesTexts: {
				genericError: 'notFoundError',
				regTextIdNotFoundError: 'regTextIdNotFoundError',
				relatedParagraphIdFoundError: 'relatedParagraphIdFoundError',
			},
		}
	},
	computed: {
		updateLayout() {
			return this.$store.state.updateLayoutProps.updateLayout
		},
	},
	watch: {
		$route(to, from) {
			// on route change remove the tooltip
			if (from !== to) this.displayRegArtTooltip = false
		},
		updateLayout() {
			let self = this

			// update tabletScreenWidth flag
			if (window.innerWidth <= self.$store.state.tabletBreakpoint) self.tabletScreenWidth = true
			else self.tabletScreenWidth = false

			// update phoneScreenWidth flag
			if (window.innerWidth <= this.$store.state.phoneBreakpoint) this.phoneScreenWidth = true
			else this.phoneScreenWidth = false
		},
	},
	beforeCreate: function () {},
	created: function () {
		if (!this.$store.state.pdfjsLib)
			this.$store.commit('setPdfjsLib', require('pdfjs-dist/webpack'))
	},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	methods: {
		handleClick: function () {
			// this function delegates to "single click" or "double click" functions by seting a timeout
			let self = this

			self.clickCount++

			if (self.clickCount === 1) {
				self.clickTimer = setTimeout(() => {
					self.clickCount = 0
					// on a single click or tap close the tooltip
					this.displayRegArtTooltip = false
				}, self.$store.state.doubleClickDelay)
			} else if (self.clickCount === 2) {
				clearTimeout(self.clickTimer)
				self.clickCount = 0
				self.tooltipDblclick()
			}
		},
		onClickOutside() {
			this.displayRegArtTooltip = false
		},
		onMouseover($event) {
			let self = this
			// if there are normal HTML links here
			// and they are pointing inside the application
			// and they are links to RegArts (/read/regTextId/paragraphId)
			// display a tooltip with the article
			if ($event.target.nodeName === 'A') {
				let url = new URL($event.target.href)
				const params = new Proxy(new URLSearchParams(url.search), {
					get: (searchParams, prop) => searchParams.get(prop),
				})

				let resolvedRoute = this.$router.resolve(url.pathname)
				if (resolvedRoute.route.name === 'readWithArticleNumber') {
					console.log(resolvedRoute)

					self.regTextId = resolvedRoute.route.params.regTextId
					self.paragraphId = resolvedRoute.route.params.articleNumber
					self.versionDate = params.versionDate
					self.hideTheTooltipInitially = false
					self.pageNumbersToRender = []
					self.articleLoading = true
					self.displayRegArtTooltip = true
					self.currentErrorCode = ''
					self.returnedMessages = []

					self.renderTooltip($event)
				}
			} else {
				if (!self.$helpers.hasSomeParentTheClass($event.target, 'regart-tooltip-content'))
					self.displayRegArtTooltip = false
			}
		},
		renderTooltip($event) {
			let self = this

			const endpoint = `${self.$store.state.baseMainURL}/RegArtCoordinates/RegTextID/${self.regTextId}/${self.paragraphId}`
			const language = self.$store.state.currentUser.documentDisplayLanguage
			const versionDate = self.versionDate

			self.$http
				.get(endpoint, { params: { language, versionDate } })
				.then((response) => {
					self.returnedMessages = self.$helpers.unionOfArrays(
						response.data.messages,
						response.data.tips
					)

					if (!response.data.fileName || !response.data.location || !response.data.pageMetadata) {
						// if some of the data was not returned don't do anything else here
						self.positionTooltip($event)
						self.articleLoading = false
						return
					}

					self.pageMetadata = response.data.pageMetadata

					let pdfBaseUrl = process.env.VUE_APP_PDFS_FOLDER?.trim()
						? process.env.VUE_APP_PDFS_FOLDER
						: ''
					let pdfUrl = pdfBaseUrl + '/pdfs/'

					let loadingTask = self.$store.state.pdfjsLib.getDocument(pdfUrl + response.data.fileName)
					loadingTask.promise
						.then(function (pdf) {
							let pageNumbersToRenderLocal = []
							for (
								let page = response.data.location.pageNumberStart;
								page <= response.data.location.pageNumberEnd;
								page++
							) {
								pageNumbersToRenderLocal.push(page)
							}
							self.pageNumbersToRender = pageNumbersToRenderLocal

							self.$nextTick(function () {
								let viewer = self.$refs['tooltip-viewer']
								let numberOfRenderedPages = 0

								for (
									let page = response.data.location.pageNumberStart;
									page <= response.data.location.pageNumberEnd;
									page++
								) {
									let upperLeftY = null
									let lowerRightY = null

									if (page === response.data.location.pageNumberStart) {
										upperLeftY = response.data.location.precedingTopicsUpperLeftY
									}
									if (page === response.data.location.pageNumberEnd) {
										lowerRightY = response.data.location.lowerRightY
									}

									let canvas = self.$refs['tooltip-canvas-' + page][0]
									self.$helpers
										.renderPage(
											page,
											canvas,
											pdf,
											viewer,
											upperLeftY,
											lowerRightY,
											self.pageMetadata.find((o) => o.pageNumber === parseInt(page)).minTextX,
											self.pageMetadata.find((o) => o.pageNumber === parseInt(page)).maxTextX,
											null,
											true,
											self.phoneScreenWidth
												? self.$store.state.pdfPadding / 2
												: self.$store.state.pdfPadding,
											self.phoneScreenWidth
												? self.$store.state.pdfPadding / 2
												: self.$store.state.pdfPadding
										)
										.then(function () {
											// we are counting how many pages have been rendered to trigger actions
											// we need to do this and not just look at the last page because pages load asyncronously
											numberOfRenderedPages++
											if (
												numberOfRenderedPages ===
												response.data.location.pageNumberEnd -
													response.data.location.pageNumberStart +
													1
											) {
												// if we rendered all the pages do some aditional actions

												self.positionTooltip($event)

												// record in OWA
												self.$store.dispatch('trackPageVisit', {
													route: self.$route,
													pageType: 'RegArt_Tooltip',
													urlAdd: '/RegArtBeingWatched/' + self.regTextId + '/' + self.paragraphId,
												})

												self.articleLoading = false
											}
										})
										.catch(function (error) {
											console.log(error)
											self.displayRegArtTooltip = false
										})
								}
							})
						})
						.catch(function (err) {
							console.log(err)
							self.displayRegArtTooltip = false
						})
				})
				.catch((error) => {
					console.log(error)

					if (
						error.response.data.status === 400 &&
						error.response.data.error === 'REG_TEXT_ID_NOT_FOUND'
					) {
						self.currentErrorCode = self.errorMessagesTexts.regTextIdNotFoundError
					} else if (
						error.response.data.status === 400 &&
						error.response.data.error === 'RELATED_PARAGRAPH_ID_FOUND'
					) {
						self.currentErrorCode = self.errorMessagesTexts.relatedParagraphIdFoundError
					} else if (
						error.response.data.status === 400 &&
						error.response.data.error === 'NOT_FOUND'
					) {
						self.currentErrorCode = self.errorMessagesTexts.genericError
					} else {
						self.currentErrorCode = self.errorMessagesTexts.genericError
					}

					self.positionTooltip($event)
					self.articleLoading = false
				})
		},
		positionTooltip($event) {
			// position the tooltip so it doesn't go outside of the right or bottom boundary if neccessary
			// only check on next tick - when the tooltip has been rendered to get the real dimensions
			let self = this

			self.$nextTick(function () {
				// needs to happen after nextTick() so it has a chance to render (with opacity: 0) so we can get the right dimensions
				// initial positioning under the link aligned to the left edge
				let rect = $event.target.getBoundingClientRect()
				self.$refs['tooltip'].$refs.content.style.right = 'auto' // reset the right position
				self.$refs['tooltip'].$refs.content.style.left = rect.left + 'px'
				self.$refs['tooltip'].$refs.content.style.bottom = 'auto' // reset the bottom position
				self.$refs['tooltip'].$refs.content.style.top = rect.top + $event.target.offsetHeight + 'px'

				// now check if it would still be rendered off the screen somewhere and reposition
				let tooltipRec = self.$refs['tooltip'].$refs.content.getBoundingClientRect()
				if (tooltipRec.right >= window.innerWidth) {
					// if tooltip would go off the screen to the right - attach it to the right edge of the screen
					self.$refs['tooltip'].$refs.content.style.left = 'auto'
					self.$refs['tooltip'].$refs.content.style.right = 0
				}
				if (tooltipRec.bottom >= window.innerHeight) {
					// if the tooltip would go off the screen to the bottom - render it above the RegArt link
					let rect = $event.target.getBoundingClientRect()
					self.$refs['tooltip'].$refs.content.style.top = 'auto'
					self.$refs['tooltip'].$refs.content.style.bottom = window.innerHeight - rect.top + 'px'

					// now check if the height is still too small for the tooltip and the tooltip would be rendered off the top off the screen
					let tooltipRec2 = self.$refs['tooltip'].$refs.content.getBoundingClientRect()
					if (tooltipRec2.top < 0) {
						// if it is, render the tooltip right at the top of the screen, it won't matter now that it will cover the link itself
						self.$refs['tooltip'].$refs.content.style.top = 0
						self.$refs['tooltip'].$refs.content.style.bottom = 'auto'
					}
				}
			})
		},
		tooltipDblclick() {
			let self = this

			if (self.regTextId && self.paragraphId) {
				self.$router
					.push({
						name: 'readWithArticleNumber',
						params: {
							regTextId: self.regTextId,
							articleNumber: self.$helpers.processParagraphIdRange(self.paragraphId),
						},
					})
					.catch((error) => {
						console.log(error)
					})
				self.displayRegArtTooltip = false
			}
		},
	},
}
</script>

<style scoped lang="scss" src="./regart-tooltip.scss"></style>
