<template>
	<v-container>
		<v-row class="full-height d-flex justify-center align-center">
			<v-col cols="6">
				<v-card v-if="step === 1" elevation="10">
					<v-card-title class="font-weight-bold">
						<h2>{{ $t('loginByEmail.cardTitle') }}</h2>
					</v-card-title>

					<v-card-subtitle class="my-2">
						{{ $t('loginByEmail.cardSubtitle') }}
					</v-card-subtitle>

					<v-card-text>
						<v-form ref="form" v-model="valid">
							<v-text-field
								v-model="email"
								type="email"
								prepend-inner-icon="mail"
								outlined
								required
								:placeholder="$t('loginByEmail.emailAddress')"
								:rules="[(v) => !!v || 'Required']"
							/>

							<div
								:class="[
									showMessage ? 'justify-space-between' : 'justify-end',
									'd-flex align-center',
								]"
							>
								<div v-if="showMessage" class="primary--text">
									{{ message }}
								</div>

								<v-btn
									color="primary"
									depressed
									data-cy="login-btn"
									class="px-6"
									:loading="isLoading"
									:disabled="!valid"
									@click="sendLink"
								>
									{{ $t('loginByEmail.btnLabel') }}
								</v-btn>
							</div>
						</v-form>
					</v-card-text>
				</v-card>

				<v-card v-if="step === 2" flat height="300px">
					<!--  -->
					<!-- LOADING SCREEN -->
					<!--  -->

					<v-card-text v-if="!errors.length" class="card-height">
						<v-row class="pt-13">
							<v-col cols="12" class="d-flex justify-center">
								<v-progress-circular color="primary" indeterminate size="60" width="3" />
							</v-col>

							<v-col cols="12" class="d-flex justify-center">
								<div class="mt-4">{{ $t('loginByEmail.loadingText') }}</div>
							</v-col>
						</v-row>
					</v-card-text>

					<!--  -->
					<!-- ERRORS -->
					<!--  -->

					<v-card-text v-else>
						<v-row class="pt-13">
							<v-col cols="12" class="d-flex justify-center">
								<v-icon size="80" color="red">error</v-icon>
							</v-col>

							<v-col
								v-for="(item, index) in errors"
								:key="index"
								cols="12"
								class="red--text d-flex justify-center text-center"
							>
								<small>{{ item }}</small>
							</v-col>

							<v-col cols="12" class="d-flex justify-center">
								<v-btn
									color="primary"
									small
									depressed
									@click="$router.push({ path: '/loginByEmail' })"
								>
									{{ tryAgain }}
								</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
// import AppFooter from '../../components/layout/AppFooter.vue'

export default {
	components: {
		// AppFooter
	},

	data() {
		return {
			step: 1,
			isLoading: false,
			valid: false,
			email: '',
			showMessage: false,
			message: '',
			errors: [],
			tryAgain: 'Try Again',
		}
	},

	methods: {
		...mapActions(['loginByEmail', 'loginUsingToken']),

		async sendLink() {
			if (this.$refs.form && !this.$refs.form.validate()) return

			this.isLoading = true

			const response = await this.loginByEmail(this.email)

			this.showMessage = true

			if (response) {
				this.message = this.$t('loginByEmail.successMessage')
				this.$refs.form.reset()
				this.$refs.form.resetValidation()
			} else {
				this.message = this.$t('loginByEmail.errorMessage')
			}

			setTimeout(() => (this.showMessage = false), 3000)

			this.isLoading = false
		},

		async loginByToken(email, temporaryToken) {
			this.step = 2
			this.isLoading = true
			const resp = await this.loginUsingToken({
				email: email,
				temporaryToken: temporaryToken,
			})

			if (resp.type === 'error' && resp.status === 403) {
				this.errors.push(resp.error)
				return
			}
			setTimeout(() => {
				this.$router.push({ path: '/' })
				this.isLoading = false
			}, 1000)
		},

		handlePageLoad() {
			const email = this.$route.query.email || ''

			this.email = email

			const temporaryToken = this.$route.query.temporaryToken || null

			if (email && !temporaryToken) return this.sendLink()

			if (email && temporaryToken) return this.loginByToken(email, temporaryToken)
		},
	},

	created() {
		this.handlePageLoad()
	},
}
</script>

<style scoped>
.full-height {
	height: calc(100vh - 35px);
}

.card-height {
	height: 100%;
}

.primary-color {
	color: #5d9e82;
}
</style>
