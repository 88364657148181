var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "easyreg-table" },
    [
      _c(
        "div",
        {
          staticClass: "global-functionalities",
          on: {
            "!focus": function($event) {
              _vm.globalFunctionsFocused = true
            },
            "!blur": function($event) {
              _vm.globalFunctionsFocused = false
            }
          }
        },
        [
          _c(
            "v-text-field",
            {
              ref: "searchInput",
              staticClass: "easyreg-table-search",
              attrs: {
                dense: "",
                solo: "",
                flat: "",
                "hide-details": "",
                clearable: "",
                placeholder: _vm.searchPlaceholder,
                autocomplete: "off"
              },
              model: {
                value: _vm.searchValue,
                callback: function($$v) {
                  _vm.searchValue = $$v
                },
                expression: "searchValue"
              }
            },
            [
              _c(
                "v-icon",
                { attrs: { slot: "prepend", color: "white" }, slot: "prepend" },
                [_vm._v("search")]
              )
            ],
            1
          ),
          _vm._t("toolbar"),
          _c(
            "v-menu",
            {
              ref: "settings-dialog",
              attrs: {
                "content-class": "easyreg-table-settings-content",
                "close-on-content-click": false,
                bottom: "",
                "nudge-top": "6",
                left: "",
                "nudge-right": "6"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          {
                            staticClass: "settings-icon",
                            attrs: { color: "white" }
                          },
                          on
                        ),
                        [_vm._v("settings")]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                { staticClass: "settings-list" },
                [
                  _c(
                    "v-list-item",
                    { staticClass: "close-settings-dialog" },
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function($event) {
                                  _vm.$refs["settings-dialog"].isActive = false
                                }
                              }
                            },
                            [_vm._v("clear")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.removeAllFilters()
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "easyregTable.removeAllFiltersButton"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.resetSettingsToBaseState()
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("easyregTable.resetAllFiltersButton")
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.reApplyFilters()
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("easyregTable.reApplyFiltersButton")
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "columns-divider" }),
                  _c("div", { staticClass: "columns-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("easyregTable.columnsTitleLabel")) +
                        " "
                    )
                  ]),
                  _c("v-divider", { staticClass: "columns-title-divider" }),
                  _c(
                    "draggable",
                    {
                      staticClass: "columns-list",
                      attrs: { draggable: ".draggable-list-item" },
                      on: { change: _vm.saveColumnSettings },
                      model: {
                        value: _vm.enrichedHeaders,
                        callback: function($$v) {
                          _vm.enrichedHeaders = $$v
                        },
                        expression: "enrichedHeaders"
                      }
                    },
                    _vm._l(_vm.enrichedHeaders, function(header) {
                      return _c(
                        "v-list-item",
                        {
                          key: header.value,
                          staticClass: "draggable-list-item"
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "columns-list-title-item" },
                            [
                              _c("v-checkbox", {
                                staticClass: "header-checkbox-option",
                                attrs: {
                                  label: header.text,
                                  "hide-details": ""
                                },
                                on: { change: _vm.saveColumnSettings },
                                model: {
                                  value: header.visible,
                                  callback: function($$v) {
                                    _vm.$set(header, "visible", $$v)
                                  },
                                  expression: "header.visible"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-expand-x-transition", [
            _c("hr", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.globalFunctionsFocused,
                  expression: "globalFunctionsFocused"
                }
              ],
              staticClass: "mx-auto global-functions-animation"
            })
          ])
        ],
        2
      ),
      _c(
        "v-data-table",
        _vm._g(
          _vm._b(
            {
              ref: "vDataTable",
              class: [_vm.isMobile ? "mobile-display" : null],
              attrs: {
                items: _vm.userFilteredItems || [],
                headers: _vm.userSelectedHeaders || [],
                search: _vm.searchValueDebounced,
                "hide-default-header": !_vm.isMobile,
                "sort-by": _vm.sortBy,
                "sort-desc": _vm.sortDesc,
                "custom-sort": _vm.$attrs["custom-sort"]
                  ? _vm.$attrs["custom-sort"]
                  : _vm.easyregCustomSort,
                "custom-filter": _vm.easyregCustomFilter,
                "no-data-text": _vm.$attrs["no-data-text"]
                  ? _vm.$attrs["no-data-text"]
                  : _vm.$t("easyregTable.noTableDataText"),
                "no-results-text": _vm.$attrs["no-results-text"]
                  ? _vm.$attrs["no-results-text"]
                  : _vm.$t("easyregTable.noTableResultsText"),
                "loading-text": _vm.$attrs["loading-text"]
                  ? _vm.$attrs["loading-text"]
                  : _vm.$t("easyregTable.tableDataLoadingText")
              },
              on: {
                "update:sortBy": function($event) {
                  _vm.sortBy = $event
                },
                "update:sort-by": function($event) {
                  _vm.sortBy = $event
                },
                "update:sortDesc": function($event) {
                  _vm.sortDesc = $event
                },
                "update:sort-desc": function($event) {
                  _vm.sortDesc = $event
                }
              },
              scopedSlots: _vm._u(
                [
                  _vm._l(Object.keys(_vm.$scopedSlots), function(slot) {
                    return {
                      key: slot,
                      fn: function(scope) {
                        return [_vm._t(slot, null, null, scope)]
                      }
                    }
                  }),
                  _vm.isMobile
                    ? {
                        key: "item",
                        fn: function(props) {
                          return [
                            _c(
                              "tr",
                              { staticClass: "v-data-table__mobile-table-row" },
                              _vm._l(props.headers, function(header) {
                                return _c(
                                  "td",
                                  {
                                    key: header.value,
                                    staticClass: "v-data-table__mobile-row"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "v-data-table__mobile-row__header"
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("filter_list_alt")
                                        ]),
                                        _vm._v(" " + _vm._s(header.text) + " ")
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "v-data-table__mobile-row__cell"
                                      },
                                      [
                                        _vm._t(
                                          "item." + header.value,
                                          function() {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.item[header.value]
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          },
                                          { item: props.item }
                                        )
                                      ],
                                      2
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        }
                      }
                    : null,
                  !_vm.isMobile
                    ? {
                        key: "header",
                        fn: function(ref) {
                          var props = ref.props
                          return [
                            _c("thead", [
                              _c(
                                "tr",
                                _vm._l(props.headers, function(header) {
                                  return _c(
                                    "th",
                                    {
                                      key: header.value,
                                      staticClass: "easyreg-table-header",
                                      class: [
                                        {
                                          "column-sorted":
                                            _vm.sortBy === header.value
                                        }
                                      ],
                                      style: header.width
                                        ? "min-width:" +
                                          header.width +
                                          ";width:" +
                                          header.width +
                                          ";"
                                        : null
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            top: "",
                                            disabled: !_vm.$slots[
                                              "header." +
                                                header.value +
                                                ".tooltipText"
                                            ]
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c("span", _vm._g({}, on), [
                                                      _c(
                                                        "div",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "easyreg-header-text",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.sortColumn(
                                                                  header
                                                                )
                                                              },
                                                              mouseover: function(
                                                                $event
                                                              ) {
                                                                header.isHovering = true
                                                              },
                                                              mouseleave: function(
                                                                $event
                                                              ) {
                                                                header.isHovering = false
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                header.text
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._t(
                                            "header." +
                                              header.value +
                                              ".tooltipText"
                                          )
                                        ],
                                        2
                                      ),
                                      _c(
                                        "v-menu",
                                        {
                                          ref: header.value + "-filter-menu",
                                          refInFor: true,
                                          attrs: {
                                            "content-class":
                                              "easyreg-table-filter-content",
                                            "close-on-content-click": false,
                                            bottom: "",
                                            "nudge-top": "8",
                                            left: "",
                                            "nudge-right": "8"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          class: {
                                                            "filter-active":
                                                              _vm.uniqueColumnValues &&
                                                              _vm
                                                                .getObjectValueByPath(
                                                                  _vm.uniqueColumnValues,
                                                                  header.value
                                                                )
                                                                .some(function(
                                                                  uniqueDataObject
                                                                ) {
                                                                  return !uniqueDataObject.visible
                                                                })
                                                          },
                                                          attrs: {
                                                            "data-cy":
                                                              header.value.toLowerCase() +
                                                              "-filter-menu"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " filter_list_alt "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            { staticClass: "filter-list" },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "filters-dialog-header"
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "title-text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(header.text)
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.$refs[
                                                                header.value +
                                                                  "-filter-menu"
                                                              ][0].isActive = false
                                                            }
                                                          }
                                                        },
                                                        [_vm._v(" clear ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              ;(header.visible = !header.visible),
                                                                _vm.saveColumnSettings()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "easyregTable.hideColumnButton"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c("v-hover", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var hover =
                                                                  ref.hover
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "sort-column-button",
                                                                      class: [
                                                                        {
                                                                          "column-sorted":
                                                                            _vm.sortBy ===
                                                                            header.value
                                                                        }
                                                                      ],
                                                                      attrs: {
                                                                        color:
                                                                          "primary"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.sortColumn(
                                                                            header
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "easyregTable.sortColumnButton"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      !hover &&
                                                                      _vm.sortBy !==
                                                                        header.value
                                                                        ? _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "sorting-indicator-icon not-sorted-icon"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " remove "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      (hover &&
                                                                        _vm.sortBy !==
                                                                          header.value) ||
                                                                      (!_vm.sortDesc &&
                                                                        _vm.sortBy ===
                                                                          header.value)
                                                                        ? _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "sorting-indicator-icon sorted-ascending-icon"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " arrow_upward "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.sortDesc &&
                                                                      _vm.sortBy ===
                                                                        header.value
                                                                        ? _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "sorting-indicator-icon sorted-descending-icon"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " arrow_downward "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c("v-divider", {
                                                staticClass:
                                                  "auto-filter-divider"
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "auto-filter-title"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "easyregTable.autoFilterTitleLabel"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c("v-divider", {
                                                staticClass:
                                                  "auto-filter-title-divider"
                                              }),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "auto-filter-list"
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "auto-filter-title-item"
                                                    },
                                                    [
                                                      _vm.uniqueColumnValues
                                                        ? _c(
                                                            "v-list",
                                                            [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "auto-filter-list-item"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "auto-filter-item-title"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-checkbox",
                                                                        {
                                                                          staticClass:
                                                                            "auto-filter-checkbox",
                                                                          attrs: {
                                                                            "input-value": _vm.uniqueColumnValues[
                                                                              header
                                                                                .value
                                                                            ].every(
                                                                              function(
                                                                                uniqueDataObject
                                                                              ) {
                                                                                return uniqueDataObject.visible
                                                                              }
                                                                            ),
                                                                            indeterminate:
                                                                              _vm.uniqueColumnValues[
                                                                                header
                                                                                  .value
                                                                              ].some(
                                                                                function(
                                                                                  uniqueDataObject
                                                                                ) {
                                                                                  return (
                                                                                    uniqueDataObject.visible ===
                                                                                    true
                                                                                  )
                                                                                }
                                                                              ) &&
                                                                              _vm.uniqueColumnValues[
                                                                                header
                                                                                  .value
                                                                              ].some(
                                                                                function(
                                                                                  uniqueDataObject
                                                                                ) {
                                                                                  return (
                                                                                    uniqueDataObject.visible ===
                                                                                    false
                                                                                  )
                                                                                }
                                                                              ),
                                                                            label: _vm.$t(
                                                                              "easyregTable.selectAllLabel"
                                                                            ),
                                                                            "hide-details":
                                                                              "",
                                                                            "data-cy":
                                                                              header.value.toLowerCase() +
                                                                              "-selectall-checkbox"
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              _vm.updateAllValuesVisibility(
                                                                                _vm
                                                                                  .uniqueColumnValues[
                                                                                  header
                                                                                    .value
                                                                                ]
                                                                              ),
                                                                                _vm.saveAutoFilterSettings(
                                                                                  header.value
                                                                                )
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._l(
                                                                _vm
                                                                  .uniqueColumnValues[
                                                                  header.value
                                                                ],
                                                                function(
                                                                  uniqueDataObject
                                                                ) {
                                                                  return _c(
                                                                    "v-list-item",
                                                                    {
                                                                      key:
                                                                        uniqueDataObject.value &&
                                                                        uniqueDataObject
                                                                          .value
                                                                          .id
                                                                          ? uniqueDataObject
                                                                              .value
                                                                              .id
                                                                          : uniqueDataObject.value &&
                                                                            uniqueDataObject
                                                                              .value
                                                                              .filterValue
                                                                          ? uniqueDataObject
                                                                              .value
                                                                              .filterValue
                                                                          : uniqueDataObject.value,
                                                                      staticClass:
                                                                        "auto-filter-list-item"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          staticClass:
                                                                            "auto-filter-item-title"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-checkbox",
                                                                            {
                                                                              staticClass:
                                                                                "auto-filter-checkbox",
                                                                              attrs: {
                                                                                "hide-details":
                                                                                  "",
                                                                                "data-cy":
                                                                                  header.value.toLowerCase() +
                                                                                  "-" +
                                                                                  _vm.getAutoFilterLabel(
                                                                                    uniqueDataObject,
                                                                                    header.value
                                                                                  ) +
                                                                                  "-checkbox"
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.saveAutoFilterSettings(
                                                                                    header.value
                                                                                  )
                                                                                }
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "label",
                                                                                    fn: function() {
                                                                                      return [
                                                                                        _vm._t(
                                                                                          "header." +
                                                                                            header.value +
                                                                                            ".uniqueAutoFilterObject",
                                                                                          function() {
                                                                                            return [
                                                                                              header.value ===
                                                                                              "comment"
                                                                                                ? _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      domProps: {
                                                                                                        innerHTML: _vm._s(
                                                                                                          _vm.getAutoFilterLabel(
                                                                                                            uniqueDataObject,
                                                                                                            header.value
                                                                                                          )
                                                                                                        )
                                                                                                      }
                                                                                                    }
                                                                                                  )
                                                                                                : _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticStyle: {
                                                                                                        "text-transform":
                                                                                                          "capitalize"
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            _vm.getAutoFilterLabel(
                                                                                                              uniqueDataObject,
                                                                                                              header.value
                                                                                                            )
                                                                                                          ) +
                                                                                                          " "
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                            ]
                                                                                          },
                                                                                          {
                                                                                            uniqueAutoFilterObject: uniqueDataObject
                                                                                          }
                                                                                        )
                                                                                      ]
                                                                                    },
                                                                                    proxy: true
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                              model: {
                                                                                value:
                                                                                  uniqueDataObject.visible,
                                                                                callback: function(
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    uniqueDataObject,
                                                                                    "visible",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "uniqueDataObject.visible"
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      (header.isHovering &&
                                        _vm.sortBy !== header.value) ||
                                      (!_vm.sortDesc &&
                                        _vm.sortBy === header.value)
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "sorting-indicator-icon sorted-ascending-icon",
                                              on: {
                                                click: function($event) {
                                                  return _vm.sortColumn(header)
                                                }
                                              }
                                            },
                                            [_vm._v(" arrow_upward ")]
                                          )
                                        : _vm._e(),
                                      _vm.sortDesc &&
                                      _vm.sortBy === header.value
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "sorting-indicator-icon sorted-descending-icon",
                                              on: {
                                                click: function($event) {
                                                  return _vm.sortColumn(header)
                                                }
                                              }
                                            },
                                            [_vm._v(" arrow_downward ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ])
                          ]
                        }
                      }
                    : null
                ],
                null,
                true
              )
            },
            "v-data-table",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm._l(Object.keys(_vm.$slots), function(slot) {
            return _vm._t(slot, null, { slot: slot })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }