var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "data-cy": "landing-page" } },
    [
      _vm.$helpers.detectUnsupportedBrowser()
        ? _c("div", { staticClass: "unsupported-browser-error" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("general.detectedInternetBrowserMessage")))
            ])
          ])
        : _vm._e(),
      _vm.isIsolatedTester
        ? _c("warning", {
            attrs: {
              type: "warning-1",
              "alert-message": _vm.$t("warnings.isolatedTesterWarning")
            }
          })
        : _vm._e(),
      _vm.showNoAccessToChangePageWarning
        ? _c("warning", {
            attrs: {
              type: "warning-2",
              "alert-message": _vm.$t("warnings.noAccessToChangePageWarning")
            }
          })
        : _vm._e(),
      _c("LandingDashboard")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }