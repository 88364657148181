import error_payloads from '../owa/errors.owa'
import events_payloads from '../owa/events.owa'
import icon_payloads from '../owa/icons.owa'
import keyboard_payloads from '../owa/keyboard.owa'
import table_payloads from '../owa/table.owa'

export default {
	methods: {
		/* Method will decide and do the appropriate action accordingly */
		decideAction(ev, extraInformation = null) {
			const path = window.location.href
			let id, nodeText

			if (typeof ev === 'string') {
				id = ev
				nodeText = ''
			} else {
				id = ev.target?.id
				nodeText = ev.target?.innerText
			}

			const payloads = {
				...icon_payloads(path),
				...keyboard_payloads(path),
				...table_payloads(path, extraInformation),
				...error_payloads(path, extraInformation),
				...events_payloads(path, extraInformation, nodeText),
			}

			// Get the payload for the current action
			const payload = payloads[id]

			if (ev?.target?.id.includes('toc')) {
				const tocTitle = ev.target.id.split('#')[1]

				const event_description = `Clicked on a toc >> [${tocTitle}] on [${path}]`
				const payload = {
					event_name: `toc_click`,
					event_description,
					toc_name: ev.target.id.split('#')[1].replaceAll(' ', '_'),
				}
				return this.recordUserAction(payload)
			}

			if (ev?.target?.id.includes('facet') && ev?.target?.id.includes('#')) {
				const facetname = ev.target.id.split('#')[1]
				const event_description = `Clicked on a facet >> [${facetname}] on [${path}]`

				const payload = {
					event_name: `facet_click`,
					event_description,
					facet_name: ev.target.id.split('#')[1].replaceAll(' ', '_'),
				}

				return this.recordUserAction(payload)
			}

			this.recordUserAction(payload)
		},

		recordUserAction(payload) {
			if (!payload) return

			const pageName = this.$route.path.split('/').filter(Boolean)[0]

			let eventName = `${pageName}-page-actions`

			if (payload.event_name.includes('error')) {
				eventName = 'error-group-actions'
			}

			this.$store.dispatch('owaExists').then(() => {
				window.OWATracker.trackAction(eventName, payload.event_name, payload.event_description)
			})

			let actionData = {
				event_name: payload.event_name,
				event_description: payload.event_description,
				pageName,
			}

			if (payload.event_name === 'user_deleted') {
				actionData['user_id'] = payload.userId
				actionData['name'] = payload.name
				actionData['email'] = payload.email
			}

			if (payload && payload.facet_name) {
				actionData['facet'] = payload.facet_name
			}

			if (payload.searchTerm) {
				actionData['searchTerm'] = payload.searchTerm
			}

			if (payload.typeOfFilter && payload.event_name === 'table_filter_opened') {
				actionData['typeOfFilter'] = payload.typeOfFilter
			}

			if (payload && payload.toc_name) {
				actionData['toc'] = payload.toc_name
			}

			if (payload && payload.warningText) {
				actionData['warningText'] = payload.warningText
			}

			if (payload && payload.event_name === 'pdf_error') {
				actionData['language'] = this.$store.state.selectedLanguage.languageCode
				actionData['versionDate'] = payload.versionDate
			}

			this.$store.dispatch('trackPageVisit', {
				route: this.$route,
				pageType: pageName,
				actionData,
			})
		},
	},
}
