const error_payloads = (path, extraInformation) => {
	return {
		pdf_error: {
			event_name: 'pdf_error',
			event_description: `PDF Error "${extraInformation?.error}" on ${path}`,
			versionDate: extraInformation?.versionDate,
		},
	}
}

export default error_payloads
