<!-- the original vuetify data table enhanced with additional functionality. It should have everything the original v-data-table has -->
<!-- + "easyreg-table-name" is required to be set on the table where it's used -->
<!-- documentaion confluence page: https://easyfinreg.atlassian.net/wiki/spaces/FRON/pages/1306263568/Easyreg+table+component -->
<!--
corresponding implementaion Jiras:
Research Epic: https://easyfinreg.atlassian.net/browse/FE-356
Implementaion Epic: https://easyfinreg.atlassian.net/browse/FE-409
More Jiras: https://easyfinreg.atlassian.net/browse/FE-458
-->

<template>
	<v-container class="mt-8 mx-lg-2 collaboration_dashboard" fluid>
		<v-row v-if="!isChatUser($store.getters.userRoles)">
			<v-col cols="12" sm="9" md="7" lg="5" xl="4">
				<v-card elevation="2" raised rounded>
					<v-card-title class="mb-3">
						<span class="title">{{ $t('landingDashboard.heading') }}</span>
					</v-card-title>
					<!-- todo comment cestion -->
					<v-card-text class="pb-1 pl-6 text" data-cy="active-todo">
						<div v-if="todo_v1">
							{{ $t('landingDashboard.todo.v1') }}
						</div>
						<div v-if="todo_v2">
							<i18n path="landingDashboard.todo.v2">
								<template v-slot:X1>
									<router-link
										class="font-weight-bold"
										:to="{
											name: 'comments',
											query: {
												taggedUserIds: userId,
												status: [1, 2],
												resolved: 0,
											},
										}"
									>
										{{ statistic.noOfToDoComments + statistic.noOfInProgressComments }}
									</router-link>
								</template>
							</i18n>
						</div>
						<div v-if="todo_v3">
							<i18n path="landingDashboard.todo.v3">
								<template v-slot:X1>
									<router-link
										class="font-weight-bold"
										:to="{
											name: 'comments',
											query: {
												taggedUserIds: userId,
												status: [1],
												resolved: 0,
											},
										}"
									>
										{{ statistic.noOfToDoComments }}
									</router-link>
								</template>
								<template v-slot:X2>
									<router-link
										class="font-weight-bold"
										:to="{
											name: 'comments',
											query: {
												taggedUserIds: userId,
												status: [2],
												resolved: 0,
											},
										}"
									>
										{{ statistic.noOfInProgressComments }}
									</router-link>
								</template>
							</i18n>
						</div>
					</v-card-text>
					<!-- todo comment cestion -->

					<!-- active changes cestion -->
					<v-card-text
						v-if="!activeUser || roleLength"
						class="pb-1 pl-6 text"
						data-cy="active-changes"
					>
						<i18n path="landingDashboard.owner" tag="div">
							<template v-slot:Y>
								<router-link
									v-if="statistic.noOfOnGoingChangeElements > 0"
									class="font-weight-bold"
									:to="{
										name: 'changes',
										query: {
											'domainData.owner': userId,
											'domainData.status': ['R', 'G', 'A', '?', 'null'],
										},
									}"
								>
									{{ statistic.noOfOnGoingChangeElements }}
								</router-link>
								<span v-else>
									{{ statistic.noOfOnGoingChangeElements }}
								</span>
							</template>
						</i18n>
					</v-card-text>
					<!-- active changes cestion -->

					<!-- total comments cestion -->
					<v-card-text class="pl-6 text" data-cy="total-comments">
						<div v-if="statistic.noOfTotalTaggedComments > 0">
							<i18n path="landingDashboard.tagged.v2" tag="div">
								<template v-slot:Z>
									<router-link
										class="px-1 font-weight-bold"
										:to="{
											name: 'comments',
											query: { taggedUserIds: userId, status: [], resolved: 0 },
										}"
									>
										{{ statistic.noOfTotalTaggedComments }}
									</router-link>
								</template>
							</i18n>
						</div>
						<div v-else>{{ $t('landingDashboard.tagged.v1') }}</div>
					</v-card-text>
					<!-- total comments cestion -->
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Dictionaries from '@/mixins/dictionaries'
import SharedMixin from '@/mixins/shared'

export default {
	mixins: [Dictionaries, SharedMixin],

	data() {
		return {
			statistic: {},
		}
	},

	computed: {
		userId() {
			return this.$store.state.currentUser.id
		},
		roleLength() {
			return this.$store.getters.userRoles.length > 1
		},
		activeUser() {
			return [
				this.$store.getters.getRole('changeEditor'),
				this.$store.getters.getRole('changeReader'),
			].some((r) => this.$store.getters.userRoles.includes(r))
		},
		todo_v1() {
			return !(this.statistic.noOfToDoComments > 0) && !(this.statistic.noOfInProgressComments > 0)
		},
		todo_v2() {
			return (
				(this.statistic.noOfToDoComments > 0 && !(this.statistic.noOfInProgressComments > 0)) ||
				(!(this.statistic.noOfToDoComments > 0) && this.statistic.noOfInProgressComments > 0)
			)
		},
		todo_v3() {
			return this.statistic.noOfToDoComments > 0 && this.statistic.noOfInProgressComments > 0
		},
	},

	created() {
		this.createAuthorsDictionary()
		this.createStatusTypeDictionary()

		this.$http
			.get(this.$store.state.baseDomainURL + '/statistics/')
			.then((result) => {
				this.statistic = result.data
			})
			.catch((err) => {
				console.log(err)
			})
	},
}
</script>

<style lang="scss" scoped src="./LandingCollaborationDashboard.scss"></style>
