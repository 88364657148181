var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": _vm.$vuetify.breakpoint.smAndDown ? "90%" : "50%" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var dialogActivator = ref.on
            return [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    bottom: "",
                    disabled: _vm.$store.state.disableCustomKeyboardActions
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var tooltip = ref.on
                          return [
                            _vm.type === "icon"
                              ? _c(
                                  "img",
                                  _vm._g(
                                    {
                                      ref: "ask-question-button",
                                      staticClass: "action-icon",
                                      attrs: {
                                        src: require("@/assets/images/q&a.png")
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$helpers.resetDialogPosition()
                                        }
                                      }
                                    },
                                    Object.assign({}, tooltip, dialogActivator)
                                  )
                                )
                              : _c(
                                  "div",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "ask-text cursor-pointer mx-1",
                                      on: {
                                        click: function($event) {
                                          return _vm.$helpers.resetDialogPosition()
                                        }
                                      }
                                    },
                                    Object.assign({}, tooltip, dialogActivator)
                                  ),
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("questionFeature.ask")) +
                                        " "
                                    )
                                  ]
                                )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("questionFeature.questionFeatureTooltip"))
                    )
                  ])
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("questionFeature.questionFeatureTitle")) +
                  " "
              )
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-form",
                    {
                      ref: "question-feature-form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.questionTypes,
                                  label: _vm.$t(
                                    "questionFeature.questionTypeLabel"
                                  ),
                                  required: "",
                                  rules: [
                                    function(v) {
                                      return (
                                        !!v ||
                                        _vm.$t(
                                          "questionFeature.questionTypeRequiredMessage"
                                        )
                                      )
                                    }
                                  ],
                                  "single-line": ""
                                },
                                model: {
                                  value: _vm.selectedQuestionType,
                                  callback: function($$v) {
                                    _vm.selectedQuestionType = $$v
                                  },
                                  expression: "selectedQuestionType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("span", { staticClass: "context-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("questionFeature.contextLabel")
                                    ) +
                                    " "
                                )
                              ]),
                              _c("i18n", {
                                attrs: {
                                  path: "questionFeature.questionContext",
                                  tag: "span"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "documentTitle",
                                    fn: function() {
                                      return [
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.documentTitle))
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "windowLocationHref",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.windowLocationHref) +
                                            " "
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t("questionFeature.subjectLabel"),
                                  required: "",
                                  rules: [
                                    function(v) {
                                      return (
                                        !!v ||
                                        _vm.$t(
                                          "questionFeature.subjectRequiredMessage"
                                        )
                                      )
                                    }
                                  ]
                                },
                                model: {
                                  value: _vm.userSubject,
                                  callback: function($$v) {
                                    _vm.userSubject = $$v
                                  },
                                  expression: "userSubject"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "12" } },
                            [
                              _c("v-textarea", {
                                ref: "message-input",
                                attrs: {
                                  label: _vm.$t("questionFeature.messageLabel"),
                                  required: "",
                                  rules: [
                                    function(v) {
                                      return (
                                        !!v ||
                                        _vm.$t(
                                          "questionFeature.messageRequiredMessage"
                                        )
                                      )
                                    }
                                  ]
                                },
                                model: {
                                  value: _vm.userMessage,
                                  callback: function($$v) {
                                    _vm.userMessage = $$v
                                  },
                                  expression: "userMessage"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.currentErrorCode
                    ? _c("div", { staticClass: "error--text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("questionFeature." + _vm.currentErrorCode)
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "accent", text: "" },
                  on: { click: _vm.close }
                },
                [_vm._v(" " + _vm._s(_vm.$t("questionFeature.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "accent", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.send()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("questionFeature.send")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }