import store from '../services/vuex'
import { updateField, getField } from 'vuex-map-fields'

export default {
	namespaced: true,

	state: () => ({
		logInformation: null,
	}),

	mutations: {
		updateField,

		setLogInfo(state, payload) {
			state.logInformation = payload

			console.log(state.logInformation)
		},
	},

	actions: {
		async getLogInformation({ commit }, regulationId) {
			if (!regulationId) return

			try {
				const endpoint = `${store.state.baseMainURL}/ListOfRegulation/LogsDuringGeneration/Regulation/${regulationId} `
				const { data } = await this._vm.$http.get(endpoint)
				commit('setLogInfo', data)
				return data
			} catch (e) {
				console.log(e)
			}
		},
	},

	getters: {
		getField,
	},
}
