import i18n from './i18n' // Import the i18n file

/**
 * Make RegArt formatting dictionaries from translation files.
 * @param {object} translations Object with token translations.
 * @return {Map<string, string>} Dictionary.
 */
function makeDictionary(translations) {
	const translationBranch = translations['general']['regArts']
	return new Map(Object.keys(translationBranch).map((key) => [key, translationBranch[key]]))
}

export const RegArtFormattingFrench = makeDictionary(i18n.messages['fr-CH'])
export const RegArtFormattingEnglish = makeDictionary(i18n.messages['en-GB'])
export const RegArtFormattingItalian = makeDictionary(i18n.messages['it-CH'])
export const RegArtFormattingGerman = makeDictionary(i18n.messages['de-CH'])
