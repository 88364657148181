import Vue from 'vue' // Import Vue
import App from '@/App.vue' // Import the App component
import store from '@/services/vuex' // Import the Vuex "store" file
import router from '@/services/router' // Import the router. Enables dinamic routing
import axios from 'axios' // Import axios, enables the usage of AJAX in the Vue project
import AppHeader from '@/components/layout/AppHeader.vue'
import AppFooter from '@/components/layout/AppFooter.vue'
import EasyregTable from '@/components/common/EasyregTable.vue'
import vuetify from '@/services/vuetify' // Import Vuetify components (e.g. table and datepicker in Changes.vue)
import i18n from '@/services/i18n' // Import the i18n file
import linkify from 'vue-linkify' // Import the linkify library to create links out of raw text; used in Internal Comments
import helpers from '@/services/helpers'

// register the helpers plugin and use it across the application
const plugin = {
	install() {
		Vue.helpers = helpers
		Vue.prototype.$helpers = helpers
	},
}
Vue.use(plugin)

Vue.directive('linkified', linkify) // create a linkified directive to be used anywhere

// Set the axios to the $http variable so we can use the $http to start ajax calls anywhere
Vue.prototype.$http = axios
// add a global response interceptor function that will catch Location headers and request and return data from the new location automatically
// e.g. when creating or updating different elements (like PUT and POST domain-specific elements)
Vue.prototype.$http.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		if (response.headers.location && response.config.baseURL) {
			return Vue.prototype
				.$http({
					url: response.headers.location,
					method: 'GET',
					baseURL: response.config.baseURL,
				})
				.then((result) => {
					return result
				})
				.catch((error) => {
					return Promise.reject(error)
				})
		} else {
			return response
		}
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		console.log(error)
		if (error.response.status == 401)
			store.dispatch('logout', {
				$http: self.$http,
			})
		return Promise.reject(error)
	}
)

Vue.prototype.$eventHub = new Vue() // Global event bus

// This seems to log some tips in the console if set to true
Vue.config.productionTip = false
// Register the base header and footer components
Vue.component('app-header', AppHeader)
Vue.component('app-footer', AppFooter)

// register the Easyreg data table
Vue.component('easyreg-table', EasyregTable)

Vue.config.errorHandler = function (err) {
	// handle error
	// `info` is a Vue-specific error info, e.g. which lifecycle hook
	// the error was found in. Only available in 2.2.0+
	console.log('Unhandled error handeled:')
	console.log(err)
}

window.onerror = function (msg, url, line, col, error) {
	console.log('Unhandled general (non vue related) javascript error handeled:')
	console.log(error)
}

// Create the Vue app instance
new Vue({
	// Vue will use the store
	store,

	// Vue will use the router
	router,

	// Vue will use i18n localizations
	i18n,

	data: function () {
		return {}
	},

	computed: {
		selectedLanguage() {
			return this.$store.state.selectedLanguage
		},
	},

	watch: {
		selectedLanguage(newLang, oldLang) {
			if (newLang && (!oldLang || oldLang.languageCode !== newLang.languageCode)) {
				this.$i18n.locale = this.$store.state.localeMappings[newLang.languageCode.toUpperCase()]
			}
		},
	},

	beforeCreate: function () {
		;(function () {
			// make ALL vuetify dialogs movable EVERYWHERE; be sure to keep track if I am attaching functions to mousedown, mousemove and mouseup anywhere else except here
			const d = {}
			document.addEventListener('mousedown', (e) => {
				const closestDialog = e.target.closest('.v-dialog.v-dialog--active')
				if (
					e.button === 0 &&
					closestDialog != null &&
					e.target.classList.contains('v-card__title')
				) {
					// element which can be used to move element
					d.el = closestDialog // element which should be moved
					d.mouseStartX = e.clientX
					d.mouseStartY = e.clientY
					d.elStartX = d.el.getBoundingClientRect().left
					d.elStartY = d.el.getBoundingClientRect().top
					d.el.style.position = 'fixed'
					d.el.style.margin = 0
					d.oldTransition = d.el.style.transition
					d.el.style.transition = 'none'
				}
			})
			document.addEventListener('mousemove', (e) => {
				if (d.el === undefined) return
				d.el.style.left =
					Math.min(
						Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
						window.innerWidth - d.el.getBoundingClientRect().width
					) + 'px'
				d.el.style.top =
					Math.min(
						Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
						window.innerHeight - d.el.getBoundingClientRect().height
					) + 'px'
			})
			document.addEventListener('mouseup', () => {
				if (d.el === undefined) return
				d.el.style.transition = d.oldTransition
				d.el = undefined
			})
			setInterval(() => {
				// prevent out of bounds
				const dialog = document.querySelector('.v-dialog.v-dialog--active')
				if (dialog === null) return
				dialog.style.left =
					Math.min(
						parseInt(dialog.style.left),
						window.innerWidth - dialog.getBoundingClientRect().width
					) + 'px'
				dialog.style.top =
					Math.min(
						parseInt(dialog.style.top),
						window.innerHeight - dialog.getBoundingClientRect().height
					) + 'px'
			}, 100)
		})()
	},

	created: function () {
		let self = this

		self.doAsyncStartupActions().then(function (results) {
			let allActionsSucceeded = true
			for (let i = 0; i < results.length; i++) {
				if (!results[i]) {
					allActionsSucceeded = false
				}
			}
			if (allActionsSucceeded) self.$store.commit('setTheStartupDataLoadedState', true)
		})
	},

	mounted: function () {},

	methods: {
		doAsyncStartupActions: async function () {
			let self = this

			let startupActions = []

			// language management
			// get the languages
			startupActions.push(
				self
					.$http({
						url: self.$store.state.baseMainURL + '/ListOfLanguages',
						method: 'GET',
						// transformRequest: [
						// 	(data, headers) => {
						// 		delete headers.common.Authorization
						// 		return data
						// 	},
						// ],
					})
					.then((result) => {
						let availableLanguagesLocal = []
						for (let i = 0; i < result.data.length; i++) {
							availableLanguagesLocal.push({
								languageCode: result.data[i].language,
							})
						}
						self.$store.commit('setAvailableLanguages', availableLanguagesLocal)
						return true
					})
					.catch((err) => {
						// clear localStorage data end load login page
						if (err.status === 502) {
							store.dispatch('logout', {
								$http: self.$http,
							})
						}
					})
			)

			// AUTHENTICATION
			const token = localStorage.getItem('easyRegToken')
			if (token) {
				// Set the Authorization on axios header to our token, so our requests can be processed if a token is required.
				// This way, we do not have to set token anytime we want to make a request.
				// The token is used in ALL API requests!
				Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
				// also request and set the DOMAIN URL
				self.$store.dispatch('getCanUseRoutes')

				startupActions.push(
					self.$store
						.dispatch('setBaseDomainUrl', {
							$http: self.$http,
							email: self.$store.getters.jwtData.email,
						})
						.then(() => {
							let startupDomainActions = []

							// get user data
							startupDomainActions.push(
								self.$store
									.dispatch('getUserData', {
										$http: self.$http,
										startupRequest: true,
									})
									.then(() => {
										return true
									})
									.catch((userErr) => {
										console.log(userErr)
										console.log('error getting user data')
									})
							)

							return Promise.all(startupDomainActions)
						})
						.catch((mainErr) => {
							console.log('Error getting user data.')
							console.log(mainErr)
						})
				)
			}

			return Promise.all(startupActions)
		},
	},
	vuetify,
	render: (h) => h(App),
}).$mount('#app')
