import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/services/vuex' // Import the Vuex "store" file
// I am not lazy-loading the Home component because it is needed right away and the Google Lighthouse complains if I don't load it immediately
import Home from '@/views/HomePage/HomePage.vue'
import LoginByEmail from '@/views/LoginByEmail/LoginByEmail.vue'
import axios from 'axios'
Vue.use(VueRouter)

const routes = [
	{
		// temporary route that indexes all entities from MAIN
		// should be removed once something better like somekind of super admin dashboard is implemented
		path: '/index',
		name: 'indexAllEntities',
		component: Home,
		meta: {
			requiresAuth: true,
			allowedRoles: ['administrator'],
			title: 'Home',
		},
	},
	{
		path: '/version',
		name: 'version',
		component: () => import('../views/Version/VersionPage.vue'),
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: '/invite/accept',
		name: 'acceptInvite',
		beforeEnter: function (to, from, next) {
			if (to.query.baseURL && to.query.code) {
				// if the "baseURL" and "code" query has been passed in, proceede
				next()
			} else {
				// if no "baseURL" and/or "code" query has been passed in, redirect to home
				next({ path: '/' })
			}
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "acceptInvite" */ `@/views/${finishViewPath('AcceptInvitePage')}`
			),
	},
	{
		path: '/user/password/reset',
		name: 'passwordReset',
		component: () =>
			import(
				/* webpackChunkName: "passwordReset" */ `@/views/${finishViewPath('PasswordResetPage')}`
			),
		beforeEnter: function (to, from, next) {
			if (to.query.baseURL && to.query.code) {
				// if the "baseURL" and "code" query has been passed in, proceede to reset password
				// localhost:8082/user/password/reset?baseURL=http://localhost:8091&code=j6pcHEKyTX-Fbg8YFxvneQ==
				next()
			} else {
				// if no "baseURL" and/or "code" query has been passed in, redirect to home
				next({ path: '/' })
			}
		},
	},
	{
		path: '/PasswordResetRequest',
		name: 'PasswordResetRequest',
		component: () =>
			import(
				/* webpackChunkName: "PasswordResetRequestPage" */ `@/views/${finishViewPath(
					'PasswordResetRequest'
				)}`
			),
	},
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			title: 'e-Reg',
		},
	},
	{
		path: '/LoginByEmail',
		name: 'LoginByEmail',
		component: LoginByEmail,
		meta: {
			title: 'Login By Email',
		},
		beforeEnter(to, from, next) {
			const jwtData = store.getters.jwtData
			const isLoggedIn = store.getters.isLoggedIn

			if (jwtData && isLoggedIn) {
				store.commit('logout', null)
				next()
			} else {
				next()
			}
		},
	},
	{
		path: '/support',
		name: 'support',
		component: () =>
			import(/* webpackChunkName: "read" */ `@/views/${finishViewPath('SupportPage')}`),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/first_login',
		name: 'firstLogin',
		component: () =>
			import(/* webpackChunkName: "read" */ `@/views/${finishViewPath('FirstLoginPage')}`),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/news',
		name: 'news',
		component: () => import(/* webpackChunkName: "news" */ `@/views/${finishViewPath('NewsPage')}`),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/read',
		name: 'read',
		component: () =>
			import(/* webpackChunkName: "readLanding" */ `@/views/${finishViewPath('ReadLandingPage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['reader', 'commentEditor'],
		},
	},
	{
		path: '/read/:regTextId',
		name: 'readWithRegTextId',
		props: true,
		component: () => import(/* webpackChunkName: "read" */ `@/views/${finishViewPath('ReadPage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['reader', 'commentEditor'],
		},
	},
	{
		path: '/read/:regTextId/:articleNumber',
		name: 'readWithArticleNumber',
		props: true,
		component: () => import(/* webpackChunkName: "read" */ `@/views/${finishViewPath('ReadPage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['reader', 'commentEditor'],
		},
	},
	{
		path: '/topic',
		name: 'topic',
		component: () =>
			import(/* webpackChunkName: "topics" */ `@/views/${finishViewPath('TopicPage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['reader', 'commentEditor'],
		},
	},
	{
		path: '/topic/:topicName',
		name: 'topicWithTopicName',
		component: () =>
			import(/* webpackChunkName: "topics" */ `@/views/${finishViewPath('TopicPage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['reader', 'commentEditor'],
		},
	},
	{
		path: '/changes',
		name: 'changes',
		component: () =>
			import(/* webpackChunkName: "changes" */ `@/views/${finishViewPath('ChangesPage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['changeEditor', 'changeReader'],
			title: 'Changes',
		},
	},
	{
		path: '/loaddata',
		name: 'loadData',
		component: () =>
			import(/* webpackChunkName: "changes" */ `@/views/${finishViewPath('LoadData')}`),
		meta: {
			requiresAuth: true,
			title: 'Load data',
		},
	},
	{
		path: '/chat',
		name: 'chat',
		component: () => import(/* webpackChunkName: "changes" */ `@/views/${finishViewPath('Chat')}`),
		meta: {
			requiresAuth: true,
			title: 'Chat',
			allowedRoles: ['CHAT_USER'],
		},
	},
	{
		path: '/changes/:slug',
		name: 'changesWithSlug',
		component: () =>
			import(/* webpackChunkName: "changes" */ `@/views/${finishViewPath('ChangesPage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['changeEditor', 'changeReader'],
		},
	},
	{
		path: '/search/:searchLanguage/:displayLanguage/:searchTerm',
		name: 'searchWithTerm',
		props: true,
		component: () =>
			import(/* webpackChunkName: "search" */ `@/views/${finishViewPath('SearchPage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['reader', 'commentEditor'],
		},
	},
	{
		path: '/regTextChange/:regTextId?',
		name: 'RegTextChange',
		component: () =>
			import(/* webpackChunkName: "RegTextChange" */ `@/views/${finishViewPath('RegTextChange')}`),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/comments',
		name: 'comments',
		component: () =>
			import(/* webpackChunkName: "read" */ `@/views/${finishViewPath('CommentsPage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['reader', 'commentEditor'],
			title: 'Collaboration',
		},
	},
	{
		path: '/comments/:commentId',
		name: 'commentsWithCommentId',
		component: () =>
			import(/* webpackChunkName: "read" */ `@/views/${finishViewPath('CommentsPage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['reader', 'commentEditor'],
		},
	},
	{
		path: '/admin/updateChanges',
		name: 'adminUpdateChanges',
		component: () =>
			import(
				/* webpackChunkName: "adminUpdateChanges" */ `@/views/${finishViewPath(
					'AdminUpdateChangesPage'
				)}`
			),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: '/invite/list',
		name: 'invites',
		component: () =>
			import(/* webpackChunkName: "invites" */ `@/views/${finishViewPath('InvitesPage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['administrator'],
		},
	},
	{
		path: '/user/list',
		name: 'users',
		component: () =>
			import(/* webpackChunkName: "users" */ `@/views/${finishViewPath('UsersPage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['administrator'],
		},
	},

	{
		path: '/user/invite',
		name: 'invite',
		component: () =>
			import(/* webpackChunkName: "invite" */ `@/views/${finishViewPath('InvitePage')}`),
		meta: {
			requiresAuth: true,
			allowedRoles: ['administrator'],
		},
	},
	{
		path: '/user/EditOwnAccount',
		name: 'EditOwnAccount',
		component: () =>
			import(/* webpackChunkName: "account" */ `@/views/${finishViewPath('AccountPage')}`),
		props: true,
		meta: {
			requiresAuth: true,
		},
		beforeEnter(to, from, next) {
			// get user token and user data before route enters
			store
				.dispatch('setBaseDomainUrl', {
					$http: axios,
					email: store.getters.jwtData.email,
				})
				.then(() => {
					store.dispatch('getUserData', {
						$http: axios,
						startupRequest: true,
					})
					next()
				})
		},
	},
	{
		path: '/user/account/:id',
		name: 'account',
		component: () =>
			import(/* webpackChunkName: "account" */ `@/views/${finishViewPath('AccountPage')}`),
		props: true,
		meta: {
			requiresAuth: true,
		},
		beforeEnter: function (to, from, next) {
			if (from.name === 'password' && !to.params.editedUser) {
				// we are navigating back from the password screen to the account screen and nobody set the editedUser yet: pass the editedUser back to the account screen
				// usefull only when using the browsers back button
				to.params.editedUser = from.params.editedUser
				next()
				return
			}
			if (!to.params.editedUser && to.params.id) {
				// if after everything we want to enter this page without there being an editedUser set
				// this can happen if we are on this page -> go somewhere else -> and then click the back button in the browser
				// or user is navigating directily in the browsers address bar

				// get token
				const token = localStorage.getItem('easyRegToken')
				if (token) {
					// Set the Authorization on axios header to our token, so our requests can be processed if a token is required.
					axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
					// also request and set the DOMAIN URL
					store
						.dispatch('setBaseDomainUrl', {
							$http: axios,
							email: store.getters.jwtData.email,
						})
						.then(() => {
							store.dispatch('getUserData', {
								$http: axios,
								startupRequest: true,
							})
							let user
							axios.get(store.state.baseDomainURL + '/users').then((result) => {
								user = result.data.filter((el) => el.id === parseInt(to.params.id))[0]
								if (user !== undefined) {
									to.params.editedUser = user
									next()
								} else {
									next({
										name: 'account',
										params: {
											editedUser: store.state.currentUser,
											id: store.state.currentUser.id,
										},
									})
								}
							})
						})
				}
			}
			next()
		},
	},
	{
		path: '/user/password',
		name: 'password',
		props: true,
		component: () =>
			import(/* webpackChunkName: "password" */ `@/views/${finishViewPath('PasswordChangePage')}`),
		meta: {
			requiresAuth: true,
		},
		beforeEnter: function (to, from, next) {
			if (!to.params.editedUser) {
				// if we want to enter this page without there being an editedUser set
				// this can happen if we are on this page -> go somewhere else -> and then click the back button in the browser
				// or user is navigating directily in the browsers address bar
				// send the user home
				next('/')
				return
			}
			next()
		},
	},
	// logout via route for testing
	{
		path: '/logout',
		name: 'logout',
		beforeEnter(to, from, next) {
			store.dispatch('logout', { $http: axios }).then(() => {
				next({ path: '/' })
			})
		},
	},
	{
		// This chunk redirects all undefined URLs to the base URL: '/'
		path: '*',
		redirect: '/',
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		// if the path requires authentification: check if the user is authentificated
		if (store.getters.isLoggedIn) {
			// if the user is logged in: check for roles
			if (to.matched.some((record) => record.meta.allowedRoles)) {
				// if roles are required/set: check if the user has the required roles
				if (
					to.matched.some((record) =>
						record.meta.allowedRoles.some((r) =>
							store.getters.userRoles.includes(store.getters.getRole(r))
						)
					)
				) {
					// if the user has the required role: let him in
					next()
					return
				} else {
					/* IF THE USER DOES NOT HAVE THE 
					REQURIED ROLES REDIRECT HIM TO DASHBOARD 
					*/

					if (to.name === 'changesWithSlug') {
						const currentuserRoles = store.getters.userRoles
						const rolesToCheck = ['CHANGE_READ', 'CHANGE_EDIT']
						const exists = rolesToCheck.some((role) => currentuserRoles.includes(role))

						if (!exists) {
							next('/?showNoAccessToChangePageWarning=yes')
							return
						}
					}

					next('/')
					return
				}
			} else {
				// GET CAN USE ROUTES
				let canUse = []

				if (localStorage.getItem('canUse')) {
					canUse = JSON.parse(localStorage.getItem('canUse'))
				} else {
					canUse = store.getters.canUse
				}

				//  CHECK IF PAGE FALLS UNDER CAN USE CRITERIA
				if (to.name === 'RegTextChange') {
					// CHECK IF ALLOWED TO SEE THE PAGE
					if (canUse?.includes('CAN_SEE_REG_TEXT_CHANGE')) return next()
					else return next('/')
				}

				// if there are no required roles: just let him in
				return next()
			}
		}
		// if the user is not logged in: redirect him
		next({
			path: '/',
			query: { continue: to.fullPath },
		}) // the login screen will be here
	} else {
		// if the route doesn't require authentification: just let him see the route
		next()
	}
})

router.afterEach((to, from) => {
	// first always revert to the base app title (ignore changes in quary in the URL and ignore Read pages with regTextId and articleNumber)
	if (
		from.path !== to.path &&
		to.name !== 'readWithRegTextId' &&
		to.name !== 'readWithArticleNumber'
	)
		document.title = store.state.baseAppTitle
	// Unfortunately the window location is not yet updated here
	// We need to make our own url using the data provided by the router

	// these routes will be handled somewhere else (probably in the corresponding pages/components)
	let escapedRoutes = [
		'read',
		'readWithRegTextId',
		'readWithArticleNumber',
		'topic',
		'topicWithTopicName',
		'changes',
		'changesWithSlug',
		'search',
		'searchWithTerm',
		'invites',
		'users',
		'invite',
		'account',
		'password',
		'support',
		'firstLogin',
	]

	if (escapedRoutes.indexOf(to.name) === -1) store.dispatch('trackPageVisit', { route: to })
})

/**
 * @param  {string}   name     the filename (basename-componentname) of the view to load.
 */
function finishViewPath(name) {
	return name + '/' + name + '.vue'
}

export default router
