<!-- This is the base entry point to the entire Vue application -->
<template>
	<div id="app">
		<picture>
			<source
				srcset="
					@/assets/images/swirls/Swirls2880x2048.webp 2880w,
					@/assets/images/swirls/Swirls1920x1365.webp 1920w,
					@/assets/images/swirls/Swirls1264x898.webp  1264w,
					@/assets/images/swirls/Swirls960x682.webp    960w,
					@/assets/images/swirls/Swirls600x424.webp    600w,
					@/assets/images/swirls/Swirls300x213.webp    300w
				"
				sizes="100vw"
				type="image/webp"
			/>
			<source
				srcset="
					@/assets/images/swirls/Swirls2880x2048.jpg 2880w,
					@/assets/images/swirls/Swirls1920x1365.jpg 1920w,
					@/assets/images/swirls/Swirls1264x898.jpg  1264w,
					@/assets/images/swirls/Swirls960x682.jpg    960w,
					@/assets/images/swirls/Swirls600x424.jpg    600w,
					@/assets/images/swirls/Swirls300x213.jpg    300w
				"
				sizes="100vw"
				type="image/jpeg"
			/>
			<img src="@/assets/images/swirls/Swirls960x682.jpg" class="background-swirls" />
		</picture>
		<v-app
			id="vuetify-container"
			ref="vuetify-container"
			style="background: transparent"
			:class="[{ 'tablet-screen': tabletScreenWidth }, { 'phone-screen': phoneScreenWidth }]"
		>
			<!-- for vuetify components to work accross the application this v-app element has to be here -->
			<RegArtTooltip v-if="$store.state.areWeUsingRegArtTooltip" ref="regart-link-tooltip" />
			<app-header
				v-if="
					$store.getters.isLoggedIn &&
					$route.name !== 'acceptInvite' &&
					$route.name !== 'passwordReset' &&
					$store.state.allStartupDataLoaded
				"
				ref="app-header"
			></app-header>
			<!-- The header bar. This one is always loaded. -->
			<router-view
				v-if="$store.state.allStartupDataLoaded"
				:key="route + $store.state.reloadMainComponentKey"
				@mouseover.native="onMouseover"
				@touchend.native="handleClicks"
				@click.native="handleClicks"
				@search="handleSearch"
			/>
			<!-- The main view/component. This one is loaded based on the route; the :key ensures that everything is reloaded on URL change -->
			<app-footer
				v-if="
					$store.getters.isLoggedIn &&
					$route.name !== 'acceptInvite' &&
					$route.name !== 'passwordReset' &&
					$store.state.allStartupDataLoaded
				"
			></app-footer>

			<div
				v-if="
					!$store.getters.isLoggedIn &&
					($route.path === '/' || $route.path === '/LoginByEmail') &&
					showFooter
				"
			>
				<v-col cols="12" class="py-1 d-flex justify-space-between">
					<div class="d-flex align-center">
						<v-icon>copyright</v-icon>
						<a href="https://easy-reg.ch" class="px-1">
							{{ $t('general.easyRegBrand') }}
						</a>
						{{ $t('general.easyRegPunchLine') }}
					</div>

					<div class="d-flex align-center">
						<a href=" https://easy-reg.ch/e-reg-features/" class="px-1">
							{{ $t('general.fullFeatureText') }}
						</a>
					</div>
				</v-col>
			</div>
		</v-app>
	</div>
</template>

<script>
import RegArtTooltip from '@/components/common/RegArtTooltip'
import Tracker from '@/mixins/tracker'
import { version } from './version'
import shared from './mixins/shared'

export default {
	name: 'App',
	components: {
		RegArtTooltip,
	},
	mixins: [Tracker, shared],
	data: function () {
		return {
			version,
			showFooter: false,
			route: null,
			width: window.outerWidth,
			tabletScreenWidth: window.innerWidth <= this.$store.state.tabletBreakpoint,
			phoneScreenWidth: window.innerWidth <= this.$store.state.phoneBreakpoint,
		}
	},
	computed: {
		updateLayout() {
			return this.$store.state.updateLayoutProps.updateLayout
		},
	},
	watch: {
		$route(to) {
			this.setPageTitle()
			let pathToReturn = to.path

			if (to.name === 'readWithArticleNumber') {
				// remove the article number from the path so the article number change doesn't cause the component to relaod
				let articleNumberLength = encodeURIComponent(to.params.articleNumber.length)
				pathToReturn =
					to.path.substring(0, to.path.lastIndexOf('/')) +
					to.path.substring(to.path.lastIndexOf('/') + articleNumberLength + 1)
			}

			if (to.name === 'changesWithSlug') {
				// remove the slug from the path so the slug change doesn't cause the component to relaod
				pathToReturn = to.path.substring(0, to.path.lastIndexOf('/'))
			}

			// first decode and then encode to ensure that you're comparing encoded routes on both side
			// (decoding a decoded route will return the same result as decoding an encoded route)
			if (
				encodeURIComponent(decodeURIComponent(this.route)) !==
				encodeURIComponent(decodeURIComponent(pathToReturn))
			)
				this.route = pathToReturn
		},
		updateLayout() {
			// update tabletScreenWidth flag
			if (window.innerWidth <= this.$store.state.tabletBreakpoint) this.tabletScreenWidth = true
			else this.tabletScreenWidth = false

			// update phoneScreenWidth flag
			if (window.innerWidth <= this.$store.state.phoneBreakpoint) this.phoneScreenWidth = true
			else this.phoneScreenWidth = false

			// re-calculate scrollbar width
			this.setScrollbarWidth()
		},
	},
	beforeCreate: function () {},
	created: function () {
		this.printVersion()
		this.$eventHub.$on('styleRegArtLinks', this.styleRegArtLinks)
		this.setScrollbarWidth()
	},
	beforeMount: function () {
		// if (!this.isChatUser(this.$store.getters.userRoles)) {
		// 	this.$router.push({ path: "/chat" })
		// }
	},
	mounted: function () {
		if (this.$store.state.updateLayoutProps.autoUpdateLayoutOnBrowserResize) {
			window.addEventListener('orientationchange', this.orientationChange)
			window.addEventListener('resize', this.onBrowserResize)
		}

		setTimeout(() => {
			this.showFooter = true
		}, 300)

		document.addEventListener('click', this.decideAction)
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {
		this.$eventHub.$off('styleRegArtLinks')
	},
	destroyed: function () {},
	methods: {
		handleSearch(str) {
			this.$refs['app-header'].refreshSearch(str)
		},
		printVersion() {
			console.log(`%c${this.version}`, 'color: green; font-size: 20px')
		},
		setPageTitle() {
			document.title = this.$route.meta?.title || 'e-Reg'
		},
		styleRegArtLinks() {
			let self = this
			document.querySelectorAll('a').forEach(function (link) {
				let url = new URL(link.href)
				let resolved = self.$router.resolve(url.pathname)

				if (resolved.route.name === 'readWithArticleNumber') {
					link.classList.add('regart-link')
				}
			})
		},
		handleClicks($event) {
			// if there are normal HTML links here
			// and they are pointing inside the application
			// use the router to navigate instead the normal way
			if (this.$helpers.isSomeParentALink($event.target)) {
				const href = this.$helpers.isSomeParentALink($event.target).href
				let url = new URL(href)
				// we can't just pass in the full href to the .resolve() because it would attach it to the current URL, so we need to combine pathname and search parameters
				let resolved = this.$router.resolve(url.pathname + url.search)

				if (resolved.route.name !== 'home') {
					$event.preventDefault()

					if (
						$event.type === 'touchend' &&
						resolved.route.name === 'readWithArticleNumber' &&
						(!this.$refs['regart-link-tooltip'].displayRegArtTooltip ||
							(this.$refs['regart-link-tooltip'].displayRegArtTooltip &&
								this.$refs['regart-link-tooltip'].hideTheTooltipInitially))
					) {
						// if the event is touchend and we are touching a "tooltip enabled RegArt link" and the tooltip is closed
						// do custom things
						this.onMouseover($event) // Triggers the tooltip.
					} else if (
						$event.type === 'touchend' &&
						resolved.route.name === 'readWithArticleNumber' &&
						this.$refs['regart-link-tooltip'].regTextId === resolved.route.params.regTextId &&
						this.$refs['regart-link-tooltip'].articleNumber === resolved.route.params.articleNumber
					) {
						// if the event is touchend and we are touching a "tooltip enabled RegArt link"
						// and the tooltip for this exact link is opened
						// colse the tooltip
						this.$refs['regart-link-tooltip'].displayRegArtTooltip = false
						// redirect to link
						this.redirectInsideApp(resolved.route)
					} else if (
						$event.type === 'touchend' &&
						resolved.route.name === 'readWithArticleNumber'
					) {
						// we are touching a different RegArt link than the one that has a tooltip opened. Open the tooltip for that one.
						this.onMouseover($event) // Triggers the tooltip.
					} else {
						// if this is not a user touch but a click
						// close the tooltip
						this.$refs['regart-link-tooltip'].displayRegArtTooltip = false
						// redirect immediately
						this.redirectInsideApp(resolved.route)
					}
				} else if (resolved.route.name === 'home' && resolved.href !== '/') {
					// links are pointing outside of the application
					$event.preventDefault()
					window.open(href, '_blank')
				}
			}
		},
		redirectInsideApp(route) {
			if (route.name === 'readWithArticleNumber') {
				// if the link is pointing to the read page process the paragraphId first if neccessary
				this.$router
					.push({
						name: 'readWithArticleNumber',
						params: {
							regTextId: route.params.regTextId,
							articleNumber: this.$helpers.processParagraphIdRange(route.params.articleNumber),
						},
						query: route.query,
					})
					.catch((error) => console.log(error))
			} else {
				// if the link is not pointing to the read page so just redirect normally
				this.$router.push({ path: route.fullPath }).catch((error) => console.log(error))
			}
		},
		onMouseover($event) {
			// save the fact that user is on a device he can hover over with his mouse

			// delegate to the RegArt link tooltip if enabled
			if (this.$store.state.areWeUsingRegArtTooltip)
				this.$refs['regart-link-tooltip'].onMouseover($event)
		},
		setScrollbarWidth() {
			// source of inspiration: https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
			// Creating invisible container
			const outer = document.createElement('div')
			outer.style.position = 'absolute'
			outer.style.left = '-1000px'
			outer.style.top = '-1000px'
			outer.style.border = 'none'
			outer.style.height = '200px'
			outer.style.margin = '0'
			outer.style.padding = '0'
			outer.style.width = '200px'
			outer.style.visibility = 'hidden'
			outer.style.overflow = 'scroll' // forcing scrollbar to appear
			outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps
			document.body.appendChild(outer)

			// Creating inner element and placing it in the container
			const inner = document.createElement('div')
			inner.style.border = 'none'
			inner.style.height = '200px'
			inner.style.margin = '0'
			inner.style.padding = '0'
			inner.style.width = '200px'
			outer.appendChild(inner)

			outer.scrollTo(0, 0)

			// Calculating difference between container's full width and the child width
			const scrollbarWidth = inner.clientWidth - outer.clientWidth || 0

			// Removing temporary elements from the DOM
			outer.parentNode.removeChild(outer)

			this.$store.commit('setScrollbarWidth', scrollbarWidth)
		},
		orientationChange() {
			// orientation change has now started but is not yet finished, so we can't get the real dimensions
			// listen to the resize event to find out when the orientation change is actually finished
			window.addEventListener('resize', this.afterOrientationChange)
		},
		afterOrientationChange() {
			this.$store.commit('triggerUpdateLayout')

			// now remove the one-time resize event listener; we got what we needed
			window.removeEventListener('resize', this.afterOrientationChange)
		},
		onBrowserResize() {
			if (window.outerWidth !== this.width) {
				this.$store.dispatch('registerBrowserResize')
				this.width = window.outerWidth
			}
		},
	},
}
</script>

<style lang="scss">
/*Load the main css file*/
@import '@/scss/variables.scss';
@import '@/scss/main.scss';
</style>
