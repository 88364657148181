import Shared from '@/mixins/shared'
import { mapGetters } from 'vuex'

export default {
	mixins: [Shared],

	computed: {
		...mapGetters([
			'userNames',
			'statusTypesDictionary',
			'changeUsersDictionary',
			'authorsDictionary',
			'commentTypesDictionary',
		]),
	},

	methods: {
		cacheData(key, value) {
			localStorage.setItem(key, JSON.stringify(value))
		},

		getCachedData(key) {
			return JSON.parse(localStorage.getItem(key))
		},

		setDataInStore(key, value) {
			this.$store.commit('setState', { key, value })
		},

		getDictionariesAsObject() {
			return {
				authorDictionary: this.authorsDictionary,
				statusTypeDictionary: this.statusTypesDictionary,
				commentTypeDictionary: this.commentTypesDictionary,
			}
		},

		async createStatusTypeDictionary() {
			const cachedStatusTypes = this.getCachedData('statusTypesDictionary')
			const statusTypes = this.getCachedData('statusTypes')

			if (cachedStatusTypes && statusTypes) {
				this.setDataInStore('statusTypesDictionary', cachedStatusTypes)
				this.setDataInStore('statusTypes', statusTypes)
				return true
			}

			const response = await this.$store.dispatch('getStatusTypes')
			let statuses = null

			if (response) {
				statuses = this.$store.getters.statusTypes
				statuses.forEach((el) => {
					this.statusTypesDictionary[el.id] = el
				})
			}

			this.cacheData('statusTypes', statuses)
			this.cacheData('statusTypesDictionary', this.statusTypesDictionary)

			return this.statusTypesDictionary
		},

		async createChangeUsersDictionary() {
			const cachedChangeUsers = this.getCachedData('changeUsersDictionary')
			const changeUsers = this.getCachedData('changeUsers')

			if (cachedChangeUsers) {
				this.setDataInStore('changeUsersDictionary', cachedChangeUsers)
				this.setDataInStore('changeUsers', changeUsers)
				return this.changeUsersDictionary
			}

			const response = await this.$store.dispatch('changeUsers')

			if (response) {
				const changeRoles = this.$store.getters.changeUserList
				changeRoles.forEach((el) => {
					this.changeUsersDictionary[el.id] = el
				})

				this.cacheData('changeUsers', changeRoles)
				this.cacheData('changeUsersDictionary', this.changeUsersDictionary)
			}

			return this.changeUsersDictionary
		},

		async createAuthorsDictionary() {
			const cachedAuthors = this.getCachedData('authorsDictionary')
			const authors = this.getCachedData('authors')

			if (cachedAuthors) {
				this.setDataInStore('authorsDictionary', cachedAuthors)
				this.setDataInStore('userNames', authors)
				return true
			}

			const response = await this.$store.dispatch('getUserNames')

			if (response) {
				this.userNames.forEach((user) => {
					this.authorsDictionary[user.id] = user
				})

				this.cacheData('authors', this.userNames)
				this.cacheData('authorsDictionary', this.authorsDictionary)
			}

			return this.authorsDictionary
		},

		async createCommentTypesDictionary() {
			const cachedCommentTypes = this.getCachedData('commentTypesDictionary')
			const commentTypes = this.getCachedData('commentTypes')

			if (cachedCommentTypes) {
				this.setDataInStore('commentTypesDictionary', cachedCommentTypes)
				this.setDataInStore('commentTypes', commentTypes)
				return true
			}

			const response = await this.$store.dispatch('getAllCommentTypes')

			if (response) {
				let dictionary = {}
				this.commentTypes.forEach((el) => (dictionary[el.id] = el.labelEn))
				this.setDataInStore('commentTypesDictionary', dictionary)

				this.cacheData('commentTypes', this.commentTypes)
				this.cacheData('commentTypesDictionary', this.commentTypesDictionary)
			}

			return this.commentTypesDictionary
		},
	},
}
