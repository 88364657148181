import store from '../services/vuex'

export default {
	state: () => ({
		fetchingToc: true,
		lastActive: null,
		tableOfContents: [],
		tocIds: [],
		tocIdsWithPosition: {},
		allRegArts: [],
		allRegArtsIndexes: {},
		subParagraphs: [],
		subParagraphsIndexes: {},
		selectedToc: null,
		currentIndex: null,
		currentIndexSubPara: 0,
		documentTitle: {},
	}),

	mutations: {
		setTableOfContents(state, payload) {
			state.tableOfContents = payload
		},

		setSelectedToc(state, payload) {
			state.selectedToc = payload
		},

		selectFirstToc(state) {
			state.selectedToc = state.tableOfContents[0]
		},

		setAllRegArts(state, payload) {
			state.allRegArts = payload
		},

		setCurrentIndex(state, payload) {
			state.currentIndex = payload
		},

		startTocLoading(state) {
			state.fetchingToc = true
		},

		stopTocLoading(state) {
			state.fetchingToc = false
		},

		setSubParagraphs(state, payload) {
			state.subParagraphs = payload
		},

		setCurrentIndexSubPara(state, payload) {
			state.currentIndexSubPara = payload
		},

		setAllRegArtsIndexes(state, payload) {
			state.allRegArtsIndexes = payload
		},

		setSubParagraphIndexes(state, payload) {
			state.subParagraphsIndexes = payload
		},

		setTocIds(state, payload) {
			state.tocIds = payload
		},

		setLastActive(state, payload) {
			state.lastActive = payload
		},

		setDocumentTitle(state, payload) {
			state.documentTitle = payload
		},

		setTocIdsWithPosition(state, payload) {
			state.tocIdsWithPosition = payload
		},
	},

	actions: {
		// eslint-disable-next-line no-empty-pattern
		async getTableOfContents({}, payload) {
			try {
				const endpoint = `${store.state.baseMainURL}/TableOfContent/V2/RegTextID/${payload.regTextId}?language=${payload.language}&versionDate=${payload.versionDate}`
				const { data } = await this._vm.$http.get(endpoint)
				return data
			} catch (e) {
				console.log(e)
			}
		},

		// eslint-disable-next-line no-empty-pattern
		async getAllRegArts({}, payload) {
			try {
				const endpoint = `${store.state.baseMainURL}/RegArtCoordinates/AllRegArts/${payload.regulationId}?language=${payload.language}&versionDate=${payload.versionDate}`
				const { data } = await this._vm.$http.get(endpoint)
				return data
			} catch (e) {
				console.log(e)
			}
		},

		// eslint-disable-next-line no-empty-pattern
		async getSubParagraphs({}, payload) {
			try {
				const endpoint = `${store.state.baseMainURL}/RegArtCoordinates/SubParagraphs/AllRegArts/${payload.regTextId}?language=${payload.language}&versionDate=${payload.versionDate}`
				const { data } = await this._vm.$http.get(endpoint)
				return data
			} catch (e) {
				console.log(e)
			}
		},
	},

	getters: {
		tableOfContents: (state) => state.tableOfContents,
		selectedToc: (state) => state.selectedToc,
		allRegArts: (state) => state.allRegArts,
		currentIndex: (state) => state.currentIndex,
		fetchingToc: (state) => state.fetchingToc,
		tocIds: (state) => state.tocIds,
		lastActive: (state) => state.lastActive,
		subParagraphs: (state) => state.subParagraphs,
		currentIndexSubPara: (state) => state.currentIndexSubPara,
		allRegArtIndexes: (state) => state.allRegArtsIndexes,
		allSubParagraphIndexes: (state) => state.subParagraphsIndexes,
		documentTitle: (state) => state.documentTitle,
		tocIdsWithPosition: (state) => state.tocIdsWithPosition,
	},
}
