// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"primaryColor": "#237853",
	"errorColor": "#ff9200",
	"secondaryColor": "#69a040",
	"infoColor": "#85cdd9",
	"selectColor": "#dbeae5"
};
module.exports = exports;
