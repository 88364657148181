import store from '../services/vuex'

export default {
	state: () => ({
		internalCommentsFlat: [],
		beautifiedRegArtNames: {},
	}),
	mutations: {
		setState(state, payload) {
			state[payload.key] = payload.value
		},
		SET_INTERNAL_COMMENTS_FLAT(state, payload) {
			state.internalCommentsFlat = payload
		},
		SET_BEAUTIFIED_REG_ART_NAMES(state, payload) {
			state.beautifiedRegArtNames = payload
		},
	},
	actions: {
		/* GET ALL INTERNAL COMMENTS */
		async getInternalComments({ commit }) {
			try {
				const endpoint = `${store.state.baseDomainURL}/InternalCommentsDomain/GetwChangeLabel`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setState', { key: 'internalCommentsFlat', value: data })
				return data
			} catch (e) {
				console.log(e)
			}
		},

		/* GET REG TEXT IDS INFORMATION */
		async getListOfRegulation({ commit }, payload) {
			try {
				console.log(commit)
				const endpoint = `${store.state.baseMainURL}/ListOfRegulation/`
				const { data } = await this._vm.$http.post(endpoint, payload)
				return data
			} catch (e) {
				console.log(e)
			}
		},
	},
	getters: {
		internalCommentsFlat: (state) => state.internalCommentsFlat,
		beautifiedRegArtNames: (state) => state.beautifiedRegArtNames,
	},
}
