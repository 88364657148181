<!--This should be a constant header across all the SPA-->
<template>
	<div class="root-div">
		<!-- the root div is necessary only so I can put the v-navigation-drawer outside of the v-app-bar component (there can be only one root element in .vue file)
			because otherwise there are problems with the overlay when the drawer is shown -->
		<v-app-bar
			ref="header"
			class="header"
			:class="[
				{ 'tablet-screen': tabletScreenWidth },
				{ 'hide-header': hideAppHeader },
				{ 'phone-screen': phoneScreenWidth },
			]"
			color="primary"
			:dense="tabletScreenWidth"
			:flat="phoneScreenWidth"
		>
			<router-link ref="logo-link" to="/" class="logo-link" data-cy="home_page">
				<img src="@/assets/images/eReg_logo/eReg_logo_white.png" />
			</router-link>
			<div v-if="!tabletScreenWidth" class="links">
				<router-link
					to="/comments"
					:disabled="
						![$store.getters.getRole('reader'), $store.getters.getRole('commentEditor')].some((r) =>
							$store.getters.userRoles.includes(r)
						)
					"
					exact
					class="header-link-not-active"
					exact-active-class="header-link-active"
					:class="isChatUser($store.getters.userRoles) && 'disabled-link'"
				>
					{{ $t('appHeader.commentsLabel') }}
				</router-link>
				<hr width="1" size="25" />
				<router-link
					v-if="
						[$store.getters.getRole('changeEditor'), $store.getters.getRole('changeReader')].some(
							(r) => $store.getters.userRoles.includes(r)
						)
					"
					to="/changes"
					:data-text="$t('appHeader.changesLabel')"
					exact
					class="header-link-not-active"
					exact-active-class="header-link-active"
					:class="isChatUser($store.getters.userRoles) && 'disabled-link'"
				>
					{{ $t('appHeader.changesLabel') }}
				</router-link>
				<span v-else class="disabled-link">
					{{ $t('appHeader.changesLabel') }}
				</span>
				<hr width="1" size="25" />
				<router-link
					v-if="
						[$store.getters.getRole('reader'), $store.getters.getRole('commentEditor')].some((r) =>
							$store.getters.userRoles.includes(r)
						)
					"
					to="/read"
					:data-text="$t('appHeader.readLabel')"
					exact
					:class="isChatUser($store.getters.userRoles) && 'disabled-link'"
				>
					{{ $t('appHeader.readLabel') }}
				</router-link>
				<span v-else class="disabled-link">
					{{ $t('appHeader.readLabel') }}
				</span>
				<hr width="1" size="25" />
				<router-link
					v-if="servicePlan !== 'ESSENTIAL_K'"
					to="/topic/landing"
					:data-text="$t('appHeader.topicsLabel')"
					exact
					:class="isChatUser($store.getters.userRoles) && 'disabled-link'"
				>
					{{ $t('appHeader.topicsLabel') }}
				</router-link>
				<router-link
					v-else
					to="/topic/landing-k"
					exact
					:class="isChatUser($store.getters.userRoles) && 'disabled-link'"
				>
					{{ $t('appHeader.topicsLabel') }}
				</router-link>

				<!-- REG TEXT CHANGES NEW ADDED BY BASIT -->
				<!--
					<hr width="1" size="25" />
				<router-link
					v-if="showRegTextChange"
					to="/regTextChange"
					:data-text="regTextLabel"
				>
					{{ regTextLabel }}
				</router-link> 
				-->
				<!-- REG TEXT CHANGES NEW ADDED BY BASIT -->
			</div>
			<div ref="search" class="search" :style="searchExpandStyle">
				<v-text-field
					id="search-input"
					ref="search-text"
					v-model="searchTerm"
					v-focus="!tabletScreenWidth"
					:loading="isSearching"
					rounded
					clearable
					hide-details
					prepend-inner-icon="search"
					:placeholder="$t('appHeader.searchPlaceholder')"
					class="search-text"
					:disabled="
						![$store.getters.getRole('reader'), $store.getters.getRole('commentEditor')].some((r) =>
							$store.getters.userRoles.includes(r)
						) || isChatUser($store.getters.userRoles)
					"
					autocomplete="off"
					data-cy="search-input"
					:append-icon="toggleComplete ? 'expand_less' : 'expand_more'"
					@keyup.enter="initiateTheSearch"
					@click:prepend-inner="initiateTheSearch"
					@click:append="toggleComplete = !toggleComplete"
					@focus="isSearchFocused = true"
					@blur="isSearchFocused = false"
					@click:clear="onSearchInputClear"
				>
					<template v-slot:progress>
						<v-progress-linear
							indeterminate
							absolute
							height="3"
							class="progress-linear"
						></v-progress-linear>
					</template>
				</v-text-field>
				<!--FE-575 Static Search Bar drop down-->
				<div v-if="toggleComplete" class="static-keys d-flex flex-column">
					<v-simple-table class="static-keys_table" dense>
						<template v-slot:default>
							<tbody>
								<tr v-for="key in localStringsKeys" :key="key">
									<td @click="getStaticKey(key)">
										{{ $t(`search.staticHelpKey.${key}`, { staticKey: key }) }}
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<div class="static-keys_spacer ma-auto"></div>
					<div class="static-keys_list">
						<div class="font-weight-bold font-italic pb-1">
							{{ $tc('search.important', 1) }}
						</div>
						<div class="body-2">{{ $tc('search.important', 2) }}</div>
						<ul>
							<!-- static list 1 line with dynamic slot -->
							<i18n path="search.staticList.list_1" tag="li">
								<template v-for="(item, i) in listLinks_1" v-slot:[i]>
									<span :key="i" class="font-weight-bold text--" @click="makeAutoSearch(item.name)">
										{{ item.name }}
									</span>
								</template>
							</i18n>

							<!-- static list 2 line with dynamic slot -->
							<i18n path="search.staticList.list_2" tag="li">
								<template v-for="(item, i) in listLinks_2" v-slot:[i]>
									<span :key="i" class="font-weight-bold" @click="makeAutoSearch(item.name)">
										{{ item.name }}
									</span>
								</template>
							</i18n>

							<!-- static list 3 line with dynamic slot -->
							<i18n path="search.staticList.list_3" tag="li">
								<template v-for="(item, i) in listLinks_3" v-slot:[i]>
									<router-link :key="i" class="font-weight-bold" :to="{ path: item.link }">
										{{ locationSlug }}{{ item.link }}
									</router-link>
								</template>
							</i18n>
						</ul>
					</div>
				</div>
			</div>
			<hr ref="header-hr" width="1" size="25" class="hr" />
			<v-menu v-model="isOpened" offset-y>
				<template v-slot:activator="{ on: menu }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on: tooltip }">
							<div
								class="dropdown-selected"
								:class="{ 'dropdown-selected-sm': evenSmallerScreen }"
								data-cy="website-language-dropdown"
								v-on="{ ...tooltip, ...menu }"
							>
								<div v-if="!evenSmallerScreen">
									{{
										$t(
											'appHeader.' +
												$store.getters.getLanguageTranslationHook(
													$store.state.currentUser.documentDisplayLanguage
												)
										)
									}}
								</div>
								<div v-if="evenSmallerScreen">
									{{
										$t(
											'appHeader.' +
												$store.getters.getLanguageTranslationHook(
													$store.state.currentUser.documentDisplayLanguage
												)
										).substring(0, 2)
									}}
								</div>
								<v-icon>keyboard_arrow_down</v-icon>
							</div>
						</template>
						<span>{{ $t('appHeader.languageBarTooltip') }}</span>
					</v-tooltip>
				</template>
				<v-list>
					<v-list-item
						v-for="lang in $store.state.availableLanguages"
						:key="lang.languageCode"
						:data-cy="`lang-${lang.languageCode}`"
						@click="langaugeClick(lang.languageCode, $event)"
					>
						<v-list-item-title>
							{{ $t('appHeader.' + $store.getters.getLanguageTranslationHook(lang.languageCode)) }}
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-btn icon data-cy="avatar" @click.stop="drawer = !drawer">
				<v-icon size="35" color="#fff">menu</v-icon>
			</v-btn>
		</v-app-bar>
		<v-navigation-drawer
			v-model="drawer"
			right
			app
			temporary
			class="navigation-drawer"
			:class="{ 'navigation-drawer_mobile': tabletScreenWidth }"
			height="100%"
			data-cy="nav"
		>
			<!-- APPEND USER NAME -->
			<template #append>
				<div class="pb-16 mb-4 px-2 text-center grey--text">
					<small>
						{{ $t('landingDashboard.loggedIn', { name: $store.state.currentUser.fullName }) }}
					</small>
				</div>
			</template>

			<v-list class="navigation-link-list d-flex flex-column justify-space-between">
				<div>
					<v-list-item
						v-if="tabletScreenWidth"
						:disabled="
							![$store.getters.getRole('reader'), $store.getters.getRole('commentEditor')].some(
								(r) => $store.getters.userRoles.includes(r)
							) || isChatUser($store.getters.userRoles)
						"
						@click="userMenuClick({ path: '/comments' })"
					>
						<v-list-item-title>
							{{ $t('appHeader.commentsLabel') }}
						</v-list-item-title>
					</v-list-item>

					<v-list-item
						v-if="tabletScreenWidth"
						:disabled="
							!canUseRoutes.includes('CAN_SEE_CHANGE') || isChatUser($store.getters.userRoles)
						"
						@click="userMenuClick({ path: '/changes' })"
					>
						<v-list-item-title>
							{{ $t('appHeader.changesLabel') }}
						</v-list-item-title>
					</v-list-item>

					<v-list-item
						v-if="tabletScreenWidth"
						:disabled="
							![$store.getters.getRole('reader'), $store.getters.getRole('commentEditor')].some(
								(r) => $store.getters.userRoles.includes(r)
							) || isChatUser($store.getters.userRoles)
						"
						@click="userMenuClick({ path: '/topic' })"
					>
						<v-list-item-title>
							{{ $t('appHeader.topicsLabel') }}
						</v-list-item-title>
					</v-list-item>

					<v-list-item
						v-if="tabletScreenWidth"
						:disabled="
							![
								$store.getters.getRole('changeEditor'),
								$store.getters.getRole('changeReader'),
							].some((r) => $store.getters.userRoles.includes(r)) ||
							isChatUser($store.getters.userRoles)
						"
						@click="userMenuClick({ path: '/read' })"
					>
						<v-list-item-title>
							{{ $t('appHeader.readLabel') }}
						</v-list-item-title>
					</v-list-item>

					<v-list-item
						:disabled="
							!canUseRoutes.includes('CAN_SEE_NEWS') || isChatUser($store.getters.userRoles)
						"
						@click="userMenuClick({ path: '/news' })"
					>
						<v-list-item-title>
							{{ $t('appHeader.newsLabel') }}
						</v-list-item-title>
					</v-list-item>

					<v-list-item
						v-if="$store.getters.userRoles.includes('EREG_STATICDB_DATA_ADMIN')"
						:disabled="
							!canUseRoutes.includes('CAN_DIRECT_DATA_LOADING') ||
							isChatUser($store.getters.userRoles)
						"
						@click="userMenuClick({ path: '/loadData' })"
					>
						<v-list-item-title>
							{{ loadDataLabel }}
						</v-list-item-title>
					</v-list-item>

					<!-- RegTextChange -->
					<v-list-item
						:disabled="
							!canUseRoutes.includes('CAN_SEE_REG_TEXT_CHANGE') ||
							isChatUser($store.getters.userRoles)
						"
						@click="userMenuClick({ path: '/regTextChange' })"
					>
						<v-list-item-title>
							{{ $t('appHeader.regTextChangeLabel') }}
						</v-list-item-title>
					</v-list-item>

					<!-- <v-list-item
					v-if="servicePlan !== 'ESSENTIAL_K'"
					data-text="$t('appHeader.newsLabel')"
					@click="userMenuClick({ path: '/news' })"
				>
					<v-list-item-title>
						{{ $t('appHeader.newsLabel') }}
					</v-list-item-title>
				</v-list-item> -->

					<v-list-item
						:disabled="
							!$store.getters.userRoles.includes('CHAT_USER') ||
							!canUseRoutes.includes('CAN_SEE_CHAT')
						"
						@click="userMenuClick({ path: '/chat' })"
					>
						<v-list-item-title>
							{{ $t('appHeader.chatLabel') }}
						</v-list-item-title>
					</v-list-item>

					<v-divider></v-divider>
					<v-list-item
						v-for="(item, index) in userMenuitems"
						:key="index"
						:disabled="
							(item.allowedRoles &&
								!item.allowedRoles.some((r) => $store.getters.userRoles.includes(r))) ||
							(isChatUser($store.getters.userRoles) && item.routeName !== 'account')
						"
						:data-cy="
							item.routeName === 'account'
								? 'edit-account-button'
								: item.routeName === 'users'
								? 'users-button'
								: item.routeName === 'invites'
								? 'invites-button'
								: null
						"
						@click="userMenuClick(item)"
					>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item>
					<v-divider></v-divider>
					<v-list-item v-if="phoneScreenWidth" @click="userMenuClick({ routeName: 'support' })">
						<v-list-item-title>
							{{ $t('appHeader.supportDetailsLabel') }}
						</v-list-item-title>
					</v-list-item>
					<v-list-item
						:class="{ 'navbar-logout': phoneScreenWidth }"
						data-cy="logout-button"
						@click="userMenuClick({ routeName: 'home', logout: true })"
					>
						<v-list-item-title>
							{{ $t('appHeader.userMenuLogoutLabel') }}
						</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="phoneScreenWidth" class="navbar-copyright">
						<v-list-item-title class="navbar-copyright-title">
							{{ $t('appFooter.copyrightInformation') }}
						</v-list-item-title>
					</v-list-item>
				</div>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Tracker from '@/mixins/tracker'
import SharedMixin from '@/mixins/shared'

export default {
	name: 'AppHeader',
	directives: {
		focus: {
			// directive definition
			// Register custom directive called `v-focus`
			// Use it like so: <input v-focus='autoFocus'>
			inserted: function (el, binding) {
				let value = binding.value
				if (value) el.focus()
			},
		},
	},
	mixins: [Tracker, SharedMixin],
	data: function () {
		return {
			showRegTextChange: false,
			regTextLabel: 'Reg Text Changes',
			loadDataLabel: 'Load data',
			searchTerm: null, // the users search term is saved here
			userMenuitems: [], // will be inited on created
			tabletScreenWidth: window.innerWidth <= this.$store.state.tabletBreakpoint,
			phoneScreenWidth: window.innerWidth <= this.$store.state.phoneBreakpoint,
			isSearching: false, // is searching currently going on? For displaying the loader
			isOpened: false, // is language menu opened; serves just to focus the search bar again after language change
			drawer: false, // is the navigation drawer opened?
			// for detecting swipes
			xDown: null,
			yDown: null,
			toggleComplete: false, //static search help drop down
			hideAppHeader: false,
			isSearchFocused: false,
			// Link fo static search help drop down
			// for first line if in future there will be more add new key,
			// with incrementing 'link-{number}' and same key in translation
			listLinks_1: {
				'link-1': {
					name: 'LSFin opting out',
					link: `${window.location.href}search/${this.$store.state.selectedLanguage.languageCode}/${this.$store.state.selectedLanguage.languageCode}/LSFin opting out`,
				},
			},
			// for second line if in future there will be more add new key,
			// with incrementing 'link-{number}' and same key in translation
			listLinks_2: {
				'link-1': {
					name: 'Art. 4 LSFin',
				},
				'link-2': {
					name: '4 LSFin',
				},
				'link-3': {
					name: 'Cm 3 1803',
				},
				'link-4': {
					name: '3 1803',
				},
				'link-5': {
					name: 'LSFin',
				},
			},
			// for third line if in future there will be more add new key,
			// with incrementing 'link-{number}' and same key in translation
			listLinks_3: {
				'link-1': {
					link: '/topic/recherches-avancees/',
				},
			},
		}
	},
	computed: {
		...mapGetters(['servicePlan', 'canUseRoutes', 'clearSearch']), //comment just for Enrico: everytime servicePlan is updated in Vuex Store, it will return the update value

		searchExpandStyle() {
			// on focus sets negative margin-left and right to expand the search bar for the full width of the screen
			if (this.phoneScreenWidth && this.isSearchFocused) {
				return (
					'margin-left:' +
					-1 * this.$refs['logo-link'].$el.offsetWidth +
					'px' +
					';' +
					'margin-right:' +
					-1 *
						(window.innerWidth -
							this.$refs['header-hr'].offsetLeft +
							this.$refs['header-hr'].offsetWidth -
							this.$store.state.pdfPadding) +
					'px'
				)
			} else {
				return 'margin-left: initial; margin-right: initial'
			}
		},
		evenSmallerScreen() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return true
				case 'sm':
					return true
				default:
					return false
			}
		},
		localStringsKeys() {
			return Object.keys(this.$i18n.messages[this.$i18n.locale].search.staticHelpKey)
		},
		updateLayout() {
			return this.$store.state.updateLayoutProps.updateLayout
		},
		locationSlug() {
			return window.location.origin
		},
	},
	watch: {
		clearSearch() {
			this.searchTerm = ''
		},
		// watch for changes on parameters
		$route(to) {
			// when the $route parameter changes, react to route changes...
			// if the search term in the URL is not what we currently have in this component: update the component's searchTerm
			if (to.params.searchTerm && to.params.searchTerm !== this.searchTerm)
				this.searchTerm = to.params.searchTerm

			// if we are not on a tablet or a smaller screen (we are not on a touch device is what is important)
			// and we don't have something else on the screen specifically focused
			// (like the Owner autocomplete input on the changes page when the user clicks it directly on another, not in focus, change -> which updates the URL)
			// set focus on the search input
			if (!this.tabletScreenWidth && !this.$store.state.disableCustomKeyboardActions)
				this.$refs['search-text'].focus()
		},

		'$i18n.locale'() {
			// reinit data on locale change because it will then reevaluate translation strings
			this.initData()
		},

		isOpened(val) {
			if (!val && !this.tabletScreenWidth) this.$refs['search-text'].focus()
		},

		updateLayout() {
			// update tabletScreenWidth flag
			if (window.innerWidth <= this.$store.state.tabletBreakpoint) this.tabletScreenWidth = true
			else this.tabletScreenWidth = false

			// update phoneScreenWidth flag
			if (window.innerWidth <= this.$store.state.phoneBreakpoint) this.phoneScreenWidth = true
			else this.phoneScreenWidth = false
		},

		toggleComplete(val) {
			if (val) {
				this.decideAction('search_dropdown_opened')
			}
		},

		searchTerm(val) {
			this.$nextTick(() => {
				this.searchTerm = decodeURIComponent(val)
			})
		},
	},
	beforeCreate: function () {},
	created: function () {
		if (this.$route.params.searchTerm)
			this.searchTerm = this.$helpers.decodeURL(this.$route.params.searchTerm)
		this.initData()
		//Comment for Enrico: below we call the Vuex action getServicePlan becasue we need the service plan in AppHeader.vue
		this.getServicePlan({
			$http: this.$http,
		})
		// uncomment below two lines to enable hiding the header with mouse wheel
		// window.removeEventListener('wheel', this.onWheel)
		// window.addEventListener('wheel', this.onWheel)
		window.removeEventListener('touchstart', this.handleTouchStart, {
			passive: true,
		})
		document.addEventListener('touchstart', this.handleTouchStart, {
			passive: true,
		})
		window.removeEventListener('touchmove', this.handleTouchMove)
		document.addEventListener('touchmove', this.handleTouchMove, false)
	},
	beforeMount: function () {},
	mounted: function () {
		// set focus on the search input
		if (!this.tabletScreenWidth) this.$refs['search-text'].focus()
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	methods: {
		refreshSearch(str, fullReplace = false) {
			if (fullReplace) {
				this.searchTerm = str
			} else {
				this.searchTerm += ` ${str}`
			}

			this.initiateTheSearch()
		},
		onSearchInputClear() {
			this.searchTerm = ''

			this.decideAction('search_input_erased')
		},
		//instruction for Enrico
		//in method, dev calls the methods
		//in computed, the method is called when there is an update
		//by definition map action are in method
		//the below line map action from Vuex
		...mapActions(['getServicePlan']),
		getStaticKey(key) {
			const staticSearchKey = ' ' + key + ' '
			if (this.searchTerm) {
				this.searchTerm += staticSearchKey
			} else {
				this.searchTerm = staticSearchKey
			}
			this.toggleComplete = false
			this.$refs['search-text'].focus()
		},
		onWheel(e) {
			if (e.deltaY < 0) {
				// scrolling up
				if (!this.isScrollable(e)) this.hideHeader()
			} else if (e.deltaY > 0) {
				// scrolling down
				if (!this.isScrollable(e)) this.showHeader()
			}
		},
		getTouches(evt) {
			return evt.touches
		},
		handleTouchStart(evt) {
			const firstTouch = this.getTouches(evt)[0]
			this.xDown = firstTouch.clientX
			this.yDown = firstTouch.clientY
		},
		handleTouchMove(evt) {
			// source: https://stackoverflow.com/questions/2264072/detect-a-finger-swipe-through-javascript-on-the-iphone-and-android
			if (!this.xDown || !this.yDown) {
				return
			}

			let xUp = evt.touches[0].clientX
			let yUp = evt.touches[0].clientY

			let xDiff = this.xDown - xUp
			let yDiff = this.yDown - yUp

			if (Math.abs(xDiff) > Math.abs(yDiff)) {
				/* most significant */
				if (xDiff > 0) {
					/* left swipe */
				} else {
					/* right swipe */
				}
			} else {
				if (yDiff > 0) {
					/* up swipe */
					if (!this.isScrollable(evt)) this.hideHeader()
				} else {
					/* down swipe */
					if (!this.isScrollable(evt)) this.showHeader()
				}
			}

			/* reset values */
			this.xDown = null
			this.yDown = null
		},
		isScrollable(e) {
			let isScrollable = false // or the splitter
			let elem = e.target
			while (elem.nodeName !== 'HTML') {
				if (
					window.getComputedStyle(elem, null).getPropertyValue('overflow-y') === 'scroll' ||
					window.getComputedStyle(elem, null).getPropertyValue('overflow-y') === 'auto'
				) {
					isScrollable = true
					break
				}

				if (elem.className.indexOf('splitpanes__splitter') > -1) {
					isScrollable = true
					break
				}

				elem = elem.parentElement
			}
			return isScrollable
		},
		hideHeader() {
			this.hideAppHeader = true
		},
		showHeader() {
			this.hideAppHeader = false
		},
		langaugeClick: function (languageCode) {
			this.$store.dispatch('changeSelectedDDL', {
				$http: this.$http,
				languageCode: languageCode,
			})
		},
		makeAutoSearch(keyword) {
			let self = this

			self.searchTerm = keyword
			this.toggleComplete = false
			self.initiateTheSearch()
		},
		initiateTheSearch() {
			this.decideAction('search_initiated', { searchTerm: this.searchTerm })

			// initiate the search
			let self = this
			if (self.searchTerm) {
				// if the user actually searched for something procede with the normal work flow
				self.isSearching = true
				let shouldWeAppendRegTextId = false

				// there is one special case of search here, if we are on the Read page, if what he searched for is an article from the curretn ToC
				// append the current regTextId to the search which will just jump the Read pdf display to the new article
				if (
					(self.$route.name === 'readWithRegTextId' ||
						self.$route.name === 'readWithArticleNumber') &&
					self.$store.state.toc
				) {
					for (let i = 0; i < self.$store.state.toc.length; i++) {
						if (shouldWeAppendRegTextId) break

						if (!self.$store.state.toc[i].isTopic) {
							if (
								self.$store.state.toc[i].paragraphRegArt.completeParagraphId === self.searchTerm
							) {
								shouldWeAppendRegTextId = true
								break
							}
							for (let j = 0; j < self.$store.state.toc[i].subSections.length; j++) {
								if (
									self.$store.state.toc[i].subSections[j].completeParagraphId === self.searchTerm
								) {
									shouldWeAppendRegTextId = true
									break
								}
							}
						}
					}
				}

				// attach a regTextId if the search term is a number (34 or 34.2) or a number + a letter (34a or 34 a)
				if (
					(self.$route.name === 'readWithRegTextId' ||
						self.$route.name === 'readWithArticleNumber') &&
					!shouldWeAppendRegTextId
				) {
					let searchArray = self.searchTerm.split(' ')
					if (
						searchArray.length === 2 &&
						searchArray[1].length === 1 &&
						isNaN(searchArray[1]) &&
						!isNaN(searchArray[0])
					) {
						shouldWeAppendRegTextId = true
					} else if (
						searchArray.length === 1 &&
						(!isNaN(searchArray[0]) ||
							!isNaN(searchArray[0].toString().substring(0, searchArray[0].toString().length - 1)))
					) {
						shouldWeAppendRegTextId = true
					}
				}

				// now finally search
				// search both backends async
				let searches = []
				searches.push(self.startMainSearch(shouldWeAppendRegTextId))
				searches.push(self.startDomainSearch())

				Promise.all(searches).then(function ([mainResults, domainResults]) {
					self.isSearching = false

					// if either MAIN or DOMAIN search returned false, this means there was an error so completely abort the search
					if (!mainResults || !domainResults) return

					self.processSearchResults(mainResults, domainResults)
				})
				return true
			}
		},
		processSearchResults(mainResults, domainResults) {
			let self = this

			switch (mainResults.data.status) {
				case 0: {
					self.processSearchStatus0(mainResults)
					break
				}
				case 1: {
					self.processSearchStatus1(mainResults)
					break
				}
				case 2:
					// check if there were any results returned
					if (mainResults.data.results && mainResults.data.results.length) {
						// if they were, act like this is a case 3
						self.processSearchStatus3(mainResults, domainResults)
					} else {
						// only the regTextIds have been returned. E.g. search for: lsfin ofr 2 clients (this case should not happen but it does)
						// todo: this will evetually be fixed on the backend
						// act like case 0
						self.processSearchStatus0(mainResults)
					}
					break
				case 3:
					self.processSearchStatus3(mainResults, domainResults)
					break
				default:
					console.log('Unexpected "RegTextSearch" response from the server!')
					console.log(mainResults)
			}
		},
		processSearchStatus0(mainResults) {
			let self = this

			// get the first regText and redirect it to the read page
			let theFirstRegText = mainResults.data.regTexts[0]
			self.$router
				.push({
					name: 'readWithRegTextId',
					params: {
						regTextId: theFirstRegText.regTextId,
						messages: self.$helpers.unionOfArrays(mainResults.data.messages, mainResults.data.tips),
					},
				})
				.catch((error) => {
					console.log(error)
				})
		},
		processSearchStatus1(mainResults) {
			console.log('hello world')
			let self = this
			// get the first regArt and redirect it to the read page

			let theFirstRegArt = mainResults.data.regTexts[0]
			let articleNumber = theFirstRegArt.regArt.completeParagraphId

			self.$router
				.replace({
					name: 'readWithArticleNumber',
					params: {
						...self.$route.params,
						regTextId: theFirstRegArt.regTextId,
						articleNumber: articleNumber,
						messages: self.$helpers.unionOfArrays(mainResults.data.messages, mainResults.data.tips),
					},
					query: {
						...self.$route.query,
					},
				})
				.catch((error) => {
					console.log(error)
				})

			self.$store.commit('setState', {
				key: 'currentSearchedArticle',
				value: articleNumber,
			})
		},
		processSearchStatus3(mainResults, domainResults) {
			let self = this

			// this is a possible status (the only possible status) from the /DomainSearch as well so check the domainResults and send the updated data
			let finalSearchData = {
				numFoundMain: 0,
				numFoundDomain: 0,
				facetFields: [],
				rawSearchResults: [],
				queryLeftOver: '',
				queryLeftOverRegulation: '',
			}
			let finalTips = []
			let finalErrors = []
			let finalMessages = []

			//merge MAIN and DOMAIN data

			// merge facetFields
			finalSearchData.facetFields = mainResults.data.facetFields.concat(
				domainResults.data.facetFields
			)

			// merge results
			finalSearchData.rawSearchResults = mainResults.data.results.concat(domainResults.data.results)

			// set numFound-s
			finalSearchData.numFoundMain = mainResults.data.numFound
			finalSearchData.numFoundDomain = domainResults.data.numFound

			// check and set the query left over
			// queryLeftOver-s should be the same in both backends, but if they are not log an error but use the queryLeftOver from MAIN
			if (mainResults.data.queryLeftOver !== domainResults.data.queryLeftOver)
				console.log('queryLeftOver-s from both backends are not the same! will use the MAIN one.')
			finalSearchData.queryLeftOver = mainResults.data.queryLeftOver

			// set the queryLeftOverRegulation also
			finalSearchData.queryLeftOverRegulation = mainResults.data.queryLeftOverRegulation

			// merge error messages
			finalErrors = self.$helpers.unionOfArrays(
				mainResults.data.messages,
				domainResults.data.messages
			)

			// merge tips
			// and do a bit of processing here for 1 special tip. todo: ideally only until BE starts returning this one more required piece of data
			finalTips = self.$helpers.unionOfArrays(
				mainResults.data.tips.map((obj) =>
					obj.type === 'SEARCH_RESULT_EXIST_IN_OTHER_LANGUAGE'
						? {
								...obj,
								context: {
									...obj.context,
									requestLanguage: new URL(mainResults.config.url).searchParams.get(
										'searchLanguage'
									),
								},
						  }
						: obj
				),
				domainResults.data.tips
			)

			// merge tips and errors into messages and send it as that (easier to handle)
			finalMessages = self.$helpers.unionOfArrays(finalErrors, finalTips)

			// redirect to the search page and pass in the data and the original search query in the URL
			self.$router
				.push({
					name: 'searchWithTerm',
					params: {
						searchTerm: self.searchTerm,
						searchLanguage: self.$store.state.currentUser.documentDisplayLanguage,
						displayLanguage: self.$store.state.currentUser.documentDisplayLanguage,
						searchData: finalSearchData,
						messages: finalMessages,
					},
				})
				.catch((error) => {
					console.log(error)
				})
		},
		async startMainSearch(shouldWeAppendRegTextId = false) {
			let self = this
			const endpoint = `${self.$store.state.baseMainURL}/MainSearch`

			const requestParams = {
				query:
					self.searchTerm + (shouldWeAppendRegTextId ? ' ' + self.$route.params.regTextId : ''),
				searchLanguage: self.$store.state.currentUser.documentDisplayLanguage,
				displayLanguage: self.$store.state.currentUser.documentDisplayLanguage,
				start: 0,
				rows: self.$store.state.resultsPerSearch,
			}

			return self.$http
				.get(endpoint, {
					params: requestParams,
				})
				.then((result) => {
					if (result && !result.data.results.length) {
						this.decideAction('main_search_results_empty', {
							searchTerm: this.searchTerm,
						})
					}
					return result
				})
				.catch((error) => {
					console.log(error.response)
					return false
				})
		},
		async startDomainSearch() {
			let self = this

			const endpoint = `${self.$store.state.baseDomainURL}/DomainSearch`

			const requestParams = {
				query: self.searchTerm,
				start: 0,
				rows: self.$store.state.resultsPerSearch,
			}

			return self.$http
				.get(endpoint, {
					params: requestParams,
				})
				.then((result) => {
					return result
				})
				.catch((error) => {
					console.log(error.response)
					return false
				})
		},
		userMenuClick: function (item) {
			let self = this
			if (item.logout) {
				// logout and redirect
				self.$store.dispatch('logout', { $http: self.$http }).then(() => {
					self.$router.push({ name: item.routeName }).catch((error) => {
						console.log(error)
					})
				})
			} else if (item.routeName === 'account' && self.$route.name === 'account') {
				// if we are already on the account page editing some other user for example, and we want to jump directly to editing our own account
				// set the currently edited user and reload the component
				self.$route.params.editedUser = self.$store.state.currentUser // set the curently edited user
				self.$store.commit('reloadTheMainComponent') // reload the account component
			} else if (item.routeName === 'account') {
				// in case we are trying to edit our account from anywhere: redirect to the account page while passing in the current user
				self.$router.push({
					name: item.routeName,
					params: {
						editedUser: self.$store.state.currentUser,
						id: self.$store.state.currentUser.id,
					},
				})
			} else if (item.path) {
				// redirect to the path directly
				self.$router.push({ path: item.path }).catch((error) => {
					console.log(error)
				})
			} else {
				// just redirect
				self.$router.push({ name: item.routeName }).catch((error) => {
					console.log(error)
				})
			}
		},
		initData() {
			this.userMenuitems = [
				{
					title: this.$t('appHeader.userMenuEditAccountLabel'),
					routeName: 'account',
				},
				{
					title: this.$t('appHeader.userMenuUsersLabel'),
					routeName: 'users',
					allowedRoles: [this.$store.getters.getRole('administrator')],
				},
				{
					title: this.$t('appHeader.userMenuInvitesLabel'),
					routeName: 'invites',
					allowedRoles: [this.$store.getters.getRole('administrator')],
				},
			]
		},
	},
}
</script>

<style scoped lang="scss" src="./app-header.scss"></style>
