import store from '../services/vuex'

export default {
	state: () => ({
		isFetchingData: false,
		isUploading: false,
		filelist: [],
		metaData: [],
	}),

	mutations: {
		startLoading(state) {
			state.isFetchingData = true
		},

		stopLoading(state) {
			state.isFetchingData = false
		},

		setFiles(state, payload) {
			state.filelist = payload
		},

		setMetaData(state, payload) {
			state.metaData = payload
		},

		setIsUploading(state, payload) {
			state.isUploading = payload
		},
	},

	actions: {
		async loadPdfFiles({ commit }, file) {
			try {
				commit('startLoading')
				const endpoint = `${store.state.baseMainURL}/DirectDataLoading/LoadPDF`
				const { data } = await this._vm.$http.post(endpoint, file)
				return data
			} catch (e) {
				console.log(e)
			} finally {
				commit('stopLoading')
			}
		},

		async loadJsonCsvFiles({ commit }, file) {
			try {
				commit('startLoading')
				const endpoint = `${store.state.baseMainURL}/DirectDataLoading`
				const { data } = await this._vm.$http.post(endpoint, file)
				return data
			} catch (e) {
				console.log(e)
			} finally {
				commit('stopLoading')
			}
		},

		async getMetaData({ commit }) {
			try {
				commit('startLoading')
				const endpoint = `${store.state.baseMainURL}/DirectDataLoading/AllStoredFilesMetadata`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setMetaData', data)
			} catch (e) {
				console.log(e)
			} finally {
				commit('stopLoading')
			}
		},
	},

	getters: {
		isFetchingData: (state) => state.isFetchingData,
		isUploading: (state) => state.isUploading,
		filelist: (state) => state.filelist,
		metaData: (state) => state.metaData,
	},
}
