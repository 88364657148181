const table_payloads = (path, extraInformation) => {
	return {
		table_filter_opened: {
			event_name: 'table_filter_opened',
			event_description: `Table filter opened having a type of ${extraInformation?.type} on ${path}`,
			typeOfFilter: extraInformation?.type,
		},

		search_on_table: {
			event_name: 'search_on_table',
			event_description: `Searched "${extraInformation?.searched}" on ${path}`,
			searchTerm: decodeURIComponent(extraInformation?.searched),
		},

		filter_cleared: {
			event_name: 'table_filter_removed',
			event_description: `Table filter was removed on ${path}`,
		},

		area_column_modified: {
			event_name: 'area_column_modified',
			event_description: `Area column of the table was modified ${path}`,
		},

		key_column_modified: {
			event_name: 'key_column_modified',
			event_description: `Key column of the table was modified ${path}`,
		},

		volume_column_modified: {
			event_name: 'volume_column_modified',
			event_description: `Volume column of the table was modified ${path}`,
		},

		owner_column_modified: {
			event_name: 'owner_column_modified',
			event_description: `Owner column of the table was modified ${path}`,
		},

		status_column_modified: {
			event_name: 'status_column_modified',
			event_description: `Status column of the table was modified ${path}`,
		},

		comment_column_modified: {
			event_name: 'comment_column_modified',
			event_description: `Comment column of the table was modified ${path}`,
		},
	}
}

export default table_payloads
