<template>
	<v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.smAndDown ? '90%' : '50%'">
		<template v-slot:activator="{ on: dialogActivator }">
			<v-tooltip bottom :disabled="$store.state.disableCustomKeyboardActions">
				<template v-slot:activator="{ on: tooltip }">
					<img
						v-if="type === 'icon'"
						ref="ask-question-button"
						src="@/assets/images/q&a.png"
						class="action-icon"
						v-on="{ ...tooltip, ...dialogActivator }"
						@click="$helpers.resetDialogPosition()"
					/>

					<div
						v-else
						class="ask-text cursor-pointer mx-1"
						v-on="{ ...tooltip, ...dialogActivator }"
						@click="$helpers.resetDialogPosition()"
					>
						{{ $t('questionFeature.ask') }}
					</div>
				</template>
				<span>{{ $t('questionFeature.questionFeatureTooltip') }}</span>
			</v-tooltip>
		</template>
		<v-card>
			<v-card-title>
				<span class="headline">
					{{ $t('questionFeature.questionFeatureTitle') }}
				</span>
			</v-card-title>

			<v-card-text>
				<v-container>
					<v-form ref="question-feature-form" @submit.prevent>
						<v-row>
							<v-col cols="12" sm="12" md="12">
								<v-select
									v-model="selectedQuestionType"
									:items="questionTypes"
									:label="$t('questionFeature.questionTypeLabel')"
									required
									:rules="[(v) => !!v || $t('questionFeature.questionTypeRequiredMessage')]"
									single-line
								></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" sm="12" md="12">
								<span class="context-label">
									{{ $t('questionFeature.contextLabel') }}
								</span>
								<i18n path="questionFeature.questionContext" tag="span">
									<template v-slot:documentTitle>
										<b>{{ documentTitle }}</b>
									</template>
									<template v-slot:windowLocationHref>
										{{ windowLocationHref }}
									</template>
								</i18n>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" sm="12" md="12">
								<v-text-field
									v-model="userSubject"
									:label="$t('questionFeature.subjectLabel')"
									required
									:rules="[(v) => !!v || $t('questionFeature.subjectRequiredMessage')]"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" sm="12" md="12">
								<v-textarea
									ref="message-input"
									v-model="userMessage"
									:label="$t('questionFeature.messageLabel')"
									required
									:rules="[(v) => !!v || $t('questionFeature.messageRequiredMessage')]"
								></v-textarea>
							</v-col>
						</v-row>
					</v-form>
					<div v-if="currentErrorCode" class="error--text">
						{{ $t('questionFeature.' + currentErrorCode) }}
					</div>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="accent" text @click="close">
					{{ $t('questionFeature.cancel') }}
				</v-btn>
				<v-btn color="accent" text @click="send()">
					{{ $t('questionFeature.send') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'QuestionFeature',
	props: {
		type: {
			type: String,
			default: () => 'icon',
		},
	},
	data: function () {
		return {
			userMessage: null,
			dialog: false, // is the edit dialog opened?
			questionTypes: [],
			selectedQuestionType: null,
			documentTitle: document.title,
			windowLocationHref: window.location.href,
			userSubject: null,
			currentErrorCode: '',
			errorCodes: {
				genericEmailSendingError: 'genericEmailSendingError',
				serverEmailSendingError: 'serverEmailSendingError',
			},
		}
	},
	computed: {},
	watch: {
		dialog(val) {
			this.$store.commit('setDialogState', val)
			if (val) this.documentTitle = document.title
			this.windowLocationHref = window.location.href
			val || this.close()
		},
		'$i18n.locale'() {
			// reinit data on locale change because it will then reevaluate translation strings
			this.initData()
		},
	},
	beforeCreate: function () {},
	created: function () {
		this.initData()
	},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	methods: {
		close: function () {
			let self = this

			self.dialog = false
			self.$emit('closeSendMenu') // close the menu on the parent also
			setTimeout(() => {
				self.currentErrorCode = ''
				self.selectedQuestionType = null
				self.userSubject = null
				self.userMessage = null
				self.$refs['question-feature-form'].resetValidation()
			}, 300)
		},
		send() {
			let self = this

			self.currentErrorCode = ''

			if (self.$refs['question-feature-form'].validate()) {
				let payload = {
					type: self.selectedQuestionType,
					subject: self.userSubject,
					context: {
						title: self.documentTitle,
						url: self.windowLocationHref,
					},
					message: self.userMessage,
					language: self.$store.state.selectedLanguage.languageCode,
				}

				self.$http
					.post(self.$store.state.baseMainURL + '/domain/mail/question', payload)
					.then(() => {
						self.close()
					})
					.catch((error) => {
						console.log(error.response)
						if (error.response.status === 502) {
							self.currentErrorCode = self.errorCodes.serverEmailSendingError
						} else {
							self.currentErrorCode = self.errorCodes.genericEmailSendingError
						}
					})
			}
		},
		initData() {
			this.questionTypes = [
				this.$t('questionFeature.questionTypeQuestion'),
				this.$t('questionFeature.questionTypeSupport'),
				this.$t('questionFeature.questionTypeBugReport'),
			]
		},
	},
}
</script>

<style scoped lang="scss" src="./question.scss"></style>
