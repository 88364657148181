var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "footer",
      staticClass: "footer d-flex justify-space-between",
      class: [
        { "tablet-screen": _vm.tabletScreenSize },
        { "phone-screen": _vm.phoneScreenSize }
      ]
    },
    [
      _c("div", { staticClass: "d-flex" }, [
        !_vm.phoneScreenSize
          ? _c("div", [
              _vm._v(
                " " + _vm._s(_vm.$t("appFooter.copyrightInformation")) + " "
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "additional-disclaimer" }, [
          _vm._v(" " + _vm._s(_vm.$t("appFooter.additionalDisclaimer")) + " ")
        ]),
        _vm.mainBackendError
          ? _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("appFooter.connectToMainBackendError")) +
                  " "
              )
            ])
          : _vm._e(),
        _c("br"),
        _vm.domainBackendError
          ? _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("appFooter.connectToDomainBackendError")) +
                  " "
              )
            ])
          : _vm._e(),
        _vm.browserResized &&
        !_vm.$store.state.updateLayoutProps.autoUpdateLayoutOnBrowserResize
          ? _c("span", [
              _vm._v(
                " " + _vm._s(_vm.$t("appFooter.browserResizedMessage")) + " "
              )
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("question-component", {
            ref: "question-feature",
            staticClass: "mr-2",
            attrs: { type: "link" }
          }),
          !_vm.phoneScreenSize
            ? _c(
                "router-link",
                { staticClass: "support-details", attrs: { to: "/support" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("appFooter.supportDetailsLabel")) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }