import store from '../services/vuex'

export default {
	state: () => ({
		selectedRegArt: '',
		localData: {},
		regulatoryAssuranceData: {},
		owners: [],
		searchLoading: false,
		unSavedDialog: false,
	}),
	mutations: {
		setState(state, payload) {
			state[payload.key] = payload.value
		},
	},
	actions: {
		async getRegulatoryAssuranceData({ commit }, regArt) {
			try {
				// start loading here
				const endpoint = `${store.state.baseDomainURL}/RegulatoryAssurance/${regArt}`
				const { data } = await this._vm.$http.get(endpoint)
				if (data) {
					commit('setState', { key: 'regulatoryAssuranceData', value: data })
				}
			} catch (e) {
				if (e.response.data.status === 404) {
					commit('setState', { key: 'regulatoryAssuranceData', value: {} })
				}
			} finally {
				// stop loading here
			}
		},
		async saveRegulatoryAssuranceData({ commit, dispatch }, payload) {
			try {
				commit('setState', { key: 'searchLoading', value: true })
				const endpoint = `${store.state.baseDomainURL}/RegulatoryAssurance`
				const data = await this._vm.$http.post(endpoint, payload)
				console.log(data)
				dispatch('getRegulatoryAssuranceData', payload.regArt)
			} catch (e) {
				console.log(e)
			} finally {
				commit('setState', { key: 'searchLoading', value: false })
			}
		},
		async updateRegulatoryAssuranceData({ dispatch, commit }, payload) {
			try {
				commit('setState', { key: 'searchLoading', value: true })
				const endpoint = `${store.state.baseDomainURL}/RegulatoryAssurance/${payload.regArt}`
				const { data } = await this._vm.$http.put(endpoint, payload)
				console.log(data)
				dispatch('getRegulatoryAssuranceData', payload.regArt)
			} catch (e) {
				console.log(e)
			} finally {
				commit('setState', { key: 'searchLoading', value: false })
			}
		},
		async getOwners({ commit }) {
			try {
				// start loading here
				const endpoint = `${store.state.baseDomainURL}/users/names`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setState', { key: 'owners', value: data })
			} catch (e) {
				console.log(e)
			} finally {
				// stop loading here
			}
		},
		async sendNoResultMessage({ commit }, payload) {
			try {
				// start loading here
				console.log(commit)
				const endpoint = `${store.state.baseMainURL}/domain/mail/question`
				const { data } = await this._vm.$http.post(endpoint, payload)
				return data
			} catch (e) {
				console.log(e)
			} finally {
				// stop loading here
			}
		},
	},
	getters: {
		owners: (state) => state.owners,
		selectedRegArt: (state) => state.selectedRegArt,
		regulatoryAssuranceData: (state) => state.regulatoryAssuranceData,
		localData: (state) => state.localData,
		searchLoading: (state) => state.searchLoading,
		unSavedDialog: (state) => state.unSavedDialog,
	},
}
