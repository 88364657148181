<template>
	<Landing v-if="$store.getters.isLoggedIn && !$route.query.continue" />
	<Login v-else-if="!$store.getters.isLoggedIn" />
</template>

<script>
import Login from '@/views/LoginScreen/LoginScreen.vue'
import Landing from '@/views/LandingPage/LandingPage.vue'
export default {
	name: 'Home',
	components: { Login, Landing },
	mounted: function () {
		if (this.$route.name === 'indexAllEntities') {
			// temporary code that indexes all entities from MAIN
			// should be removed once something better like somekind of super admin dashboard is implemented
			this.$http
				.post(this.$store.state.baseMainURL + '/Index?entityType=All&language=FR')
				.then(() => {
					console.log('indexing succeeded')
				})
				.catch((error) => {
					console.log('indexing failed')
					console.log(error.response)
				})
		}
	},
}
</script>
