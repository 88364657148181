<template>
	<div
		ref="footer"
		class="footer d-flex justify-space-between"
		:class="[{ 'tablet-screen': tabletScreenSize }, { 'phone-screen': phoneScreenSize }]"
	>
		<div class="d-flex">
			<!--This should be a constant footer across all the SPA-->
			<div v-if="!phoneScreenSize">
				{{ $t('appFooter.copyrightInformation') }}
			</div>
			<div class="additional-disclaimer">
				{{ $t('appFooter.additionalDisclaimer') }}
			</div>
			<span v-if="mainBackendError">
				{{ $t('appFooter.connectToMainBackendError') }}
			</span>
			<br />
			<span v-if="domainBackendError">
				{{ $t('appFooter.connectToDomainBackendError') }}
			</span>
			<span
				v-if="browserResized && !$store.state.updateLayoutProps.autoUpdateLayoutOnBrowserResize"
			>
				{{ $t('appFooter.browserResizedMessage') }}
			</span>
		</div>

		<div class="d-flex align-center">
			<!-- New footer link -->
			<question-component ref="question-feature" type="link" class="mr-2" />

			<router-link v-if="!phoneScreenSize" to="/support" class="support-details">
				{{ $t('appFooter.supportDetailsLabel') }}
			</router-link>
		</div>
	</div>
</template>

<script>
import QuestionComponent from '@/components/emailing/QuestionComponent'

export default {
	name: 'AppFooter',
	components: {
		QuestionComponent,
	},
	data: function () {
		return {
			mainBackendError: false,
			domainBackendError: false,
			browserResized: false,
			width: window.outerWidth,
			tabletScreenSize: window.innerWidth <= this.$store.state.tabletBreakpoint,
			phoneScreenSize: window.innerWidth <= this.$store.state.phoneBreakpoint,
		}
	},
	computed: {
		baseDomainURL() {
			return this.$store.state.baseDomainURL
		},
		updateLayout() {
			return this.$store.state.updateLayoutProps.updateLayout
		},
	},
	watch: {
		baseDomainURL() {
			let self = this

			if (window.webpackHotUpdate) {
				// we are in the dev mode
				// re-check if we can connect to the DOMAIN backend
				console.log('Rechecking the connection to the DOMAIN server...')

				self.$http
					.head(self.$store.state.baseDomainURL)
					.then((headersResult) => {
						console.log(headersResult)
						self.domainBackendError = false
					})
					.catch((error) => {
						if (error.response) {
							// we connected to the server but didn't find anything on the base URL; this is expected
							self.domainBackendError = false
						} else {
							// axios couldn't connect to the server; we probably didn't start the docker containers
							self.domainBackendError = true
						}
					})
			}
		},
		updateLayout() {
			// update tabletScreenSize flag
			if (window.innerWidth <= this.$store.state.tabletBreakpoint) this.tabletScreenSize = true
			else this.tabletScreenSize = false

			// update phoneScreenSize flag
			if (window.innerWidth <= this.$store.state.phoneBreakpoint) this.phoneScreenSize = true
			else this.phoneScreenSize = false
		},
	},
	beforeCreate: function () {},
	created: function () {
		let self = this

		window.addEventListener('resize', this.onBrowserResize)

		if (window.webpackHotUpdate) {
			// we are in the dev mode
			// check if we can connect to the MAIN and the DOMAIN backends
			console.log('Trying to connect to the MAIN and DOMAIN servers...')

			self.$http
				.head(self.$store.state.baseMainURL)
				.then((headersResult) => {
					console.log(headersResult)
					self.mainBackendError = false
				})
				.catch((error) => {
					if (error.response) {
						// we connected to the server but didn't find anything on the base URL; this is expected
						self.mainBackendError = false
					} else {
						// axios couldn't connect to the server; we probably didn't start the docker containers
						self.mainBackendError = true
					}
				})

			self.$http
				.head(self.$store.state.baseDomainURL)
				.then((headersResult) => {
					console.log(headersResult)
					self.domainBackendError = false
				})
				.catch((error) => {
					if (error.response) {
						// we connected to the server but didn't find anything on the base URL; this is expected
						self.domainBackendError = false
					} else {
						// axios couldn't connect to the server; we probably didn't start the docker containers
						self.domainBackendError = true
					}
				})
		}
	},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {
		window.removeEventListener('resize', this.onBrowserResize)
	},
	methods: {
		onBrowserResize() {
			if (window.outerWidth !== this.width) {
				this.browserResized = true
				this.width = window.outerWidth
			}
		},
	},
}
</script>

<style scoped lang="scss" src="./app-footer.scss"></style>
