const keyboard_payloads = (path) => {
	return {
		keyboard_right_arrow: {
			event_name: 'keyboard_right_arrow_pressed',
			event_description: `Right arrow pressed on ${path}`,
		},

		keyboard_left_arrow: {
			event_name: 'keyboard_left_arrow_pressed',
			event_description: `Left arrow pressed on ${path}`,
		},

		table_settings_filter_opened: {
			event_name: 'table_settings_filter_opened',
			event_description: `Table setting filter opened ${path}`,
		},

		table_settings_filter_closed: {
			event_name: 'table_settings_filter_closed',
			event_description: `Table setting filter closed ${path}`,
		},
	}
}

export default keyboard_payloads
