const icon_payloads = (path) => {
	return {
		read_info_icon: {
			event_name: 'read_info_tab_click',
			event_description: `Clicked on read info tab on ${path}`,
		},

		download_pdf_icon: {
			event_name: 'download_pdf_click',
			event_description: `Clicked on download pdf on ${path}`,
		},

		read_reg_art_share: {
			event_name: 'read_reg_art_share_click',
			event_description: `Clicked on read reg art share icon on ${path}`,
		},

		height_icon_click: {
			event_name: 'height_icon_click',
			event_description: `Clicked on height icon on ${path}`,
		},

		search_reg_art_share: {
			event_name: 'search_reg_art_share_click',
			event_description: `Clicked on search reg art share icon on ${path}`,
		},

		read_prev_reg_art: {
			event_name: 'read_previous_reg_art_click',
			event_description: `Clicked on read previous reg art icon on ${path}`,
		},

		read_next_reg_art: {
			event_name: 'read_next_reg_art_click',
			event_description: `Clicked on read next reg art icon on ${path}`,
		},

		zoom_in: {
			event_name: 'zoom_in_click',
			event_description: `Clicked on zoom in button on ${path}`,
		},

		comment_icon: {
			event_name: 'comment_icon_click',
			event_description: `Clicked on comment icon on ${path}`,
		},

		sort_search: {
			event_name: 'sort_search_click',
			event_description: `Clicked on sort search results icon on ${path}`,
		},

		zoom_out: {
			event_name: 'zoom_out_click',
			event_description: `Clicked on zoom out button on ${path}`,
		},
	}
}

export default icon_payloads
