var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search-message content container-main my-1 mb-1 mx-0",
      class: { "tablet-screen": _vm.tabletScreenWidth },
      attrs: { id: "search-message" }
    },
    _vm._l(_vm.messages, function(message, messageIndex) {
      return _c(
        "div",
        { key: messageIndex, staticClass: "container-main" },
        [
          message && message.type === "SEARCH_TERM_HAS_NOT_BEEN_FOUND"
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "general.searchNotFound",
                          message.context["removed search terms"].length || 1,
                          {
                            words: message.context["removed search terms"].join(
                              ", "
                            ),
                            returned: message.context["returning result for"]
                          }
                        )
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          message &&
          message.type === "REG_TEXT_ID_NOT_FOUND" &&
          message.context.documentSearchType === "FINMA"
            ? _c("div", { staticClass: "message-inner search-error" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("searchError.noRegTextIdFoundFinma", {
                        documentSearchTerm: message.context.documentSearchTerm
                      })
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          message &&
          message.type === "REG_TEXT_ID_NOT_FOUND" &&
          message.context.documentSearchType !== "FINMA" &&
          message.context.documentSearchType !== "GENERIC_KNOWN"
            ? _c("div", { staticClass: "message-inner search-error" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("searchError.noRegTextIdFound", {
                        documentSearchTerm:
                          message.context.documentSearchTerm.searchTerm
                      })
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          message &&
          message.type === "REG_TEXT_ID_NOT_FOUND" &&
          message.context.documentSearchType === "GENERIC_KNOWN" &&
          message.context.documentSearchType !== "FINMA"
            ? _c("div", { staticClass: "message-inner search-error" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("searchError.noRegTextIdFound", {
                        documentSearchTerm: message.context.documentSearchTerm
                      })
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          message && message.type === "REG_ART_NOT_FOUND"
            ? _c("div", { staticClass: "message-inner search-error" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("searchError.regArtNotFound", {
                        regTextDisplayName: message.context.regTextDisplayName
                      })
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          message && message.type === "ERROR_IN_TAG_USE" && message.context.tag
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc("message.messages.errorInTagUse", 1, {
                          unknownTags: message.context.tag
                        })
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          message &&
          message.type === "ERROR_IN_TAG_USE" &&
          message.context.unknownTags
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "message.messages.errorInTagUse",
                          message.context.unknownTags.length,
                          {
                            unknownTags: message.context.unknownTags.join(", ")
                          }
                        )
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          message && message.type === "SHOWING_PARAGRAPH_BEFORE"
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("message.messages.showingParagraphBefore", {
                          searchedParagraphId:
                            message.context.searchedParagraphId,
                          regTextDisplayName: message.context.regTextDisplayName
                        })
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          message &&
          message.type === "DISPLAY_NAME_PROVIDED_IN_ANOTHER_LANGUAGE"
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "message.messages.displayNameProvidedInAnotherLanguage",
                          {
                            shownDisplayName: message.context.shownDisplayName,
                            shownLanguage: _vm.$t(
                              "message." +
                                _vm.$store.getters.getLanguageTranslationHook(
                                  message.context.shownLanguage
                                )
                            ),
                            searchedDisplayName:
                              message.context.searchedDisplayName,
                            searchedLanguage: _vm.$t(
                              "message." +
                                _vm.$store.getters.getLanguageTranslationHook(
                                  message.context.searchedLanguage
                                )
                            )
                          }
                        )
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          message && message.type === "SIX_IN_FRENCH_SEARCH_TOOLTIP"
            ? _c(
                "i18n",
                {
                  staticClass: "message-inner actual-search-message",
                  attrs: {
                    path: "message.messages.sixInFrenchSearchTooltip.tooltip",
                    tag: "div",
                    for: "message.messages.sixInFrenchSearchTooltip.link"
                  }
                },
                [
                  _c(
                    "router-link",
                    { attrs: { id: "link", to: "/search/DE/DE/six" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "message.messages.sixInFrenchSearchTooltip.link"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          message && message.type === "LERA_DISAMBIGUATION"
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("message.messages.leraDisambiguation")) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          message && message.type === "SEARCH_SET_EXPANDED"
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("message.messages.searchSetExpanded", {
                          searchTerms: message.context.searchTerms
                        })
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          message && message.type === "MODIFIED_SEARCH_SET_EXPANDED"
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("message.messages.modifiedSearchSetExpanded", {
                          searchTerms: message.context.searchTerms,
                          searchSetUsed: message.context.modifiersUsed.join(
                            ", "
                          )
                        })
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          message && message.type === "EXISTS_IN_DIFFERENT_LANGUAGE"
            ? _c(
                "div",
                {
                  staticClass: "message-inner actual-search-message",
                  attrs: { "data-cy": "different-version" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("message.messages.existsInDifferentLanguage", {
                          documentSearchTerm: message.context.knownDisplayName,
                          searchedLanguage: _vm.$t(
                            "message." +
                              _vm.$store.getters.getLanguageTranslationHook(
                                message.context.searchedLanguage
                              )
                          )
                        })
                      ) +
                      " "
                  ),
                  _vm._l(message.context.existingLanguages, function(
                    lang,
                    index
                  ) {
                    return _c(
                      "span",
                      { key: lang },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { id: "link", to: "/" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.changeLanguageAndRedirect(
                                  lang,
                                  message.context.regTextId
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "message." +
                                      _vm.$store.getters.getLanguageTranslationHook(
                                        lang
                                      )
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        index !== message.context.existingLanguages.length - 1
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("message.enumerationSeparatorInText")
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          message && message.type === "APPENDIX_NOT_FOUND"
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("message.messages.appendixNotFound", {
                          searchedAppendix: message.context.searchedAppendix
                        })
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          message && message.type === "DOCUMENT_FOUND_IN_ONE_OTHER_LANGUAGE"
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "message.messages.documentFoundInOneOtherLanguage",
                          {
                            availableLanguge: _vm.$t(
                              "message." +
                                _vm.$store.getters.getLanguageTranslationHook(
                                  message.context.availableLanguge
                                )
                            ),
                            oldDisplayLanguge: _vm.$t(
                              "message." +
                                _vm.$store.getters.getLanguageTranslationHook(
                                  message.context.oldDisplayLanguge
                                )
                            )
                          }
                        )
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          message && message.type === "CUSTOM_MESSAGE"
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  message.context.link
                    ? _c("a", {
                        attrs: {
                          id: "warning-message",
                          rel: "noopener noreferrer",
                          href: message.context.link,
                          target: "_blank"
                        },
                        domProps: {
                          innerHTML: _vm._s(
                            message.context.customMessageLabel[
                              _vm.$store.getters.getLanguageBackendCode(
                                _vm.$i18n.locale
                              )
                                ? _vm.$store.getters.getLanguageBackendCode(
                                    _vm.$i18n.locale
                                  )
                                : "EN"
                            ]
                          )
                        }
                      })
                    : _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            message.context.customMessageLabel[
                              _vm.$store.getters.getLanguageBackendCode(
                                _vm.$i18n.locale
                              )
                                ? _vm.$store.getters.getLanguageBackendCode(
                                    _vm.$i18n.locale
                                  )
                                : "EN"
                            ]
                          )
                        }
                      })
                ]
              )
            : _vm._e(),
          message && message.type === "CHANGE_PLANNED"
            ? _c(
                "i18n",
                {
                  staticClass: "message-inner actual-search-message",
                  attrs: {
                    path: "message.messages.changePlanned.message",
                    tag: "div",
                    for: "message.messages.changePlanned.link"
                  }
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        id: "warning-message",
                        rel: "noopener noreferrer",
                        href: message.context.link,
                        target: "_blank"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("message.messages.changePlanned.link")
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          message && message.type === "SEARCH_RESULT_EXIST_IN_OTHER_LANGUAGE"
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "message.messages.searchResultExistInOtherLanguage",
                          {
                            searchTerm: message.context.searchTerms,
                            requestLanguage: _vm.$t(
                              "message." +
                                _vm.$store.getters.getLanguageTranslationHook(
                                  message.context.requestLanguage
                                )
                            ),
                            searchedLanguage: _vm.$t(
                              "message." +
                                _vm.$store.getters.getLanguageTranslationHook(
                                  message.context.searchedLanguage
                                )
                            )
                          }
                        )
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          message && message.type === "OLD_VERSIONDATE_TIP"
            ? _c("i18n", {
                staticClass: "message-inner actual-search-message",
                attrs: {
                  tag: "div",
                  "data-cy": "old-versionDate",
                  path: "message.tips.oldVersionDate"
                },
                on: {
                  click: function($event) {
                    return _vm.handleVersionDateClick(message)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "XYZ",
                      fn: function() {
                        return [_vm._v(_vm._s(message.context.displayName))]
                      },
                      proxy: true
                    },
                    {
                      key: "activeVersion",
                      fn: function() {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(message.context.requestedVersionDate) +
                              " "
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "currentVersion",
                      fn: function() {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(message.context.currentVersionDate) +
                              " "
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "link",
                      fn: function() {
                        return [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                id: "link",
                                to: {
                                  name: "readWithArticleNumber",
                                  params: { versionDate: "current" }
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(message.context.currentVersionDate) +
                                  " "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e(),
          message && message.type === "FUTURE_VERSIONDATE_TIP"
            ? _c("i18n", {
                staticClass: "message-inner actual-search-message",
                attrs: { tag: "div", path: "message.tips.futureVersionDate" },
                on: {
                  click: function($event) {
                    return _vm.handleVersionDateClick(message)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "XYZ",
                      fn: function() {
                        return [_vm._v(_vm._s(message.context.displayName))]
                      },
                      proxy: true
                    },
                    {
                      key: "activeVersion",
                      fn: function() {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(message.context.requestedVersionDate) +
                              " "
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "currentVersion",
                      fn: function() {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(message.context.currentVersionDate) +
                              " "
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "link",
                      fn: function() {
                        return [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                id: "link",
                                to: {
                                  name: "readWithRegTextId"
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(message.context.currentVersionDate) +
                                  " "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e(),
          message &&
          message.type === "ARCHIVE_STATUS" &&
          message.context.archiveStatus === "FINMA_FAQ_OUTDATED"
            ? _c(
                "div",
                { staticClass: "message-inner actual-search-message" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("message.messages.finmaFaqOutdated")) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          message &&
          message.type === "ARCHIVE_STATUS" &&
          message.context.archiveStatus === "NON_VALID_REPLACED_BY"
            ? _c("i18n", {
                staticClass: "message-inner actual-search-message",
                attrs: {
                  path: "message.messages.nonValidReplacedBy",
                  tag: "div"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "oldRegTextName",
                      fn: function() {
                        return [
                          _vm._v(
                            " " + _vm._s(message.context.oldRegTextName) + " "
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "newRegTextName",
                      fn: function() {
                        return [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                id: "link",
                                to: {
                                  name: "readWithRegTextId",
                                  params: {
                                    regTextId: message.context.newRegTextId
                                  }
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(message.context.newRegTextName) +
                                  " "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e(),
          message &&
          message.type === "ARCHIVE_STATUS" &&
          message.context.archiveStatus === "FINMA_C0810_OLD_MESSAGE"
            ? _c(
                "i18n",
                {
                  staticClass: "message-inner actual-search-message",
                  attrs: {
                    path: "message.messages.finmaC0810OldMessage.text",
                    tag: "div",
                    for: "message.messages.finmaC0810OldMessage.link"
                  }
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        id: "warning-message",
                        rel: "noopener noreferrer",
                        href: message.context.link,
                        target: "_blank"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("message.messages.finmaC0810OldMessage.link")
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }