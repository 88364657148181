<template>
	<v-main data-cy="login-page">
		<v-container class="fill-height" fluid>
			<v-row align="center" justify="center">
				<v-col>
					<v-row justify="center">
						<v-col cols="12" sm="8" md="4" class="the-login-class">
							<v-card class="elevation-12">
								<v-card-text class="pb-0">
									<v-form ref="form" v-model="formValid" lazy-validation>
										<h2>{{ $t('login.loginMessage') }}</h2>

										<div>{{ $t('login.emailLabel') }}</div>
										<v-text-field
											v-model="email"
											name="email"
											prepend-inner-icon="mail"
											type="text"
											autocomplete="email"
											:rules="[(v) => v.length > 0 || '', !incorrectCredentials || '']"
											outlined
											dense
											data-cy="email"
											@keyup.enter="login"
										/>

										<div>{{ $t('login.passwordLabel') }}</div>
										<v-text-field
											id="password"
											v-model="password"
											name="password"
											prepend-inner-icon="lock"
											type="password"
											dense
											autocomplete="current-password"
											:rules="[(v) => v.length > 0 || '', !incorrectCredentials || '']"
											outlined
											data-cy="password"
											@keyup.enter="login"
										/>

										<div v-if="incorrectCredentials || !formValid" class="error--text">
											{{ $t('login.genericError') }}
										</div>
										<div v-if="temporarilyUnavailable" class="error--text">
											{{ $t('login.temporarilyUnavailableError') }}
										</div>
									</v-form>
								</v-card-text>
								<div class="px-6 pb-4 d-flex justify-end">
									<router-link :to="{ name: 'PasswordResetRequest' }">
										{{ $t('login.forgotPassword') }}
									</router-link>
								</div>
								<v-card-actions>
									<v-btn color="primary" depressed data-cy="login-btn" @click="login">
										{{ $t('login.loginButton') }}
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-col>
					</v-row>
					<v-row justify="center">
						<div v-if="$helpers.detectUnsupportedBrowser()" class="unsupported-browser-error">
							{{ $t('general.detectedInternetBrowserMessage') }}
						</div>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</v-main>
</template>

<script>
import shared from '../../mixins/shared'

export default {
	name: 'Login',
	mixins: [shared],
	data: function () {
		return {
			temporarilyUnavailable: false,
			email: '',
			password: '',
			formValid: true,
			incorrectCredentials: false, // custom incorrect credentials; if the login attempt returns any errors
		}
	},
	computed: {},
	beforeCreate: function () {},
	created: function () {
		document.title = this.$store.state.baseAppTitle
	},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	methods: {
		login: function () {
			let self = this

			self.incorrectCredentials = false
			self.temporarilyUnavailable = false

			self.$nextTick(function () {
				if (self.$refs.form.validate()) {
					self.$store
						.dispatch('login', {
							user: { email: self.email, password: self.password },
							$http: self.$http,
						})
						.then(() => {
							if (this.isChatUser(this.$store.getters.userRoles)) {
								return this.$router.push({ path: '/chat' })
							}

							if (self.$route.query.continue) self.$router.push(self.$route.query.continue)

							/* Doesnt change often but can be cached for instant results */
							this.$store.dispatch('getRegTextChangesMain')
							/* This is used to cache the api results but can change often */
							this.$store.dispatch('getRegTextChangesDomain')
							this.$store.dispatch('getChangeRoles')
							this.$store.dispatch('getCanUseRoutes')
						})
						.catch((err) => {
							console.log(err)
							if (!err.response || err.response.status >= 500) self.temporarilyUnavailable = true
							else self.incorrectCredentials = true
						})
				}
			})
		},
	},
}
</script>

<style scoped lang="scss" src="./login.scss"></style>
