const events_payloads = (path, extraInformation, nodeText) => {
	return {
		route_change: {
			event_name: 'route_change',
			event_description: `Active route is ${path}`,
		},

		user_deleted: {
			event_name: 'user_deleted',
			event_description: `A User was deleted with an id of ${extraInformation?.id} and name of ${extraInformation?.fullName} on ${path}`,
			userId: extraInformation?.id,
			name: extraInformation?.fullName || extraInformation?.name,
			email: extraInformation?.email,
		},

		note_saved: {
			event_name: 'note_saved',
			event_description: `Saved a note on ${path}`,
		},

		'warning-message': {
			event_description: `Clicked on the warning message on ${path} and warning text was ${nodeText}`,
			event_name: `warning_message_click`,
			warningText: nodeText?.replaceAll(' ', '_'),
		},

		'search-input': {
			event_name: `search_input_click`,
			event_description: `Clicked on the search input on ${path}`,
		},

		search_initiated: {
			event_name: `search_initiated`,
			event_description: `Search initiated as ${extraInformation?.searchTerm} on ${path}`,
			searchTerm: extraInformation?.searchTerm,
		},

		main_search_results_empty: {
			event_name: `no_main_search_results_returned`,
			event_description: `Search returned nothing initiated as ${extraInformation?.searchTerm} on ${path}`,
			searchTerm: extraInformation?.searchTerm,
		},

		'related-files-tab': {
			event_name: `related_files_tab_click`,
			event_description: `Clicked on the related files tab on ${path}`,
		},

		'notes-tab': {
			event_name: `notes-tab-click`,
			event_description: `Clicked on the notes tab on ${path}`,
		},

		search_input_erased: {
			event_name: `search_input_erased`,
			event_description: 'User clicked  on the clear icon of search input',
		},

		search_dropdown_opened: {
			event_name: `search_dropdown_opened`,
			event_description: 'User clicked on the search dropdown and opened it',
		},

		'comments-tab': {
			event_name: `comments_tab_click`,
			event_description: `Clicked on the comments tab on ${path}`,
		},

		'export-as-word': {
			event_name: `export_as_word_file_click`,
			event_description: `Clicked on the export as word file button in summary on ${path}`,
		},

		add_comment_button: {
			event_name: 'add_comment_button_click',
			event_description: `Clicked on add comment button on ${path}`,
		},

		export_as_excel: {
			event_name: `export_as_excel`,
			event_description: `User clicked on the export as excel on ${path}`,
		},

		add_to_bookmark: {
			event_name: `add_to_bookmark`,
			event_description: 'User clicked on the bookmark',
		},

		add_notification: {
			event_name: `add_notification`,
			event_description: 'User clicked on the add notification',
		},

		comment_dropdown_hamburger: {
			event_name: `comment_dropdown_hamburger`,
			event_description: 'User clicked on the comment dropdown hamburger',
		},

		hamburger_comment_edit: {
			event_name: `hamburger_comment_edit`,
			event_description: 'User clicked on the comment edit in hamburger',
		},

		hamburger_comment_delete: {
			event_name: `hamburger_comment_delete`,
			event_description: 'User clicked on the comment delete in hamburger',
		},

		hamburger_comment_resolve: {
			event_name: `hamburger_comment_resolve`,
			event_description: 'User clicked on the comment resolve in hamburger',
		},

		hamburger_comment_share: {
			event_name: `hamburger_comment_share`,
			event_description: 'User clicked on the comment share in hamburger',
		},

		open_source_same_tab: {
			event_name: `open_source_same_tab_clicked`,
			event_description: 'User clicked on the open source in same tab document icon',
		},

		open_source_new_tab: {
			event_name: `open_source_new_tab_clicked`,
			event_description: 'User clicked on the open source in new tab document icon',
		},

		language_change: {
			event_name: `language_change_${extraInformation?.lang}`,
			event_description: `Language was changed to ${extraInformation?.lang}`,
		},

		reset_password_clicked: {
			event_name: `reset_password_clicked`,
			event_description: `Reset password was clicked`,
		},

		remove_account_clicked: {
			event_name: `remove_account_clicked`,
			event_description: `Remove account clicked`,
		},

		notification_checkbox_changed: {
			event_name: `notification_checkbox_changed`,
			event_description: `Notification checkbox changed`,
		},

		roles_checkbox_changed: {
			event_name: `roles_checkbox_changed`,
			event_description: `Roles checkbox changed`,
		},

		facet_back_button_click: {
			event_name: `facet_back_button_click`,
			event_description: `Facet back button was clicked`,
		},
	}
}

export default events_payloads
