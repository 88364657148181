var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "full-height d-flex justify-center align-center" },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _vm.step === 1
                ? _c(
                    "v-card",
                    { attrs: { elevation: "10" } },
                    [
                      _c("v-card-title", { staticClass: "font-weight-bold" }, [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.$t("loginByEmail.cardTitle")))
                        ])
                      ]),
                      _c("v-card-subtitle", { staticClass: "my-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("loginByEmail.cardSubtitle")) +
                            " "
                        )
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "email",
                                  "prepend-inner-icon": "mail",
                                  outlined: "",
                                  required: "",
                                  placeholder: _vm.$t(
                                    "loginByEmail.emailAddress"
                                  ),
                                  rules: [
                                    function(v) {
                                      return !!v || "Required"
                                    }
                                  ]
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  class: [
                                    _vm.showMessage
                                      ? "justify-space-between"
                                      : "justify-end",
                                    "d-flex align-center"
                                  ]
                                },
                                [
                                  _vm.showMessage
                                    ? _c(
                                        "div",
                                        { staticClass: "primary--text" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.message) + " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-6",
                                      attrs: {
                                        color: "primary",
                                        depressed: "",
                                        "data-cy": "login-btn",
                                        loading: _vm.isLoading,
                                        disabled: !_vm.valid
                                      },
                                      on: { click: _vm.sendLink }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("loginByEmail.btnLabel")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 2
                ? _c(
                    "v-card",
                    { attrs: { flat: "", height: "300px" } },
                    [
                      !_vm.errors.length
                        ? _c(
                            "v-card-text",
                            { staticClass: "card-height" },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pt-13" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-center",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("v-progress-circular", {
                                        attrs: {
                                          color: "primary",
                                          indeterminate: "",
                                          size: "60",
                                          width: "3"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-center",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("div", { staticClass: "mt-4" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("loginByEmail.loadingText")
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                { staticClass: "pt-13" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-center",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { size: "80", color: "red" } },
                                        [_vm._v("error")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.errors, function(item, index) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: index,
                                        staticClass:
                                          "red--text d-flex justify-center text-center",
                                        attrs: { cols: "12" }
                                      },
                                      [_c("small", [_vm._v(_vm._s(item))])]
                                    )
                                  }),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-center",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            small: "",
                                            depressed: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$router.push({
                                                path: "/loginByEmail"
                                              })
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.tryAgain) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }