import store from '../services/vuex'
import helpers from '../services/helpers'

export default {
	namespaced: true,

	state: () => ({
		isFetchingContextData: true,
		inboundData: [],
		outboundData: [],
		selectedContextItem: null,
		closestItems: [],
	}),

	mutations: {
		startContextLoading(state) {
			state.isFetchingContextData = true
		},

		stopContextLoading(state) {
			state.isFetchingContextData = false
		},

		setContextData(state, payload) {
			let outbound = []
			let inbound = []

			payload.forEach((el) => {
				if (el.referenceType === 'OUTBOUND') outbound.push(el)
				if (el.referenceType === 'INBOUND') inbound.push(el)
			})

			state.inboundData = helpers.groupByKey(inbound, 'targetDisplayName')
			state.outboundData = helpers.groupByKey(outbound, 'targetDisplayName')
		},

		setClosestRegulation(state, payload) {
			state.closestItems = payload
		},

		setSelectedContextItem(state, payload) {
			state.selectedContextItem = payload
		},

		resetContext(state) {
			state.inboundData = []
			state.outboundData = []
			state.selectedContextItem = null
		},
	},

	actions: {
		async getContextData({ commit }, payload) {
			if (!payload.regulationId || !payload.completeParagraphId) return

			try {
				const endpoint = `${store.state.baseMainURL}/ComplInfo/ContextV2/${payload.regulationId}/${payload.completeParagraphId}`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setContextData', data)
				return data
			} catch (e) {
				console.log(e)
			} finally {
				commit('stopContextLoading')
			}
		},

		async getClosestRegulation({ commit }, payload) {
			if (!payload.regulationId || !payload.completeParagraphId) return

			try {
				commit('startContextLoading')
				commit('setClosestRegulation', [])
				const endpoint = `${store.state.baseMainURL}/LLM/similarity/${payload.regulationId}/${payload.completeParagraphId}`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setClosestRegulation', data)
				return data
			} catch (e) {
				console.log(e)
			} finally {
				commit('stopContextLoading')
			}
		},
	},

	getters: {
		isFetchingContextData: (state) => state.isFetchingContextData,
		inboundData: (state) => state.inboundData,
		outboundData: (state) => state.outboundData,
		selectedContextItem: (state) => state.selectedContextItem,
		closestItems: (state) => state.closestItems,
	},
}
