export default class RegArtFormatter {
	/**
	 * @var {Map<string, string>} This formatters dictionary.
	 * @private
	 */
	_dictionary

	/**
	 * Constructor.
	 * @param {Map<string, string>} dictionary
	 */
	constructor(dictionary) {
		this._dictionary = dictionary
	}

	/**
	 * @type {RegExp} Regular expression for parsing RegArt parts.
	 * @private
	 */
	// This is the default case REGEX
	static PARSE_REGEX = /^.*?~(\{(?<annex>A\|?([0-9]+)?)\})?(?<subsections>.+)$/

	// This REGEX is used only when children naming is ['INTRODUCTION', 'WHEREAS', 'PREAMBLE']
	static PARSE_REGEX_SUB_CASE_1 = /^.*?~(\{(?<section>(.+)?)\})?(?<subsections>.+)$/

	static PARSE_REGEX_SECTION = /^.*?~{(?<annex>[A-Za-z][0-9A-Za-z]*)\}(?<subsections>.+)$/
	/**
	 * Formats paragraph/section/subsection/etc. by prepending translated naming.
	 * @param {string} value paragraph/section/subsection/etc.
	 * @param {number} position paragraph's/section's/subsection's/etc. position in RegArt.
	 * @param {string} naming Name of paragraph/section/subsection/etc.
	 * @return {string} Formatted paragraph/section/subsection/etc.
	 * @private
	 */
	_formatSubsection(value, position, naming) {
		if (typeof naming !== 'undefined') {
			if (this._dictionary.has(naming)) {
				return `${this._dictionary.get(naming)} ${value}`
			} else {
				throw new Error(`No dictionary entry for ${naming} defined`)
			}
		}
		throw new Error(`Naming is not defined for position #${position}`)
	}

	/**
	 * Formats annex
	 * @param {string} annex Annex/appendix.
	 * @return {string} Formatted.
	 * @private
	 */
	_formatAnnex(annex) {
		// Initialize an empty string for the formatted annex
		let formattedAnnex = ''

		// Check if the annex parameter is provided and not empty
		if (annex) {
			// Retrieve the word 'ANNEX' from the dictionary
			const annexLabel = this._dictionary.get('ANNEX')

			// Check if the annex contains any digits
			const containsDigits = /[0-9]/.test(annex)

			// If it contains digits, extract the first sequence of digits
			let annexNumber = ''

			if (containsDigits) {
				const match = annex.match(/([0-9]+[a-zA-Z]*[0-9]*)/)
				annexNumber = match ? ` ${match[0]}` : ''
			}

			// Construct the formatted annex string
			formattedAnnex = ` ${annexLabel}${annexNumber}`
		}

		return formattedAnnex?.toLowerCase()
	}

	/**
	 * Parse subsections from the RegArt.
	 * @param {string} regArtData RegArt.
	 * @param {string[]} childrenNaming childrenNaming.
	 * @return {string[]} Matched parts.
	 */
	static parseRegArtPath(regArtData, childrenNaming) {
		let chosenRegex = RegArtFormatter.PARSE_REGEX

		// SUB CASE 1
		let SUB_CASE_1 = ['INTRODUCTION', 'WHEREAS', 'PREAMBLE']
		if (childrenNaming && childrenNaming.some((el) => SUB_CASE_1.includes(el))) {
			chosenRegex = RegArtFormatter.PARSE_REGEX_SUB_CASE_1
		}

		// SPECIAL SECTION CASE WHERE WE HAVE / instead of ~
		if (childrenNaming && childrenNaming.includes('SECTION') && regArtData.includes('~{')) {
			chosenRegex = RegArtFormatter.PARSE_REGEX_SECTION
		}

		const match = regArtData.match(chosenRegex).groups
		if (match.subsections) match.subsections = match.subsections.split('^')
		return match
	}

	/**
	 * Make human-readable representation of a RegArt using children naming scheme and display name of its RegText.
	 * @param {string} regArt RegArt to display.
	 * @param {string} regText Parent RegText of the RegArt.
	 * @param {string[]} childrenNaming Naming scheme for RegArt's sections and subsections.
	 *@return {string} Human-friendly representation of a RegArt.
	 */
	makeDisplayName(regArt, regText, childrenNaming) {
		// SUB CASE 1B [TOC BASED]
		let SUB_CASE_1B = ['TOCBased']
		if (childrenNaming && childrenNaming.some((el) => SUB_CASE_1B.includes(el))) {
			const toProcess = regArt.split('~')[1]
			const [end, start] = toProcess.replaceAll('p', ' §').split(' ')
			const displayName = `${start}${end && start ? ' in chap. ' : ''}${end} ${regText}`
			return displayName
		}

		// SUB CASE 3 {XX_XX}<int>
		const subCase3Pattern = /\{([^{}]*_[^{}]*)\}\d+/
		const isSubCase3 = subCase3Pattern.test(regArt)

		if (isSubCase3 && regArt.includes('~{')) {
			const toProcess = regArt.split('~')[1]
			const [end, start] = toProcess.replaceAll('{', '').replaceAll('}', ' ').split(' ')
			const displayName = `§${start}, ${end.replaceAll('_', ' ')}, ${regText}`
			return displayName
		}

		// NORMAL
		const { annex, subsections } = RegArtFormatter.parseRegArtPath(regArt, childrenNaming)

		return `${subsections
			.map((value, index) => {
				if (childrenNaming) {
					return this._formatSubsection(value, index, childrenNaming[index])
				}
			})
			.join(' ')}${this._formatAnnex(annex)} ${regText}`
	}

	/**
	 * Make human-readable representation of a RegArt using children naming scheme
	 * @param {string} regArt RegArt to display.
	 * @param {string[]} childrenNaming Naming scheme for RegArt's sections and subsections.
	 *@return {string} Human-friendly representation of a RegArt.
	 */
	makeDisplayNameNoRegText(regArt, childrenNaming) {
		const { annex, subsections } = RegArtFormatter.parseRegArtPath(regArt)
		return `${subsections
			.map((value, index) => this._formatSubsection(value, index, childrenNaming[index]))
			.join(' ')}${this._formatAnnex(annex)}`
	}

	/**
	 * Make pretty name for range of RegArts
	 * @param {string} regArtStart
	 * @param {string} regArtEnd
	 * @param {string[]} childrenNaming
	 * @return {string}
	 */
	makeDisplayNameForRange(regArtStart, regArtEnd, childrenNaming) {
		return `${this.makeDisplayNameNoRegText(regArtStart, childrenNaming)} – ${regArtEnd.replace(
			/.+[~^]([^~^]+)$/,
			'$1'
		)}`
	}
}
