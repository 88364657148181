import Vue from 'vue'
import VueI18n from 'vue-i18n'
import FrenchTranslations from 'er-i18n-frontend/fr-CH'
import EnglishTranslations from 'er-i18n-frontend/en-GB'
import ItalianTranslations from 'er-i18n-frontend/it-CH'
import GermanTranslations from 'er-i18n-frontend/de-CH'

Vue.use(VueI18n)

function loadLocaleMessages() {
	return {
		'fr-CH': FrenchTranslations,
		'en-GB': EnglishTranslations,
		'it-CH': ItalianTranslations,
		'de-CH': GermanTranslations,
	}
}

const i18n = new VueI18n({
	// language is not yet set at this plugin initialization so set the default one for now, it will be updated with the real users language a little bit later
	locale: process.env.VUE_APP_I18N_FALLBACK_LOCALE, // set locale
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
	messages: loadLocaleMessages(),
	// replace the asterisk at the end of the string with non breaking space + asterisk, so that the asterisk never gets left alone in the new line
	postTranslation: (str) => str.replace(' *', '\xa0*'),
})

export default i18n
