<template>
	<div data-cy="landing-page">
		<div v-if="$helpers.detectUnsupportedBrowser()" class="unsupported-browser-error">
			<span>{{ $t('general.detectedInternetBrowserMessage') }}</span>
		</div>

		<warning
			v-if="isIsolatedTester"
			type="warning-1"
			:alert-message="$t('warnings.isolatedTesterWarning')"
		/>

		<warning
			v-if="showNoAccessToChangePageWarning"
			type="warning-2"
			:alert-message="$t('warnings.noAccessToChangePageWarning')"
		/>

		<LandingDashboard />
	</div>
</template>

<script>
import LandingDashboard from '@/components/comments/LandingCollaborationDashboard'
import Warning from '@/components/shared/Warning.vue'
import SharedMixin from '@/mixins/shared'

export default {
	name: 'Landing',
	components: {
		LandingDashboard,
		Warning,
	},
	mixins: [SharedMixin],
	data: function () {
		return {
			param: null,
			showNoAccessToChangePageWarning: false,
		}
	},
	beforeCreate: function () {},
	created: function () {
		if (this.$route.query.showNoAccessToChangePageWarning === 'yes') {
			this.showNoAccessToChangePageWarning = true
		}
	},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {
		let self = this
		self.$nextTick(function () {
			self.$eventHub.$emit('styleRegArtLinks')
		})
	},
	beforeDestroy: function () {},
	destroyed: function () {},
	methods: {},
}
</script>

<style scoped lang="scss" src="./landing.scss"></style>
