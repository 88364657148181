import store from '../services/vuex'

export default {
	state: () => ({
		regTextChangesDomain: [],
		regTextChangesMain: [],
		changeRoles: [],
	}),
	mutations: {
		setState(state, payload) {
			state[payload.key] = payload.value
		},
	},
	actions: {
		async getRegTextChangesDomain({ commit }) {
			try {
				let endpoint = `${store.state.baseDomainURL}/RegTextChange`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setState', { key: 'regTextChangesDomain', value: data })
			} catch (e) {
				console.log(e)
			} finally {
				// Stop loading
			}
		},
		async getRegTextChangesMain({ commit }, regTextType) {
			try {
				let language = store.state.currentUser.documentDisplayLanguage
				let endpoint = `${store.state.baseMainURL}/ListOfRegulation/RegTextChange?RegTextType=${regTextType}&language=${language}&SolrLoadSet=3&NumberOfDaysOfHistoryToShow=730`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setState', {
					key: 'regTextChangesMain',
					value: data,
				})
			} catch (e) {
				console.log(e)
			} finally {
				// Stop loading
			}
		},
		async getChangeRoles({ commit }) {
			try {
				const endpoint = `${store.state.baseDomainURL}/users/changeroles`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setState', {
					key: 'changeRoles',
					value: data,
				})
			} catch (e) {
				console.log(e)
			} finally {
				// Stop loading
			}
		},
	},
	getters: {
		getRegTextChangesDomain: (state) => state.regTextChangesDomain,
		getRegTextChangesMain: (state) => state.regTextChangesMain,
		getChangeRoles: (state) => state.changeRoles,
	},
}
