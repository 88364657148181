import { updateField, getField } from 'vuex-map-fields'
import store from '../services/vuex'

export default {
	namespaced: true,

	state: () => ({
		messages: [],
		newMessage: '',
		sendingMessage: false,
	}),

	mutations: {
		updateField,

		setMessages(state, payload) {
			state.messages = payload
		},
	},

	actions: {
		/* GET ALL INTERNAL COMMENTS */
		async saveChat({ commit }, payload) {
			try {
				const endpoint = `${store.state.baseMainURL}/LLM/openAIChat`
				const { data } = await this._vm.$http.post(endpoint, payload)
				commit('setMessages', data.messages)
				return data.messages
			} catch (e) {
				console.log(e)
			}
		},
	},

	getters: {
		getField,
	},
}
