/* 
This is the shared vuex store - all the shared 
things across the application should be put in here 
- for example getting users. getUserName() is called in many components 
so that is why it is here in actions.
*/

import store from '../services/vuex'

export default {
	state: () => ({
		currentSearchedArticle: null,
		changeLoading: false,
		statusTypesDictionary: {},
		changeUsersDictionary: {},
		authorsDictionary: {},
		commentTypesDictionary: {},
		userNames: [],
		canUseRoutes: [],
	}),

	mutations: {
		setState(state, payload) {
			state[payload.key] = payload.value
		},
	},

	actions: {
		async getUserNames({ commit }) {
			try {
				commit('setState', { key: 'changeLoading', value: true })
				const endpoint = `${store.state.baseDomainURL}/users/names`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setState', { key: 'userNames', value: data })
				return true
			} catch (e) {
				console.log(e)
			} finally {
				commit('setState', { key: 'changeLoading', value: false })
			}
		},

		async getCanUseRoutes({ commit }) {
			try {
				commit('setState', { key: 'changeLoading', value: true })
				const endpoint = `${store.state.baseMainURL}/domain/ServicePlan/CanUse`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setState', { key: 'canUseRoutes', value: data })
				localStorage.setItem('canUse', JSON.stringify(data))
				return true
			} catch (e) {
				console.log(e)
			} finally {
				commit('setState', { key: 'changeLoading', value: false })
			}
		},
	},
	getters: {
		userNames: (state) => state.userNames,
		changeLoading: (state) => state.changeLoading,
		statusTypesDictionary: (state) => state.statusTypesDictionary,
		changeUsersDictionary: (state) => state.changeUsersDictionary,
		authorsDictionary: (state) => state.authorsDictionary,
		commentTypesDictionary: (state) => state.commentTypesDictionary,
		canUseRoutes: (state) => state.canUseRoutes,
		currentSearchedArticle: (state) => state.currentSearchedArticle,
	},
}
