/* 
This is the shared mixin useed across the application
it consits of all the logic that is shared across many components, 
so that we dont repeateadly define and pollute files.
 */

import { mapGetters } from 'vuex'
// import Dictionaries from './dictionaries'

export default {
	// mixix: [Dictionaries],

	computed: {
		...mapGetters(['currentUser']),

		getDateNow() {
			return new Date().toLocaleString('en-GB', {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
			})
		},

		disableSaveButtonWhenEmpty() {
			return (commentEditor) => {
				if (commentEditor !== null) {
					if (commentEditor.trim().length === 0) {
						return true
					}
					/* eslint-disable */
					let transformedResult = commentEditor.match(/<p>([^\<]*?)<\/p>/g)
					if (transformedResult) {
						if (transformedResult.length === 1 && transformedResult[0] === '<p></p>') {
							return true
						}

						if (transformedResult.length > 1) {
							let onlyHtml = true
							for (let i = 0; i < transformedResult.length; i++) {
								if (transformedResult[i] !== '<p></p>') {
									onlyHtml = false
									break
								}
							}
							return onlyHtml
						}
					}

					return false
				}

				return true
			}
		},

		isCurrentUserTaggedInComment() {
			return (comment) => {
				return comment.taggedUserIds.includes(this.currentUser.id)
			}
		},

		commentTypes() {
			return this.$store.getters.commentTypes
		},

		isIsolatedTester() {
			return [this.$store.getters.getRole('isolatedTester')].some((r) =>
				this.$store.getters.userRoles.includes(r)
			)
		},

		isCommentEditor() {
			const currentUserRoles = this.$store.getters.userRoles
			return [this.$store.getters.getRole('commentEditor')].some((r) =>
				currentUserRoles.includes(r)
			)
		},

		getCommentType() {
			return (id) => {
				if (id) {
					const commentType = this.commentTypes.find((el) => el.id === id)
					return commentType.labelEn
				}
				return null
			}
		},

		statusTypes() {
			let defaultItem = {
				id: null,
				status: 'NO_STATUS',
				text: this.$t('commentEdit.commentStatusNoStatus'),
			}

			console.log(this.$store.getters.statusTypes)

			let originalStatusTypes = this.$store.getters.statusTypes

			if (originalStatusTypes && originalStatusTypes.length) {
				return [
					defaultItem,
					...originalStatusTypes.map((el) => ({
						...el,
						text: this.getStatusTranslationItems(el.status),
					})),
				]
			}

			return []
		},

		statusTypesFromStore() {
			return this.$store.getters.statusTypes
		},
	},
	methods: {
		/* This will return translated items for status dropdown */
		getStatusTranslationItems(item) {
			switch (item) {
				case 'Todo':
					return this.$t('commentEdit.commentStatusTodo')
				case 'In-Progress':
					return this.$t('commentEdit.commentStatusInProgress')
				case 'Done':
					return this.$t('commentEdit.commentStatusDone')
			}
		},
		/* 
      This function will pull out all the 
      included users from the tiptap editor 
      html content 
    */
		extractTaggedUsersFromHtml(html) {
			const extractedUsers = html.match(/\B@[a-z0-9_-]+/gi)
			return extractedUsers
		},

		/* 
      This function will add the label and value as 
      user id for the tagged user ids 
    */
		getEnrichedUserNames(usersNames) {
			let enrichedUserNames = []

			for (let i = 0; i < usersNames.length; i++) {
				let enrichedUserData = Object.assign({}, usersNames[i])
				enrichedUserData.label = enrichedUserData.fullName
				enrichedUserData.value = '@{' + enrichedUserData.id + '}'
				enrichedUserNames.push(enrichedUserData)
			}

			return enrichedUserNames
		},

		/* 
      This function will get all the tagged 
      user ids in the comment
    */
		getTaggedUserIds(users) {
			if (!users) return []

			const mentions = []

			users.forEach((el) => {
				const user = el.split('@')[1]
				const index = this.getEnrichedUserNames(this.userNames).findIndex((item) =>
					item.fullName.includes(user)
				)
				if (index >= 0) mentions.push(this.getEnrichedUserNames(this.userNames)[index].id)
			})

			return [...new Set(mentions)]
		},

		/* 
			Only FR and EN are allowed as fallback, 
			because headers translations are only available in FR and EN 
		*/
		getFallbackLanguage() {
			const lang = this.$store.getters.currentUser.documentDisplayLanguage
			if (['EN', 'FR'].includes(lang)) {
				return lang
			}
			return this.$store.getters.currentUser.preferredLanguage
		},

		// Check if the user is chat user
		isChatUser(roles) {
			if (!roles) throw new Error('Pass user roles array to check')

			if (roles.length <= 2 && roles.includes('CHAT_USER')) return true
			return false
		},

		resultArticleDoubleClick(searchResult, functionCalledBy = 'dblclick') {
			const resultHandlers = {
				GLOBAL_COMMENT: () => this.redirectToReadPage(searchResult),
				INTERNAL_COMMENT: () => this.redirectToReadPage(searchResult),
				CHANGE: () => this.redirectToChangesPage(searchResult),
				MAIN_CHANGE: () => this.redirectToChangesPage(searchResult),
				DOMAIN_CHANGE: () => this.redirectToChangesPage(searchResult),
				MAIN_CHANGE_FILE: () => this.redirectToChangesPage(searchResult),
				DOMAIN_CHANGE_FILE: () => this.redirectToChangesPage(searchResult),
				NEWS: () => {
					/* In case of news, don't do anything on double click */
				},
				TOPIC: () => this.redirectToTopicPage(searchResult),
			}

			if (resultHandlers.hasOwnProperty(searchResult.resultType)) {
				resultHandlers[searchResult.resultType]()
			} else {
				this.handleNormalRegulation(searchResult, functionCalledBy)
			}
		},

		redirectToReadPage(searchResult) {
			this.$router
				.push({
					name: 'readWithArticleNumber',
					params: {
						regTextId: searchResult.id.regTextId,
						articleNumber: searchResult.id.completeParagraphId,
					},
					query: {
						versionDate: this.$route.query.versionDate,
					},
				})
				.catch((error) => {
					console.log(error)
				})
		},

		redirectToChangesPage(searchResult) {
			this.$router.push({
				name: 'changesWithSlug',
				params: { slug: searchResult.slug },
			})
		},

		redirectToTopicPage(searchResult) {
			this.$router.push({
				name: 'topicWithTopicName',
				params: { topicName: searchResult.slug },
			})
		},

		handleNormalRegulation(searchResult, functionCalledBy) {
			if (functionCalledBy === 'dblclick') {
				this.$store.dispatch('trackPageVisit', {
					route: this.$route,
					pageType: 'SearchResult_DblClick',
					urlAdd: `/TargetRegArt/${searchResult.id.regTextId}/${searchResult.id.completeParagraphId}`,
				})
			}

			this.redirectToReadPage(searchResult)
		},
	},
}
