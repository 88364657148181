var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.getters.isLoggedIn && !_vm.$route.query.continue
    ? _c("Landing")
    : !_vm.$store.getters.isLoggedIn
    ? _c("Login")
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }