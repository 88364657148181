var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm._m(0),
      _c(
        "v-app",
        {
          ref: "vuetify-container",
          class: [
            { "tablet-screen": _vm.tabletScreenWidth },
            { "phone-screen": _vm.phoneScreenWidth }
          ],
          staticStyle: { background: "transparent" },
          attrs: { id: "vuetify-container" }
        },
        [
          _vm.$store.state.areWeUsingRegArtTooltip
            ? _c("RegArtTooltip", { ref: "regart-link-tooltip" })
            : _vm._e(),
          _vm.$store.getters.isLoggedIn &&
          _vm.$route.name !== "acceptInvite" &&
          _vm.$route.name !== "passwordReset" &&
          _vm.$store.state.allStartupDataLoaded
            ? _c("app-header", { ref: "app-header" })
            : _vm._e(),
          _vm.$store.state.allStartupDataLoaded
            ? _c("router-view", {
                key: _vm.route + _vm.$store.state.reloadMainComponentKey,
                on: { search: _vm.handleSearch },
                nativeOn: {
                  mouseover: function($event) {
                    return _vm.onMouseover.apply(null, arguments)
                  },
                  touchend: function($event) {
                    return _vm.handleClicks.apply(null, arguments)
                  },
                  click: function($event) {
                    return _vm.handleClicks.apply(null, arguments)
                  }
                }
              })
            : _vm._e(),
          _vm.$store.getters.isLoggedIn &&
          _vm.$route.name !== "acceptInvite" &&
          _vm.$route.name !== "passwordReset" &&
          _vm.$store.state.allStartupDataLoaded
            ? _c("app-footer")
            : _vm._e(),
          !_vm.$store.getters.isLoggedIn &&
          (_vm.$route.path === "/" || _vm.$route.path === "/LoginByEmail") &&
          _vm.showFooter
            ? _c(
                "div",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-1 d-flex justify-space-between",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("v-icon", [_vm._v("copyright")]),
                          _c(
                            "a",
                            {
                              staticClass: "px-1",
                              attrs: { href: "https://easy-reg.ch" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("general.easyRegBrand")) +
                                  " "
                              )
                            ]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("general.easyRegPunchLine")) +
                              " "
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c(
                          "a",
                          {
                            staticClass: "px-1",
                            attrs: {
                              href: " https://easy-reg.ch/e-reg-features/"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("general.fullFeatureText")) +
                                " "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("picture", [
      _c("source", {
        attrs: {
          srcset:
            require("@/assets/images/swirls/Swirls2880x2048.webp") +
            " 2880w, " +
            require("@/assets/images/swirls/Swirls1920x1365.webp") +
            " 1920w, " +
            require("@/assets/images/swirls/Swirls1264x898.webp") +
            " 1264w, " +
            require("@/assets/images/swirls/Swirls960x682.webp") +
            " 960w, " +
            require("@/assets/images/swirls/Swirls600x424.webp") +
            " 600w, " +
            require("@/assets/images/swirls/Swirls300x213.webp") +
            " 300w",
          sizes: "100vw",
          type: "image/webp"
        }
      }),
      _c("source", {
        attrs: {
          srcset:
            require("@/assets/images/swirls/Swirls2880x2048.jpg") +
            " 2880w, " +
            require("@/assets/images/swirls/Swirls1920x1365.jpg") +
            " 1920w, " +
            require("@/assets/images/swirls/Swirls1264x898.jpg") +
            " 1264w, " +
            require("@/assets/images/swirls/Swirls960x682.jpg") +
            " 960w, " +
            require("@/assets/images/swirls/Swirls600x424.jpg") +
            " 600w, " +
            require("@/assets/images/swirls/Swirls300x213.jpg") +
            " 300w",
          sizes: "100vw",
          type: "image/jpeg"
        }
      }),
      _c("img", {
        staticClass: "background-swirls",
        attrs: { src: require("@/assets/images/swirls/Swirls960x682.jpg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }