import store from '../services/vuex'

export default {
	state: () => ({
		mainChangeElementsStored: [],
		domainChangeElementsStored: [],
		computedChangeTableElements: [],
		cachedUserSettings: null,
	}),

	mutations: {
		SET_MAIN_CHANGE_ELEMENTS(state, payload) {
			state.mainChangeElementsStored = payload
		},
		SET_DOMAIN_CHANGE_ELEMENTS(state, payload) {
			state.domainChangeElementsStored = payload
		},
		SET_COMPUTED_CHANGE_TABLE_ELEMENTS(state, payload) {
			state.computedChangeTableElements = payload
		},
		SET_CHACHED_USER_SETTINGS(state, payload) {
			state.cachedUserSettings = payload
		},
	},

	actions: {
		/* GET ALL INTERNAL COMMENTS */
		async getChangeElements({ commit }) {
			try {
				const endpoint = `${store.state.baseMainURL}/ChangeElements`
				const { data } = await this._vm.$http.get(endpoint)
				commit('SET_MAIN_CHANGE_ELEMENTS', data)
				return data
			} catch (e) {
				console.log(e)
			}
		},

		/* GET ALL DOMAIN CHANGE COMMENTS */
		async getDomainChangeElements({ commit }) {
			try {
				const endpoint = `${store.state.baseDomainURL}/ChangeElementsDomain`
				const { data } = await this._vm.$http.get(endpoint)
				commit('SET_DOMAIN_CHANGE_ELEMENTS', data)
				return data
			} catch (e) {
				console.log(e)
			}
		},
	},

	getters: {
		mainChangeElementsStored: (state) => state.mainChangeElementsStored,
		domainChangeElementsStored: (state) => state.domainChangeElementsStored,
		computedChangeTableElements: (state) => state.computedChangeTableElements,
		cachedUserSettings: (state) => state.cachedUserSettings,
	},
}
