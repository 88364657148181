import store from '../services/vuex'
import { updateField, getField } from 'vuex-map-fields'

export default {
	state: () => ({
		loading: false,
		commentTypes: [],
		statusTypes: [],

		/* Changes Page State */
		selectedChangeElement: null,
		selectedChangeElementComments: [],
		selectedCommentForEdit: {},
		currentlySelectedComment: {},
		changeUsers: [],
	}),
	mutations: {
		updateField,
		setState(state, payload) {
			state[payload.key] = payload.value
		},
		SET_SELECTED_CHANGE_ELEMENT_COMMENTS(state, payload) {
			state.selectedChangeElementComments = payload
		},
	},
	actions: {
		async getAllCommentTypes({ commit }) {
			try {
				commit('setState', { key: 'loading', value: true })
				const endpoint = `${store.state.baseDomainURL}/CommentType`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setState', { key: 'commentTypes', value: data })
				return true
			} catch (e) {
				console.log(e)
			} finally {
				commit('setState', { key: 'loading', value: false })
			}
		},

		async getStatusTypes({ commit }) {
			try {
				commit('setState', { key: 'loading', value: true })
				const endpoint = `${store.state.baseDomainURL}/InternalCommentsStatus`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setState', { key: 'statusTypes', value: data })
				return data
			} catch (e) {
				console.log(e)
			} finally {
				commit('setState', { key: 'loading', value: false })
			}
		},

		async saveChangeComment({ commit }, payload) {
			try {
				commit('setState', { key: 'loading', value: true })

				let endpoint, response

				if (payload.commentMode === 'new-comment') {
					endpoint = `${store.state.baseDomainURL}/InternalCommentsDomain`
					const { data } = await this._vm.$http.post(endpoint, payload.body)
					response = data
				} else {
					endpoint = `${store.state.baseDomainURL}/InternalCommentsDomain/${payload.body.comment_id}`
					const { data } = await this._vm.$http.put(endpoint, payload.body)
					response = data
				}

				return response
			} catch (e) {
				console.log(e)
			} finally {
				commit('setState', { key: 'loading', value: false })
			}
		},

		async getCommentsForChangeElementId({ commit }, payload) {
			try {
				commit('setState', { key: 'loading', value: true })

				let changeElementID = payload?.changeElementsId || payload?.changeElementID

				const endpoint = `${store.state.baseDomainURL}/InternalCommentsDomain/Change/${changeElementID}?origin=${payload.origin}`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setState', {
					key: 'selectedChangeElementComments',
					value: data,
				})
				return true
			} catch (e) {
				console.log(e)
			} finally {
				commit('setState', { key: 'loading', value: false })
			}
		},

		async resolveComment({ commit, dispatch, state }, payload) {
			try {
				commit('setState', { key: 'loading', value: true })
				const endpoint = `${store.state.baseDomainURL}/InternalCommentsDomain/resolveTagging/${payload.comment_id}?resolve=${payload.resolve}`
				const { data } = await this._vm.$http.patch(endpoint)
				dispatch('getCommentsForChangeElementId', state.selectedChangeElement)
				return data
			} catch (e) {
				console.log(e)
			} finally {
				commit('setState', { key: 'loading', value: false })
			}
		},

		async deleteComment({ commit }, payload) {
			try {
				commit('setState', { key: 'loading', value: true })
				const endpoint = `${store.state.baseDomainURL}/InternalCommentsDomain/${payload.comment_id}`
				const { data } = await this._vm.$http.delete(endpoint)
				return data
			} catch (e) {
				console.log(e)
			} finally {
				commit('setState', { key: 'loading', value: false })
			}
		},

		async changeCommentStatus({ commit }, payload) {
			try {
				commit('setState', { key: 'loading', value: true })
				const endpoint = `${store.state.baseDomainURL}/InternalCommentsDomain/Status/${payload.comment_id}?statusId=${payload.statusId}`
				const { data } = await this._vm.$http.patch(endpoint, payload.body)
				return data
			} catch (e) {
				console.log(e)
			} finally {
				commit('setState', { key: 'loading', value: false })
			}
		},

		async changeUsers({ commit }) {
			try {
				commit('setState', { key: 'loading', value: true })
				const endpoint = `${store.state.baseDomainURL}/users/changeroles`
				const { data } = await this._vm.$http.get(endpoint)
				commit('setState', { key: 'changeUsers', value: data })
				return data
			} catch (e) {
				console.log(e)
			} finally {
				commit('setState', { key: 'loading', value: false })
			}
		},
	},
	getters: {
		getField,
		commentTypes: (state) => state.commentTypes,
		statusTypes: (state) => state.statusTypes,
		commentsLoading: (state) => state.loading,
		selectedChangeElement: (state) => state.selectedChangeElement,
		selectedChangeElementComments: (state) => state.selectedChangeElementComments,
		isCommentsLoading: (state) => state.loading,
		selectedCommentForEdit: (state) => state.selectedCommentForEdit,
		currentlySelectedComment: (state) => state.currentlySelectedComment,
		changeUserList: (state) => state.changeUsers,
	},
}
