var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root-div" },
    [
      _c(
        "v-app-bar",
        {
          ref: "header",
          staticClass: "header",
          class: [
            { "tablet-screen": _vm.tabletScreenWidth },
            { "hide-header": _vm.hideAppHeader },
            { "phone-screen": _vm.phoneScreenWidth }
          ],
          attrs: {
            color: "primary",
            dense: _vm.tabletScreenWidth,
            flat: _vm.phoneScreenWidth
          }
        },
        [
          _c(
            "router-link",
            {
              ref: "logo-link",
              staticClass: "logo-link",
              attrs: { to: "/", "data-cy": "home_page" }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/eReg_logo/eReg_logo_white.png")
                }
              })
            ]
          ),
          !_vm.tabletScreenWidth
            ? _c(
                "div",
                { staticClass: "links" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "header-link-not-active",
                      class:
                        _vm.isChatUser(_vm.$store.getters.userRoles) &&
                        "disabled-link",
                      attrs: {
                        to: "/comments",
                        disabled: ![
                          _vm.$store.getters.getRole("reader"),
                          _vm.$store.getters.getRole("commentEditor")
                        ].some(function(r) {
                          return _vm.$store.getters.userRoles.includes(r)
                        }),
                        exact: "",
                        "exact-active-class": "header-link-active"
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("appHeader.commentsLabel")) + " "
                      )
                    ]
                  ),
                  _c("hr", { attrs: { width: "1", size: "25" } }),
                  [
                    _vm.$store.getters.getRole("changeEditor"),
                    _vm.$store.getters.getRole("changeReader")
                  ].some(function(r) {
                    return _vm.$store.getters.userRoles.includes(r)
                  })
                    ? _c(
                        "router-link",
                        {
                          staticClass: "header-link-not-active",
                          class:
                            _vm.isChatUser(_vm.$store.getters.userRoles) &&
                            "disabled-link",
                          attrs: {
                            to: "/changes",
                            "data-text": _vm.$t("appHeader.changesLabel"),
                            exact: "",
                            "exact-active-class": "header-link-active"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("appHeader.changesLabel")) + " "
                          )
                        ]
                      )
                    : _c("span", { staticClass: "disabled-link" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("appHeader.changesLabel")) + " "
                        )
                      ]),
                  _c("hr", { attrs: { width: "1", size: "25" } }),
                  [
                    _vm.$store.getters.getRole("reader"),
                    _vm.$store.getters.getRole("commentEditor")
                  ].some(function(r) {
                    return _vm.$store.getters.userRoles.includes(r)
                  })
                    ? _c(
                        "router-link",
                        {
                          class:
                            _vm.isChatUser(_vm.$store.getters.userRoles) &&
                            "disabled-link",
                          attrs: {
                            to: "/read",
                            "data-text": _vm.$t("appHeader.readLabel"),
                            exact: ""
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("appHeader.readLabel")) + " "
                          )
                        ]
                      )
                    : _c("span", { staticClass: "disabled-link" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("appHeader.readLabel")) + " "
                        )
                      ]),
                  _c("hr", { attrs: { width: "1", size: "25" } }),
                  _vm.servicePlan !== "ESSENTIAL_K"
                    ? _c(
                        "router-link",
                        {
                          class:
                            _vm.isChatUser(_vm.$store.getters.userRoles) &&
                            "disabled-link",
                          attrs: {
                            to: "/topic/landing",
                            "data-text": _vm.$t("appHeader.topicsLabel"),
                            exact: ""
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("appHeader.topicsLabel")) + " "
                          )
                        ]
                      )
                    : _c(
                        "router-link",
                        {
                          class:
                            _vm.isChatUser(_vm.$store.getters.userRoles) &&
                            "disabled-link",
                          attrs: { to: "/topic/landing-k", exact: "" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("appHeader.topicsLabel")) + " "
                          )
                        ]
                      )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              ref: "search",
              staticClass: "search",
              style: _vm.searchExpandStyle
            },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "focus",
                    rawName: "v-focus",
                    value: !_vm.tabletScreenWidth,
                    expression: "!tabletScreenWidth"
                  }
                ],
                ref: "search-text",
                staticClass: "search-text",
                attrs: {
                  id: "search-input",
                  loading: _vm.isSearching,
                  rounded: "",
                  clearable: "",
                  "hide-details": "",
                  "prepend-inner-icon": "search",
                  placeholder: _vm.$t("appHeader.searchPlaceholder"),
                  disabled:
                    ![
                      _vm.$store.getters.getRole("reader"),
                      _vm.$store.getters.getRole("commentEditor")
                    ].some(function(r) {
                      return _vm.$store.getters.userRoles.includes(r)
                    }) || _vm.isChatUser(_vm.$store.getters.userRoles),
                  autocomplete: "off",
                  "data-cy": "search-input",
                  "append-icon": _vm.toggleComplete
                    ? "expand_less"
                    : "expand_more"
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.initiateTheSearch.apply(null, arguments)
                  },
                  "click:prepend-inner": _vm.initiateTheSearch,
                  "click:append": function($event) {
                    _vm.toggleComplete = !_vm.toggleComplete
                  },
                  focus: function($event) {
                    _vm.isSearchFocused = true
                  },
                  blur: function($event) {
                    _vm.isSearchFocused = false
                  },
                  "click:clear": _vm.onSearchInputClear
                },
                scopedSlots: _vm._u([
                  {
                    key: "progress",
                    fn: function() {
                      return [
                        _c("v-progress-linear", {
                          staticClass: "progress-linear",
                          attrs: {
                            indeterminate: "",
                            absolute: "",
                            height: "3"
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.searchTerm,
                  callback: function($$v) {
                    _vm.searchTerm = $$v
                  },
                  expression: "searchTerm"
                }
              }),
              _vm.toggleComplete
                ? _c(
                    "div",
                    { staticClass: "static-keys d-flex flex-column" },
                    [
                      _c("v-simple-table", {
                        staticClass: "static-keys_table",
                        attrs: { dense: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.localStringsKeys, function(key) {
                                      return _c("tr", { key: key }, [
                                        _c(
                                          "td",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.getStaticKey(key)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "search.staticHelpKey." +
                                                      key,
                                                    { staticKey: key }
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3488899787
                        )
                      }),
                      _c("div", { staticClass: "static-keys_spacer ma-auto" }),
                      _c("div", { staticClass: "static-keys_list" }, [
                        _c(
                          "div",
                          { staticClass: "font-weight-bold font-italic pb-1" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$tc("search.important", 1)) + " "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "body-2" }, [
                          _vm._v(_vm._s(_vm.$tc("search.important", 2)))
                        ]),
                        _c(
                          "ul",
                          [
                            _c("i18n", {
                              attrs: {
                                path: "search.staticList.list_1",
                                tag: "li"
                              },
                              scopedSlots: _vm._u(
                                [
                                  _vm._l(_vm.listLinks_1, function(item, i) {
                                    return {
                                      key: i,
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              key: i,
                                              staticClass:
                                                "font-weight-bold text--",
                                              on: {
                                                click: function($event) {
                                                  return _vm.makeAutoSearch(
                                                    item.name
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  })
                                ],
                                null,
                                true
                              )
                            }),
                            _c("i18n", {
                              attrs: {
                                path: "search.staticList.list_2",
                                tag: "li"
                              },
                              scopedSlots: _vm._u(
                                [
                                  _vm._l(_vm.listLinks_2, function(item, i) {
                                    return {
                                      key: i,
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              key: i,
                                              staticClass: "font-weight-bold",
                                              on: {
                                                click: function($event) {
                                                  return _vm.makeAutoSearch(
                                                    item.name
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  })
                                ],
                                null,
                                true
                              )
                            }),
                            _c("i18n", {
                              attrs: {
                                path: "search.staticList.list_3",
                                tag: "li"
                              },
                              scopedSlots: _vm._u(
                                [
                                  _vm._l(_vm.listLinks_3, function(item, i) {
                                    return {
                                      key: i,
                                      fn: function() {
                                        return [
                                          _c(
                                            "router-link",
                                            {
                                              key: i,
                                              staticClass: "font-weight-bold",
                                              attrs: { to: { path: item.link } }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.locationSlug) +
                                                  _vm._s(item.link) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  })
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("hr", {
            ref: "header-hr",
            staticClass: "hr",
            attrs: { width: "1", size: "25" }
          }),
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var menu = ref.on
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var tooltip = ref.on
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        {
                                          staticClass: "dropdown-selected",
                                          class: {
                                            "dropdown-selected-sm":
                                              _vm.evenSmallerScreen
                                          },
                                          attrs: {
                                            "data-cy":
                                              "website-language-dropdown"
                                          }
                                        },
                                        Object.assign({}, tooltip, menu)
                                      ),
                                      [
                                        !_vm.evenSmallerScreen
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "appHeader." +
                                                        _vm.$store.getters.getLanguageTranslationHook(
                                                          _vm.$store.state
                                                            .currentUser
                                                            .documentDisplayLanguage
                                                        )
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.evenSmallerScreen
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .$t(
                                                        "appHeader." +
                                                          _vm.$store.getters.getLanguageTranslationHook(
                                                            _vm.$store.state
                                                              .currentUser
                                                              .documentDisplayLanguage
                                                          )
                                                      )
                                                      .substring(0, 2)
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("v-icon", [
                                          _vm._v("keyboard_arrow_down")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("appHeader.languageBarTooltip"))
                            )
                          ])
                        ]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.isOpened,
                callback: function($$v) {
                  _vm.isOpened = $$v
                },
                expression: "isOpened"
              }
            },
            [
              _c(
                "v-list",
                _vm._l(_vm.$store.state.availableLanguages, function(lang) {
                  return _c(
                    "v-list-item",
                    {
                      key: lang.languageCode,
                      attrs: { "data-cy": "lang-" + lang.languageCode },
                      on: {
                        click: function($event) {
                          return _vm.langaugeClick(lang.languageCode, $event)
                        }
                      }
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "appHeader." +
                                  _vm.$store.getters.getLanguageTranslationHook(
                                    lang.languageCode
                                  )
                              )
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", "data-cy": "avatar" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.drawer = !_vm.drawer
                }
              }
            },
            [
              _c("v-icon", { attrs: { size: "35", color: "#fff" } }, [
                _vm._v("menu")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "navigation-drawer",
          class: { "navigation-drawer_mobile": _vm.tabletScreenWidth },
          attrs: {
            right: "",
            app: "",
            temporary: "",
            height: "100%",
            "data-cy": "nav"
          },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "pb-16 mb-4 px-2 text-center grey--text" },
                    [
                      _c("small", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("landingDashboard.loggedIn", {
                                name: _vm.$store.state.currentUser.fullName
                              })
                            ) +
                            " "
                        )
                      ])
                    ]
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-list",
            {
              staticClass:
                "navigation-link-list d-flex flex-column justify-space-between"
            },
            [
              _c(
                "div",
                [
                  _vm.tabletScreenWidth
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              ![
                                _vm.$store.getters.getRole("reader"),
                                _vm.$store.getters.getRole("commentEditor")
                              ].some(function(r) {
                                return _vm.$store.getters.userRoles.includes(r)
                              }) || _vm.isChatUser(_vm.$store.getters.userRoles)
                          },
                          on: {
                            click: function($event) {
                              return _vm.userMenuClick({ path: "/comments" })
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("appHeader.commentsLabel")) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.tabletScreenWidth
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              !_vm.canUseRoutes.includes("CAN_SEE_CHANGE") ||
                              _vm.isChatUser(_vm.$store.getters.userRoles)
                          },
                          on: {
                            click: function($event) {
                              return _vm.userMenuClick({ path: "/changes" })
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("appHeader.changesLabel")) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.tabletScreenWidth
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              ![
                                _vm.$store.getters.getRole("reader"),
                                _vm.$store.getters.getRole("commentEditor")
                              ].some(function(r) {
                                return _vm.$store.getters.userRoles.includes(r)
                              }) || _vm.isChatUser(_vm.$store.getters.userRoles)
                          },
                          on: {
                            click: function($event) {
                              return _vm.userMenuClick({ path: "/topic" })
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("appHeader.topicsLabel")) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.tabletScreenWidth
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              ![
                                _vm.$store.getters.getRole("changeEditor"),
                                _vm.$store.getters.getRole("changeReader")
                              ].some(function(r) {
                                return _vm.$store.getters.userRoles.includes(r)
                              }) || _vm.isChatUser(_vm.$store.getters.userRoles)
                          },
                          on: {
                            click: function($event) {
                              return _vm.userMenuClick({ path: "/read" })
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("appHeader.readLabel")) + " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        disabled:
                          !_vm.canUseRoutes.includes("CAN_SEE_NEWS") ||
                          _vm.isChatUser(_vm.$store.getters.userRoles)
                      },
                      on: {
                        click: function($event) {
                          return _vm.userMenuClick({ path: "/news" })
                        }
                      }
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("appHeader.newsLabel")) + " "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.$store.getters.userRoles.includes(
                    "EREG_STATICDB_DATA_ADMIN"
                  )
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            disabled:
                              !_vm.canUseRoutes.includes(
                                "CAN_DIRECT_DATA_LOADING"
                              ) || _vm.isChatUser(_vm.$store.getters.userRoles)
                          },
                          on: {
                            click: function($event) {
                              return _vm.userMenuClick({ path: "/loadData" })
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(" " + _vm._s(_vm.loadDataLabel) + " ")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        disabled:
                          !_vm.canUseRoutes.includes(
                            "CAN_SEE_REG_TEXT_CHANGE"
                          ) || _vm.isChatUser(_vm.$store.getters.userRoles)
                      },
                      on: {
                        click: function($event) {
                          return _vm.userMenuClick({ path: "/regTextChange" })
                        }
                      }
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("appHeader.regTextChangeLabel")) +
                            " "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        disabled:
                          !_vm.$store.getters.userRoles.includes("CHAT_USER") ||
                          !_vm.canUseRoutes.includes("CAN_SEE_CHAT")
                      },
                      on: {
                        click: function($event) {
                          return _vm.userMenuClick({ path: "/chat" })
                        }
                      }
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("appHeader.chatLabel")) + " "
                        )
                      ])
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _vm._l(_vm.userMenuitems, function(item, index) {
                    return _c(
                      "v-list-item",
                      {
                        key: index,
                        attrs: {
                          disabled:
                            (item.allowedRoles &&
                              !item.allowedRoles.some(function(r) {
                                return _vm.$store.getters.userRoles.includes(r)
                              })) ||
                            (_vm.isChatUser(_vm.$store.getters.userRoles) &&
                              item.routeName !== "account"),
                          "data-cy":
                            item.routeName === "account"
                              ? "edit-account-button"
                              : item.routeName === "users"
                              ? "users-button"
                              : item.routeName === "invites"
                              ? "invites-button"
                              : null
                        },
                        on: {
                          click: function($event) {
                            return _vm.userMenuClick(item)
                          }
                        }
                      },
                      [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                      1
                    )
                  }),
                  _c("v-divider"),
                  _vm.phoneScreenWidth
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.userMenuClick({ routeName: "support" })
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("appHeader.supportDetailsLabel")
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    {
                      class: { "navbar-logout": _vm.phoneScreenWidth },
                      attrs: { "data-cy": "logout-button" },
                      on: {
                        click: function($event) {
                          return _vm.userMenuClick({
                            routeName: "home",
                            logout: true
                          })
                        }
                      }
                    },
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("appHeader.userMenuLogoutLabel")) +
                            " "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.phoneScreenWidth
                    ? _c(
                        "v-list-item",
                        { staticClass: "navbar-copyright" },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "navbar-copyright-title" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("appFooter.copyrightInformation")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }