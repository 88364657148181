<template>
	<div
		id="search-message"
		class="search-message content container-main my-1 mb-1 mx-0"
		:class="{ 'tablet-screen': tabletScreenWidth }"
	>
		<div v-for="(message, messageIndex) in messages" :key="messageIndex" class="container-main">
			<div
				v-if="message && message.type === 'SEARCH_TERM_HAS_NOT_BEEN_FOUND'"
				class="message-inner actual-search-message"
			>
				{{
					$tc('general.searchNotFound', message.context['removed search terms'].length || 1, {
						words: message.context['removed search terms'].join(', '),
						returned: message.context['returning result for'],
					})
				}}
			</div>

			<!-- first actually follow errors -->

			<div
				v-if="
					message &&
					message.type === 'REG_TEXT_ID_NOT_FOUND' &&
					message.context.documentSearchType === 'FINMA'
				"
				class="message-inner search-error"
			>
				{{
					$t('searchError.noRegTextIdFoundFinma', {
						documentSearchTerm: message.context.documentSearchTerm,
					})
				}}
			</div>
			<div
				v-if="
					message &&
					message.type === 'REG_TEXT_ID_NOT_FOUND' &&
					message.context.documentSearchType !== 'FINMA' &&
					message.context.documentSearchType !== 'GENERIC_KNOWN'
				"
				class="message-inner search-error"
			>
				{{
					$t('searchError.noRegTextIdFound', {
						documentSearchTerm: message.context.documentSearchTerm.searchTerm,
					})
				}}
			</div>
			<div
				v-if="
					message &&
					message.type === 'REG_TEXT_ID_NOT_FOUND' &&
					message.context.documentSearchType === 'GENERIC_KNOWN' &&
					message.context.documentSearchType !== 'FINMA'
				"
				class="message-inner search-error"
			>
				{{
					$t('searchError.noRegTextIdFound', {
						documentSearchTerm: message.context.documentSearchTerm,
					})
				}}
			</div>
			<div
				v-if="message && message.type === 'REG_ART_NOT_FOUND'"
				class="message-inner search-error"
			>
				{{
					$t('searchError.regArtNotFound', {
						regTextDisplayName: message.context.regTextDisplayName,
					})
				}}
			</div>

			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- below are actual messages -->
			<!-- todo: this ERROR_IN_TAG_USE comes from MAIN and should be removed eventually and replaced with the same one tip as the DOMAIN one -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<div
				v-if="message && message.type === 'ERROR_IN_TAG_USE' && message.context.tag"
				class="message-inner actual-search-message"
			>
				{{
					$tc('message.messages.errorInTagUse', 1, {
						unknownTags: message.context.tag,
					})
				}}
			</div>
			<!-- this one ERROR_IN_TAG_USE comes from DOMAIN and is a tip, thats the one that should remain -->
			<div
				v-if="message && message.type === 'ERROR_IN_TAG_USE' && message.context.unknownTags"
				class="message-inner actual-search-message"
			>
				{{
					$tc('message.messages.errorInTagUse', message.context.unknownTags.length, {
						unknownTags: message.context.unknownTags.join(', '),
					})
				}}
			</div>
			<div
				v-if="message && message.type === 'SHOWING_PARAGRAPH_BEFORE'"
				class="message-inner actual-search-message"
			>
				{{
					$t('message.messages.showingParagraphBefore', {
						searchedParagraphId: message.context.searchedParagraphId,
						regTextDisplayName: message.context.regTextDisplayName,
					})
				}}
			</div>
			<div
				v-if="message && message.type === 'DISPLAY_NAME_PROVIDED_IN_ANOTHER_LANGUAGE'"
				class="message-inner actual-search-message"
			>
				{{
					$t('message.messages.displayNameProvidedInAnotherLanguage', {
						shownDisplayName: message.context.shownDisplayName,
						shownLanguage: $t(
							'message.' + $store.getters.getLanguageTranslationHook(message.context.shownLanguage)
						),
						searchedDisplayName: message.context.searchedDisplayName,
						searchedLanguage: $t(
							'message.' +
								$store.getters.getLanguageTranslationHook(message.context.searchedLanguage)
						),
					})
				}}
			</div>
			<i18n
				v-if="message && message.type === 'SIX_IN_FRENCH_SEARCH_TOOLTIP'"
				path="message.messages.sixInFrenchSearchTooltip.tooltip"
				tag="div"
				for="message.messages.sixInFrenchSearchTooltip.link"
				class="message-inner actual-search-message"
			>
				<router-link id="link" to="/search/DE/DE/six">
					{{ $t('message.messages.sixInFrenchSearchTooltip.link') }}
				</router-link>
			</i18n>
			<div
				v-if="message && message.type === 'LERA_DISAMBIGUATION'"
				class="message-inner actual-search-message"
			>
				{{ $t('message.messages.leraDisambiguation') }}
			</div>
			<div
				v-if="message && message.type === 'SEARCH_SET_EXPANDED'"
				class="message-inner actual-search-message"
			>
				{{
					$t('message.messages.searchSetExpanded', {
						searchTerms: message.context.searchTerms,
					})
				}}
			</div>
			<div
				v-if="message && message.type === 'MODIFIED_SEARCH_SET_EXPANDED'"
				class="message-inner actual-search-message"
			>
				{{
					$t('message.messages.modifiedSearchSetExpanded', {
						searchTerms: message.context.searchTerms,
						searchSetUsed: message.context.modifiersUsed.join(', '),
					})
				}}
			</div>
			<div
				v-if="message && message.type === 'EXISTS_IN_DIFFERENT_LANGUAGE'"
				class="message-inner actual-search-message"
				data-cy="different-version"
			>
				{{
					$t('message.messages.existsInDifferentLanguage', {
						documentSearchTerm: message.context.knownDisplayName,
						searchedLanguage: $t(
							'message.' +
								$store.getters.getLanguageTranslationHook(message.context.searchedLanguage)
						),
					})
				}}
				<span v-for="(lang, index) in message.context.existingLanguages" :key="lang">
					<router-link
						id="link"
						to="/"
						@click.native="changeLanguageAndRedirect(lang, message.context.regTextId)"
					>
						{{ $t('message.' + $store.getters.getLanguageTranslationHook(lang)) }}
					</router-link>
					<span v-if="index !== message.context.existingLanguages.length - 1">
						{{ $t('message.enumerationSeparatorInText') }}
					</span>
				</span>
			</div>

			<div
				v-if="message && message.type === 'APPENDIX_NOT_FOUND'"
				class="message-inner actual-search-message"
			>
				{{
					$t('message.messages.appendixNotFound', {
						searchedAppendix: message.context.searchedAppendix,
					})
				}}
			</div>
			<div
				v-if="message && message.type === 'DOCUMENT_FOUND_IN_ONE_OTHER_LANGUAGE'"
				class="message-inner actual-search-message"
			>
				{{
					$t('message.messages.documentFoundInOneOtherLanguage', {
						availableLanguge: $t(
							'message.' +
								$store.getters.getLanguageTranslationHook(message.context.availableLanguge)
						),
						oldDisplayLanguge: $t(
							'message.' +
								$store.getters.getLanguageTranslationHook(message.context.oldDisplayLanguge)
						),
					})
				}}
			</div>
			<div
				v-if="message && message.type === 'CUSTOM_MESSAGE'"
				class="message-inner actual-search-message"
			>
				<!-- display the message in the current users UI language if it's returned from the BE. If not: default to English -->

				<!-- if the link points inside the application it will be intercepted by a function and opened in the same tab -->
				<!-- otherwise it will be opened in a new tab -->
				<a
					v-if="message.context.link"
					id="warning-message"
					rel="noopener noreferrer"
					:href="message.context.link"
					target="_blank"
					v-html="
						message.context.customMessageLabel[
							$store.getters.getLanguageBackendCode($i18n.locale)
								? $store.getters.getLanguageBackendCode($i18n.locale)
								: 'EN'
						]
					"
				></a>
				<div
					v-else
					v-html="
						message.context.customMessageLabel[
							$store.getters.getLanguageBackendCode($i18n.locale)
								? $store.getters.getLanguageBackendCode($i18n.locale)
								: 'EN'
						]
					"
				></div>
			</div>
			<i18n
				v-if="message && message.type === 'CHANGE_PLANNED'"
				path="message.messages.changePlanned.message"
				tag="div"
				for="message.messages.changePlanned.link"
				class="message-inner actual-search-message"
			>
				<a
					id="warning-message"
					rel="noopener noreferrer"
					:href="message.context.link"
					target="_blank"
				>
					{{ $t('message.messages.changePlanned.link') }}
				</a>
			</i18n>
			<div
				v-if="message && message.type === 'SEARCH_RESULT_EXIST_IN_OTHER_LANGUAGE'"
				class="message-inner actual-search-message"
			>
				{{
					$t('message.messages.searchResultExistInOtherLanguage', {
						searchTerm: message.context.searchTerms,
						requestLanguage: $t(
							'message.' +
								$store.getters.getLanguageTranslationHook(message.context.requestLanguage)
						),
						searchedLanguage: $t(
							'message.' +
								$store.getters.getLanguageTranslationHook(message.context.searchedLanguage)
						),
					})
				}}
			</div>

			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- version date tips -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->

			<!-- old version -->
			<i18n
				v-if="message && message.type === 'OLD_VERSIONDATE_TIP'"
				tag="div"
				data-cy="old-versionDate"
				class="message-inner actual-search-message"
				path="message.tips.oldVersionDate"
				@click="handleVersionDateClick(message)"
			>
				<template v-slot:XYZ>{{ message.context.displayName }}</template>
				<template v-slot:activeVersion>
					{{ message.context.requestedVersionDate }}
				</template>
				<template v-slot:currentVersion>
					{{ message.context.currentVersionDate }}
				</template>
				<template v-slot:link>
					<router-link
						id="link"
						:to="{
							name: 'readWithArticleNumber',
							params: { versionDate: 'current' },
						}"
					>
						{{ message.context.currentVersionDate }}
					</router-link>
				</template>
			</i18n>

			<i18n
				v-if="message && message.type === 'FUTURE_VERSIONDATE_TIP'"
				tag="div"
				class="message-inner actual-search-message"
				path="message.tips.futureVersionDate"
				@click="handleVersionDateClick(message)"
			>
				<template v-slot:XYZ>{{ message.context.displayName }}</template>
				<template v-slot:activeVersion>
					{{ message.context.requestedVersionDate }}
				</template>
				<template v-slot:currentVersion>
					{{ message.context.currentVersionDate }}
				</template>
				<template v-slot:link>
					<router-link
						id="link"
						:to="{
							name: 'readWithRegTextId',
						}"
					>
						{{ message.context.currentVersionDate }}
					</router-link>
				</template>
			</i18n>

			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- archive status tips -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
			<!-- --------------------------------------------------------------------------------------------------------------------------------- -->

			<div
				v-if="
					message &&
					message.type === 'ARCHIVE_STATUS' &&
					message.context.archiveStatus === 'FINMA_FAQ_OUTDATED'
				"
				class="message-inner actual-search-message"
			>
				{{ $t('message.messages.finmaFaqOutdated') }}
			</div>
			<i18n
				v-if="
					message &&
					message.type === 'ARCHIVE_STATUS' &&
					message.context.archiveStatus === 'NON_VALID_REPLACED_BY'
				"
				path="message.messages.nonValidReplacedBy"
				tag="div"
				class="message-inner actual-search-message"
			>
				<template v-slot:oldRegTextName>
					{{ message.context.oldRegTextName }}
				</template>
				<template v-slot:newRegTextName>
					<router-link
						id="link"
						:to="{
							name: 'readWithRegTextId',
							params: { regTextId: message.context.newRegTextId },
						}"
					>
						{{ message.context.newRegTextName }}
					</router-link>
				</template>
			</i18n>
			<i18n
				v-if="
					message &&
					message.type === 'ARCHIVE_STATUS' &&
					message.context.archiveStatus === 'FINMA_C0810_OLD_MESSAGE'
				"
				path="message.messages.finmaC0810OldMessage.text"
				tag="div"
				for="message.messages.finmaC0810OldMessage.link"
				class="message-inner actual-search-message"
			>
				<a
					id="warning-message"
					rel="noopener noreferrer"
					:href="message.context.link"
					target="_blank"
				>
					{{ $t('message.messages.finmaC0810OldMessage.link') }}
				</a>
			</i18n>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MessagesComponent',
	props: {
		messages: {
			type: Array,
			default() {
				return []
			},
		},
	},
	data: function () {
		return {
			windowLocationOrigin: window.location.origin, // base application URL
			tabletScreenWidth: window.innerWidth <= this.$store.state.tabletBreakpoint,
		}
	},
	computed: {
		updateLayout() {
			return this.$store.state.updateLayoutProps.updateLayout
		},
	},
	watch: {
		messages: {
			// when we get the messages or messages update: trigger processing
			deep: true, // even if nested properties change
			handler() {
				this.handleMessagesUpdate()
			},
		},
		updateLayout() {
			// update tabletScreenWidth flag
			if (window.innerWidth <= this.$store.state.tabletBreakpoint) this.tabletScreenWidth = true
			else this.tabletScreenWidth = false
		},
	},
	beforeCreate: function () {},
	created: function () {
		// if some messages were already passed in on init trigger processing
		if (this.messages.length) this.handleMessagesUpdate()
	},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {
		let self = this
		self.$nextTick(function () {
			self.$eventHub.$emit('styleRegArtLinks')
		})
	},
	beforeDestroy: function () {},
	destroyed: function () {},
	methods: {
		handleMessagesUpdate() {
			// for now we only need to handle one special case here and that is for the SEARCH_RESULT_EXIST_IN_OTHER_LANGUAGE tip
			// for it we need to set the DDL to the searchedLanguage without triggering a new search
			// the triggering of a new search will be prevented in the SearchPage component it self in the selectedDDL() watched computed property
			let searchedLanguage = this.messages.find(
				(message) => message.type === 'SEARCH_RESULT_EXIST_IN_OTHER_LANGUAGE'
			)
				? this.messages.find((message) => message.type === 'SEARCH_RESULT_EXIST_IN_OTHER_LANGUAGE')
						.context.searchedLanguage
				: null

			if (searchedLanguage) {
				this.$store.dispatch('changeSelectedDDL', {
					$http: this.$http,
					languageCode: searchedLanguage,
				})
			}
		},
		changeLanguageAndRedirect(languageCode, regTextId) {
			let self = this
			self.$store
				.dispatch('changeSelectedDDL', {
					$http: self.$http,
					languageCode: languageCode,
				})
				.then(() => {
					self.$router
						.push({
							name: 'readWithRegTextId',
							params: { regTextId: regTextId },
						})
						.catch((error) => {
							console.log(error)
						})
				})
				.catch((responseError) => {
					console.log(responseError)
				})
		},

		handleVersionDateClick(message) {
			console.log(message)
			this.$emit('version-date-click', message.context.currentVersionDate)
		},
	},
}
</script>

<style scoped lang="scss" src="./messages.scss"></style>
